<template>
    <div class="container repair-container">
        <div class="row">
            <div class="col-md-12">

                <!-- Alert -->
                <div class="alert alert-warning">
                    <animated-warning></animated-warning>
                    <div class="alert-content">
                        <p class="mb-2">
                            <strong>Contact your SSM if:</strong>
                        </p>
                        <ul class="mb-0">
                            <li>You've received a different model</li>
                            <li>You've received a different colour</li>
                            <li>The customer hasn't provided the device's PIN</li>
                        </ul>
                    </div>
                </div>

                <!-- Correct device checks -->
<!--                <label>Confirm the following before continuing</label>-->
                <div class="card-grid vertical mb-5">

                    <!-- Correct Model -->
                    <!--                    <div class="card-link-container">-->
                    <!--                        <div class="card">-->
                    <!--                            <div class="card-body card-body-sm">-->
                    <!--                                <label class="custom-control custom-checkbox mb-0 pt-0" id="correct-model-checkbox">-->
                    <!--                                    <input type="checkbox" class="custom-control-input" id="correct-model-check"-->
                    <!--                                           :class="modelCorrect ? '' : 'collapsed'"-->
                    <!--                                           :aria-expanded="modelCorrect ? 'false' : 'true'"-->
                    <!--                                           aria-controls="correct-model-controls"-->
                    <!--                                           v-model="modelCorrect"-->
                    <!--                                           @click="toggleCorrectModel()">-->
                    <!--                                    <label class="custom-control-label mb-0" for="correct-model-check">Correct model has been supplied</label>-->
                    <!--                                </label>-->
                    <!--                            </div>-->
                    <!--                            <b-collapse id="correct-model-controls" v-model="modelIncorrect" class="mt-2">-->
                    <!--                                <div class="card-body pt-0">-->
                    <!--                                    <div class="alert alert-warning">-->
                    <!--                                        <animated-warning></animated-warning>-->
                    <!--                                        <div class="alert-content">-->
                    <!--                                            <p>-->
                    <!--                                                If the device shown below is different to the one the customer has supplied, click the "Change Device" button to update the repair. <strong>Please note this may affect your KPIs.</strong>-->
                    <!--                                            </p>-->
                    <!--                                        </div>-->
                    <!--                                    </div>-->
                    <!--                                    <label>Expected Device</label>-->
                    <!--                                    <div class="card card-body-sm bg-secondary-light border-radius-base mb-4">-->
                    <!--                                        <div class="media align-items-center">-->
                    <!--                                            <div class="media-left">-->
                    <!--                                                <img src="https://likewizerepair-uk.s3.eu-west-2.amazonaws.com/makes/apple/phone/iphone-xs/space-grey.svg" class="img-xl">-->
                    <!--                                            </div>-->
                    <!--                                            <div class="media-body">-->
                    <!--                                                <h5 class="mb-0">iPhone XS Space Grey</h5>-->
                    <!--                                            </div>-->
                    <!--                                        </div>-->
                    <!--                                    </div>-->
                    <!--                                    <label>Received Device</label>-->
                    <!--                                    &lt;!&ndash; TODO: Change to show the device details once selected &ndash;&gt;-->
                    <!--                                    <div class="card card-body-sm card-link bg-secondary-light border-radius-base mb-2" v-b-modal.change-device-modal>-->
                    <!--                                        <div class="media align-items-center">-->
                    <!--                                            <div class="media-left">-->
                    <!--                                                <icon-device-unknown class="empty-icon icon-all-muted" size="xl"></icon-device-unknown>-->
                    <!--                                            </div>-->
                    <!--                                            <div class="media-body">-->
                    <!--                                                <h5 class="mb-0">Select the received device</h5>-->
                    <!--                                            </div>-->
                    <!--                                        </div>-->
                    <!--                                    </div>-->
                    <!--                                </div>-->
                    <!--                            </b-collapse>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <!--                    &lt;!&ndash; Correct Colour &ndash;&gt;-->
                    <!--                    <div class="card-link-container">-->
                    <!--                        <div class="card">-->
                    <!--                            <div class="card-body card-body-sm">-->
                    <!--                                <label class="custom-control custom-checkbox mb-0 pt-0" id="correct-colour-checkbox">-->
                    <!--                                    <input type="checkbox" class="custom-control-input" id="correct-colour-check"-->
                    <!--                                           :class="colourCorrect ? '' : 'collapsed'"-->
                    <!--                                           :aria-expanded="colourCorrect ? 'false' : 'true'"-->
                    <!--                                           aria-controls="correct-model-controls"-->
                    <!--                                           v-model="colourCorrect"-->
                    <!--                                           @click="toggleCorrectColour()">-->
                    <!--                                    <label class="custom-control-label mb-0" for="correct-colour-check">Correct colour has been supplied</label>-->
                    <!--                                </label>-->
                    <!--                            </div>-->
                    <!--                            <b-collapse id="correct-colour-controls" v-model="colourIncorrect" class="mt-2">-->
                    <!--                                <div class="card-body pt-0">-->
                    <!--                                    <label>Expected Colour</label>-->
                    <!--                                    <div class="card card-body-sm bg-secondary-light border-radius-base mb-4">-->
                    <!--                                        <div class="media align-items-center">-->
                    <!--                                            <div class="media-left">-->
                    <!--                                                <img src="https://likewizerepair-uk.s3.eu-west-2.amazonaws.com/makes/apple/phone/iphone-xs/space-grey.svg" class="img-xl">-->
                    <!--                                            </div>-->
                    <!--                                            <div class="media-body">-->
                    <!--                                                <h5 class="mb-0">Space Grey</h5>-->
                    <!--                                            </div>-->
                    <!--                                        </div>-->
                    <!--                                    </div>-->
                    <!--                                    <label>What colour has been supplied?</label>-->
                    <!--                                    <div class="card-grid grid-col-2 grid-col-md-3 grid-col-lg-4">-->
                    <!--                                        <div class="card-link-container">-->
                    <!--                                            <div class="card card-select card-select-sm active">-->
                    <!--                                                Midnight Black-->
                    <!--                                            </div>-->
                    <!--                                        </div>-->
                    <!--                                        <div class="card-link-container">-->
                    <!--                                            <div class="card card-select card-select-sm">-->
                    <!--                                                Coral Blue-->
                    <!--                                            </div>-->
                    <!--                                        </div>-->
                    <!--                                        <div class="card-link-container">-->
                    <!--                                            <div class="card card-select card-select-sm">-->
                    <!--                                                Lilac Purple-->
                    <!--                                            </div>-->
                    <!--                                        </div>-->
                    <!--                                        <div class="card-link-container">-->
                    <!--                                            <div class="card card-select card-select-sm">-->
                    <!--                                                Titanium Grey-->
                    <!--                                            </div>-->
                    <!--                                        </div>-->
                    <!--                                        <div class="card-link-container">-->
                    <!--                                            <div class="card card-select card-select-sm">-->
                    <!--                                                Sunrise Gold-->
                    <!--                                            </div>-->
                    <!--                                        </div>-->
                    <!--                                    </div>-->
                    <!--                                </div>-->
                    <!--                            </b-collapse>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <!-- PIN Supplied -->
                    <!--                    <div class="card-link-container">-->
                    <!--                        <div class="card">-->
                    <!--                            <div class="card-body card-body-sm">-->
                    <!--                                <label class="custom-control custom-checkbox mb-0 pt-0" id="pin-supplied-checkbox">-->
                    <!--                                    <input type="checkbox" class="custom-control-input" id="pin-supplied-check"-->
                    <!--                                           :class="pinSupplied ? '' : 'collapsed'"-->
                    <!--                                           :aria-expanded="pinSupplied ? 'false' : 'true'"-->
                    <!--                                           aria-controls="correct-model-controls"-->
                    <!--                                           v-model="pinSupplied"-->
                    <!--                                           @click="togglePinSupplied()">-->
                    <!--                                    <label class="custom-control-label mb-0" for="pin-supplied-check">PIN has been supplied</label>-->
                    <!--                                </label>-->
                    <!--                            </div>-->
                    <!--                            <b-collapse id="pin-supplied-controls" v-model="pinNotSupplied" class="mt-2">-->
                    <!--                                <div class="card-body pt-0">-->
                    <!--                                    <div class="alert alert-warning">-->
                    <!--                                        <animated-warning></animated-warning>-->
                    <!--                                        <div class="alert-content">-->
                    <!--                                            <h5 class="title">Don't have the device's PIN?</h5>-->
                    <!--                                            <p>-->
                    <!--                                                The PIN for the device is required for testing. Please ask the customer for the PIN and if they are unable to supply it, inform them <strong>the repair will be cancelled and they will be subject to the call out charge.</strong>-->
                    <!--                                            </p>-->
                    <!--                                            <p>-->
                    <!--                                                <strong>Please note this may affect your KPIs.</strong>-->
                    <!--                                            </p>-->
                    <!--                                        </div>-->
                    <!--                                    </div>-->
                    <!--                                    <button class="btn btn-info ml-auto">Call your SSM</button>-->
                    <!--                                </div>-->
                    <!--                            </b-collapse>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <!-- Waiver Required -->
                    <div class="card-link-container">
                        <div class="card">
<!--                            <div class="card-body card-body-sm">-->
<!--                                <label class="custom-control custom-checkbox mb-0 pt-0" id="waiver-required-checkbox">-->
<!--                                    <input type="checkbox" class="custom-control-input" id="waiver-required-check"-->
<!--                                           :class="waiverNotRequired ? '' : 'collapsed'"-->
<!--                                           :aria-expanded="waiverNotRequired ? 'false' : 'true'"-->
<!--                                           aria-controls="correct-model-controls"-->
<!--                                           v-model="waiverNotRequired"-->
<!--                                           @click="toggleWaiverRequired()">-->
<!--                                    <label class="custom-control-label mb-0" for="waiver-required-check">No waiver is required</label>-->
<!--                                </label>-->
<!--                            </div>-->
<!--                            <b-collapse id="waiver-required-controls" v-model="waiverRequired" class="mt-2">-->
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h3>Please complete if a waiver is required</h3>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Name of Person Signing</label>
                                                <input type="text" class="form-control" v-model="device.waiver_name">
                                            </div>
                                        </div>
                                    </div>
                                    <label>Check all that apply</label>

                                    <ul class="list-group selection-list mb-4">
                                        <li class="list-group-item" v-for="(waiverOption, index) in waiverOptions" :key="index">
                                            <label class="custom-control custom-checkbox mb-0">
                                                <input type="checkbox" class="custom-control-input"
                                                       :id="waiverOption.id"
                                                       v-model="waiverOption.checked">
                                                <label class="custom-control-label mb-0" :for="waiverOption.id">{{waiverOption.title}}</label>
                                            </label>
                                        </li>
                                    </ul>

                                    <p>
                                        This confirms that I (the owner of the device) understand and accept the extenuating circumstances of my device’s repair. Due to the selected reason(s) above I understand that the standard terms cannot apply on this occasion.
                                    </p>
                                    <p>
                                        I would like WeFix to repair my device to the best condition possible, but I understand that there may be restrictions in the final result, and that the normal warranty cannot apply due to the conditions marked above.
                                    </p>

                                    <!-- If waiver has NOT been signed -->
                                    <div class="row" v-if="!device.waiver_signed">
                                        <div class="col-md-8 offset-md-2">
                                            <button class="btn btn-success btn-lg btn-block mb-3" v-b-modal.waiver-signature-modal>Sign Waiver</button>
                                        </div>
                                    </div>

                                    <!-- If waiver has been signed -->
                                    <div class="row" v-if="device.waiver_signed">
                                        <div class="col-md-12">
                                            <img :src="signatureImageUrl" class="signature-preview "/>
                                        </div>
                                        <div class="col-md-8 offset-md-2">
                                            <button class="btn btn-success btn-lg btn-block mb-3" v-b-modal.waiver-signature-modal>Edit Waiver</button>
                                        </div>
                                    </div>

                                </div>
<!--                            </b-collapse>-->
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- Footer -->
        <fixed-footer
                :class="{'active': showFooter}"
                @click="setWaiverOptionChecked()"
                :nextLabel="this.nextRepairStep($route.name).title"
                :nextRoute="this.nextRepairStep($route.name).routeName">
        </fixed-footer>

        <!-- Change Device Modal -->
        <change-device-modal></change-device-modal>

    </div>
</template>

<script>
    import {mapActions, mapGetters, mapState} from "vuex";


    export default {
        props:['deviceId', 'jobId', 'signatureImageUrl'],

        data() {
            return {
                modelCorrect: true,
                modelIncorrect: false,
                colourCorrect: true,
                colourIncorrect: false,
                pinSupplied: true,
                pinNotSupplied: false,

                waiverRequired: true,
                waiverNotRequired: false,

                showFooter: true
            }
        },

        computed: {
            ...mapGetters([
                'device',
                'job',
                'waiverOptions',
                'waiverSigned',
                'nextRepairStep',
                'prevRepairStep'
            ]),

            ...mapState([
                'waiverName',
            ])
        },

        methods: {
            ...mapActions([
                'loadDeviceForJob',
                'loadWaiverOptions',
                'setWaiverName',
                'setWaiverOptionChecked',
                'setWaiverSigned',
                'addSignature'
            ]),

            /** Toggle correct model */
            toggleCorrectModel() {
                if (this.modelCorrect) {
                    this.modelCorrect = false;
                    this.modelIncorrect = true;
                    return;
                }

                this.modelCorrect = true;
                this.modelIncorrect = false;
            },

            /** Toggle correct colour */
            toggleCorrectColour() {
                if (this.colourCorrect) {
                    this.colourCorrect = false;
                    this.colourIncorrect = true;
                    return;
                }

                this.colourCorrect = true;
                this.colourIncorrect = false;
            },

            /** Toggle correct colour */
            togglePinSupplied() {
                if (this.pinSupplied) {
                    this.pinSupplied = false;
                    this.pinNotSupplied = true;
                    return;
                }

                this.pinSupplied = true;
                this.pinNotSupplied = false;
            },

            /** Toggle correct colour */
            toggleWaiverRequired() {
                if (this.waiverNotRequired) {
                    this.waiverNotRequired = false;
                    this.waiverRequired = true;
                    return;
                }

                this.waiverNotRequired = true;
                this.waiverRequired = false;
            },
        }
    }
</script>

<style scoped>

</style>