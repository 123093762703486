import Vue from 'vue'
import App from '@/App.vue'
// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginVue from '@bugsnag/plugin-vue'
//
// Bugsnag.start({
//     apiKey: '9999896a89acbde86fded5c67c7349a7',
//     plugins: [new BugsnagPluginVue()]
// })
//
// Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)

require('dotenv').config();

import Laraform from "laraform";
Vue.use(Laraform);

import 'Components'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/vuex/store';

window.Vue = Vue;

// Boostrap
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);

// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBdZcrpWfXStW2Uui8iweJYvwCv18xvrYg',
        libraries: 'places', // This is required if you use the Autocomplete plugin
    }
});

// Moment
import moment from 'moment'

Vue.prototype.moment = moment

//User
import User from "./services/user";
let user = new User(store.getters.user);

// VueAxios
import Axios from 'axios'
let axios = Axios.create({
    baseURL:process.env.VUE_APP_API_URI
});
axios.interceptors.request.use((config) => {
    config.params = config.params || {};
    config.params['key'] = user.apiKey();

    // If we already have an authorization header, skip.
    if (config.headers.Authorization !== undefined
        && config.headers.Authorization !== null
        && config.headers.Authorization.length > 0) {
        return config;
    }

    // If we have a user with an api key in store, lets apply that key to all requests,
    //  regardless of whether it's needed or not.
    const token = user.apiKey();
    if (token !== null) {
        config.headers.Authorization = 'Bearer ' + token;
    }

    return config;
});
Vue.prototype.$http = axios;
store.$axios = axios;

// Vue Offline
import VueOffline from 'vue-offline';
Vue.use(VueOffline);

// Vue Scrollto
let VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease-in",
    offset: -20,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
});

// Vue Signature Pad
import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

// Vue Toasted
import VueToasted from 'vue-toasted';
Vue.use(VueToasted, {
    iconPack : 'custom-class' // set your iconPack, defaults to material. material|fontawesome|custom-class
});

// Firebase
let firebase = require('firebase/app');
firebase.firestore().enablePersistence().then();

import './registerServiceWorker'
import '@/styles/imports.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
