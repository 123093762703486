<template>
    <div :class="'img-' + size" class="status-icon error-animated">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"><circle cx="65.1" cy="65.1" r="62.1" fill="none" stroke="#FE636C" stroke-miterlimit="10" stroke-width="6" class="path circle"/><path fill="none" stroke="#FE636C" stroke-linecap="round" stroke-miterlimit="10" stroke-width="6" d="M34.4 37.9l61.4 54.4M95.8 38L34.4 92.2" class="path line"/></svg>
    </div>
</template>

<script>
    export default {
        name: "ErrorAnimated",

        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>