<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M43.31 2h43.38a2.54 2.54 0 011.927.887l7.772 9.066A2.54 2.54 0 0197 13.606v47.315A5.08 5.08 0 0191.92 66H38.08A5.08 5.08 0 0133 60.92V13.607c0-.606.217-1.192.611-1.653l7.772-9.066A2.54 2.54 0 0143.31 2zM33.255 12.92h62.984m20.795 26.524c5.624-1.875 9.062 2.187 10.312 12.187 1.875 14.999-.938 30.935-8.437 42.184-5 7.5-7.812 17.186-8.437 29.06h0v3.75c-1.875 1.25-6.875 1.875-14.999 1.875s-13.437-.937-15.936-2.812h0v-7.5c-1.25-16.249-.313-27.498 2.812-33.747 5.625-10.624 10.312-15 14.061-13.124 3.75 1.875 3.75 6.25 0 13.124-5.624 8.124-5 9.999 1.875 5.624 10.312-6.562 16.874-27.185 15.937-37.497-.625-6.874.312-11.25 2.812-13.124zm-104.159 0c2.5 1.875 3.437 6.25 2.812 13.124-.937 10.312 5.625 30.935 15.937 37.497 6.874 4.375 7.5 2.5 1.875-5.624-3.75-6.875-3.75-11.25 0-13.124 3.75-1.875 8.437 2.5 14.061 13.124 3.125 6.25 4.062 17.498 2.812 33.747v7.5h0c-2.5 1.875-7.811 2.812-15.936 2.812-8.124 0-13.124-.625-14.999-1.875v-3.75C18.811 111.001 16 101.315 11 93.815 3.5 82.565.688 66.63 2.562 51.631c1.25-10 4.688-14.062 10.312-12.187z" class="path" stroke="#0C2340"/><path class="highlight" stroke="#00BBDC" d="M74.143 12.92v16.255L65 23.587l-9.143 5.588V12.92zM55.857 12.159L59.508 2h10.984l3.65 10.159"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>