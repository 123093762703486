<template>
    <div class="container barcode-container">
        <!-- Loading -->
        <loading-state
                v-if="!showScanner && !cannotLoadScanner"
                centered
                overlay
                title="Loading scanner"
        />

        <empty-state
                v-if="cannotLoadScanner"
                icon="scanner"
                inline
                small
                title="Could not load the scanner on this device"
        />

        <button class="barcode-reload btn" title="Reload scanner" @click="reloadBarcodeScanner"><icon-reload class="icon-all-body icon-chunky" size="sm"></icon-reload></button>
        <div v-show="showScanner" id="barcode-picker" class="barcode-picker"></div>
    </div>
</template>

<script>
    import * as ScanditSDK from "scandit-sdk";
    import {BarcodeScanned} from "@/models/barcodeScanned";
    import {mapActions, mapGetters} from "vuex";

    export default {
        props: {
            resolutionPreference: {
                type: String,
                default: "full-hd"
            },
            resumeDelay: {
                type: Number,
                default: 2000
            },
            inModal: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                scandit: null,
                barcodeScanner: null,
                clearJsonTimeout: null,
                componentIsDestroyed: false,
                jsonString: "",
                showScanner: false,
                showReloadButton: false,
                cannotLoadScanner: false
            }
        },

        computed: {
            ...mapGetters(['initialisingScanner', 'user'])
        },

        mounted() {
            if (this.initialisingScanner || (this.barcodeScanner !== null && !this.barcodeScanner.destroyed)) {
                return;
            }

            this.setInitialisingScanner(true);

            let self = this;
            setTimeout(() => {
                self.initialiseBarcodeScanner();
                self.listenForBarcodeScanner();
            }, 1000);
        },

        beforeDestroy() {
            this.componentIsDestroyed = true;
            this.destroyBarcodeScanner();
        },

        methods: {
            ...mapActions(['displayToast', 'setInitialisingScanner']),

            handleBarcodeScanned(data) {
                let barcodeScanned = data;

                if (this.isJson(data)) {
                    barcodeScanned = new BarcodeScanned(JSON.parse(data));
                }

                this.$emit("barcodeHasBeenScanned", barcodeScanned);
            },

            /** Listen out for the hand held barcode scanner */
            listenForBarcodeScanner() {
                let self = this;
                document.addEventListener('keypress', (event) => {
                    clearTimeout(self.clearJsonTimeout);

                    if (event.key.length === 1) {
                        if (event.key === "@") {
                            self.jsonString += '"';
                        } else {
                            self.jsonString += event.key;
                        }
                    }

                    if (event.code === "Enter") {
                        self.handleBarcodeScanned(self.jsonString);
                    }

                    self.clearJsonTimeout = setTimeout(() => {
                        self.searching = false;
                        self.jsonString = "";
                    }, 1000);
                });
            },

            /** Check if string is valid json */
            isJson(string) {
                try {
                    JSON.parse(string);
                } catch (e) {
                    return false;
                }
                return true;
            },

            initialiseBarcodeScanner() {
                this.showScanner = false;
                this.cannotLoadScanner = false;

                let self = this;

                // let licenseKey = "AQvAOpKBBob8AtyImS1bVZclZ7c4AWt+JXVNTeBZUCJnYI7EPCrXwHJLGJsSLXVjGXobyOpmAAYWODrWJHfJBj0UHlQJQUHFaDnzeaJYfG/7ZHpNLXv4C7EEoQR/Cc+BRQH1K6cjN94HAdfaAH6D4+LyjgTPfEzJ1Y4igMLKOU/JdZlaidLx7w8C+j7GP6Ivz4KW/+w48a2lOXJUDxcVqushd7xKEzbVuczEd04Ve6WMsaEa3ewBjw/4d9sfn8uOMW++Phap4PG1WkjX/LeuZNz6ISFQlPx2UerMwXxtrV43MY54gg4wRELPi7wGZJiQoYS5MLTy3tPu5DfVG1usjOCFVmmXGuX45I5XWPR78w+YphmWTfShroSgyoUROkFFSv+03dKm2Tk2NHf3MP1QobHgQDoHzHg0SLKQ5dfb3A9bDWgWt9qOk7Mx35kGAuM1bovY0EK/JYsu36QHvaUfsrvlborAqO9ZC1MNhJ0bfRnhlK+b6bmwat99X33kmlxoUSQd1405QAaS5BeKqDA6QoSc2WjY9yc8vYjLxQjL8qoBsZPv8jQF5Y9p77sFQWtAv7t21DsgdWWIjigZmoQadSptluxtICKo/Yk3LumrlC3b6h/1tkgJVpI/9PoXt2Zyl1lYsM3oYNiLdQg8vyzZ0EjnohIWz02rCNPXryvWEEXEif0BR1pBlNXh7HTbelJc+pUVnrFtmRDGMo36hiezmaxglbO/W8ImOkLr5LbDqErPThE/6efJOC89MKRWeUgAsUJQtl909giKxiCKQubCrpgoV0swSi/hYekp6PXeCSpMBxMPnpXKgw==";
                let licenseKey = "Ad8+hNSBQHr8EvzGyRiLYKc2d4cKDTkQTmLGjY46TuKDLMq1gRtqn5lPQwDcXwH8BSt8NHpiS1/JQIwpuip6CshIpyXGQ65iDiTFxkhstoTbTzodok53zfERsQKnPvd7vQDtRwwiHCCtrD3CpWsfo1Yx6wSBOMKWFABwTV+mwDCE1Vaau36nKTt8fSNQMzaQLpIActRslEMQtDiv1syQXM0t5CFRbo0OCUf27gaAr2fmKb8Wqkr1gDzLPVWDlCENdh3cii9sL4JjyMPaq7+4OqaD02QLjeAMoEyEjKno22AANCcRtndS3nIrx+hd1aK80ND0yu0YLZbpwUUBbBRHdyScJdYcfEe5QeYjO4EXFpTsb1gOHxLb9cbsHX62IVo/O7xxipV5sEImAZGSj0d8sawpqo6QmSHEzXuY4dfRp6UNcQYJICal7WI9T7BVO5BWPmQR+chHJC0VNjRPxV+5HlEWYK4n0J+PQyTdE1+DyBqd+6oM8AnoH2Yp8ScG+eM61CAgboodUM//X6S3pof3lX8GqDT9t5TYWE8WftZE2A3t88GiDzYqaN3pkOC8u6P65KqwSIQYw0lLKsGW/2hpz/ySDW8M12/xuWvkZhnVYTQA3lL5+Vwa3FncN/lUEdu5HI6BxlRbW5x9U+N+acSs72jPC1hFPW0e6Lo/PPlu0UAXfdQ8RIDZNV9VJbMg4BFpjwgINzgNBzXu7+7iOjIsyi38lDjWzTiylN3dSDbgGgBh9wtHxaqrWj2ibQOL1i3uJzDjRK5vq/6iVf66LdMVb/IqcD1EWiPDNls+Cengm5lH5vKjGLxU";

                if (process.env.VUE_APP_COUNTRY_CODE === "AU") {
                    licenseKey = "AW2gxbSBIJhtQ22NkjKgfdgmeEXsC29sblTG8hBS0PvxRBhZBQKOX6hyksc0dHsANAvEOTperNWjckVQmn0dkQwkA0G/I7fdtT9ur9xG8nGsVM1D3iJDnucMbtOUQPRmEjpSHkcc5wFUDseDd4PKeOzyWXuI8WDQ73gUr/j3zda/aOtBS4xgLUOYBJQ7q7elpS3SsGIabx8BHlBGfP6fFgm/9a2XM/rWmZ/6EcN5GjtJDLScmPX1kkMtOBnTBnhDZVAE73YKwuwA9QrAQHTZrcQJlFsy6Xblime2Rb1Q/1YA8wRGNoADpv08muI9z4f2/cvdrvUiu7GE883WOgEC7xubiPV8+Fxo2ngv1IU0t1NM3VknlUToR6ZtkEOvtqARzZOURYcDLZk2r5iTeuzEg/lMRfLdtanGyfXR+0EpjDK/ops1RK9Ae5GQRpIo9xntgfcprZpo74tySCLj7O/GKknZOCOSogD2bg/bWUT5RBuixhMGBOZDJzY7Qe316UWtwB0BccFXaXwY5Dt/YABqRdarU8pBpOfz07t4jUFdP3Ufg72pcyhbhwXh6fVjBJNfNC3jDl9mJ79MJF/R8ToSAyhUNVhlfkGWUoup5my+IrDOtxso4MOv7vy2M0atViS4coV4moxIC3P9R0raGexPTaHLuvddtxwqZG/Mr9oqHWvQUsBgY7wGF8mKe9+6mNzM/RCSbpVwwtps5yxA3qhZlaSLg+/xqpn9RQ+7RFfQNL1XCiaw6lh64+5BRnt3WusER3UJymJpFqQ3JTmNSitzQiYOwNz3g8DXRK3cCWLL5NsBO9Qkgb+RmgE=";

                } else if (process.env.VUE_APP_COUNTRY_CODE === "CA") {
                    licenseKey = "ASjR/pqBDhuIAOhIOBC6BwYUhh39Fd4OYU+5kAA4sFgLLmLNVT6+RshNMlu/dKNAxGOFI7N0vITVYJ5WHiGLKT5hEBWjebqHu2ErnUowBn08IA2EnEp66VodWwaVWflPwUM4w+lhCX6ZSgisHCm32etV9eT5UIgxnkeLnS8eL+StMV1arD6/6dEYkYsOhxtzGUstIkUXevp0wE1DMdeZ/gSvCnOqSGcWf/WyYt8hPnRMlSwZyLs+SMbv+XFrgz5O5ht9Y7TosaQo3gO1GYnQ1fx0NdHdYktGxOzjY47NsckykmcNoYr2oXByGz5QD/Q3MWcfl3bbxawDKu+C+wIN66IuxbpCAHra1b4ZaARk2bxQWjIi+LS5Bt9qK3jRGid/wfVxn2IcPnQl5M/HSC9+DMUgPp1hu66iQE8XvL8ic0HClN25katZvZyBfqCXnU/E9IKXIzc+FATkTg1ca+6J+vvClw/EjOeVzWYoUBzvNDe1qFNKPwiTob+lFOSb1aH4sco86E7nR/LNDwO+oxjF0gfgXdIOj1SjxxKbtXh0v83z2rtGAK+2aHyfJEDmsPzeb02581fYAV8S5N47gMZXVvCrjVmNx0GDPHTk4BStpZ59dyacaRz/LmLQzxISSbzbrZMGqjkYL4Nf+63nJJHCqkRrva6h3D6CZEXSbyxSGCRCty/j9HeKjiBFmiE6F47EYAp32Xl8gtKqiATFIuU2P3dZ2jEYKgxxkiS9eg3FUobsTRo5DO35t6CQmPzZOcklmjUvjNc6VI0FyaUKbyPWQ2wf49oPN4yr3O/8HJbZLkff/qkbwEUroA3SSnDFPuSsuF3Z3wa3ZLFfaAAZU/NIHl4aSPukbDikk7T/LA==";

                } else if (process.env.VUE_APP_COUNTRY_CODE === "US") {
                    licenseKey = "AU2jF4yBGLjyC3IjwClkJrwJnN32GrX6pHss0fx7nNkAXCmpfB2H4wxFJiJoSACPimrFneh9utcfU38mjHbfqdI6mlEdT7a18mZOXAdfdqUsQ3onY2Ri/69u82WLeRJyZWSm36Avqx0tWXNBHELNatRy8U8BDyE7Lhx+nD0Qnmv7Kgu6q+qOHkuGJOAz47zx41FA4pDVUUsdQrhdhQlG0e2uBse+ipmsR8xvpWH3Ts7SBtTZq8NoQFmsuqQykwznFiIrkZOamXh7g0GaBi39Uge/vjuz1MDdzE3yTbrXcJoV35OyXXxDh3fjyCNNhMM3DaaerQFk3lBfj4njL12ZONfYSnixy+yhI4lUUtjfUjWAgNcQjo5B9xzWIcyUKOL3b6wl9ZNRKXfpgRnCFl/2tp00c+Ge3qjdbZZdHe89s9hbL7tFFRDClRVjnNbNH9sUGL7r0UJP5KJZb3GZsyUe5tb3gThuaXNVi651v2mYbR+lgyxigzmUCQS053jdhYpAAXlq5YnUVaZzuCfU21dYv3ZJ8JLplptWw5br7aBM0yZPeoUFRl88psourXP469Xjx8MznljAMN1TSd/Wt+OLQZshqhlJgLNZhhdHzJYx2nJTHkt14Je+pa7hZ+gAi4qzv0XiLQABaJasRH47UIXREQ5S51aa4YqU5maBatSpENnyoFPkXgo59q9r85g1IRDvJQsxixacNopYYlx2fDYZpIIR53Kx982cxULXNPhYy1prNxzt6FtMoA1XUXgCGZju7CPS9zHNGvkQ0KDb8M72XIB249R2Jevwuuucf8xyX+xnU8OVabJyYEGxTqeQoVt31HIln60r7jC445IDNmT8OEqg8vU=";
                }

                const engineLocation = `${process.env.BASE_URL}/scandit`;

                this.scandit = ScanditSDK;

                this.scandit.configure(licenseKey, {
                    engineLocation: engineLocation,
                    preloadEngineLibrary: true,
                    preloadCameras: true
                }).then(() => {
                    self.scandit.BarcodePicker.create(document.getElementById("barcode-picker"), {
                        accessCamera: true,
                        cameraType: "back",
                        cameraSettings: { resolutionPreference: this.resolutionPreference },
                        guiStyle: "viewfinder",
                        isTapToFocusEnabled: true,
                        isTorchToggleEnabled: true,
                        objectFit: "cover",
                        playSoundOnScan: true,
                        vibrateOnScan: false,
                        singleImageMode: {
                            desktop: {
                                allowFallback: true,
                                always: false
                            },
                            mobile: {
                                allowFallback: true,
                                always: false
                            },
                        },
                    }).then(barcodePicker => {

                        let symbologies = ["ean13", "upca", "upce", "code128", "code39", "code93", "qr", "microqr", "data-matrix", "itf"];

                        if (self.resolutionPreference === 'hd') {
                            // Kit scanning, so use reduced symbologies
                            symbologies = ["qr"];
                        }

                        const scanSettings = new self.scandit.ScanSettings({
                            enabledSymbologies: symbologies,
                            deviceName: this.user.email
                        });

                        // scanSettings.setCodeDuplicateFilter(-1);

                        // "Enable or disable recognition of inverted-color symbology (in addition to normal colors)" For the tiny QR serial number codes on Samsung parts
                        scanSettings.getSymbologySettings(self.scandit.Barcode.Symbology.DATA_MATRIX).setColorInvertedEnabled(true);

                        barcodePicker.applyScanSettings(scanSettings);

                        barcodePicker.on("scan", function(scanResult) {
                            self.handleBarcodeScanned(scanResult.barcodes[0].data);

                            barcodePicker.pauseScanning();

                            setTimeout(() => {
                                barcodePicker.resumeScanning();
                            }, self.resumeDelay);
                        });

                        self.barcodeScanner = barcodePicker;

                        if (self.componentIsDestroyed) {
                            self.destroyBarcodeScanner();
                        }

                        self.showScanner = true;
                        this.setInitialisingScanner(false);

                    }).catch(() => {
                        this.cannotLoadScanner = true;
                        this.setInitialisingScanner(false);
                    });

                }).catch(() => {
                    this.cannotLoadScanner = true;
                    this.setInitialisingScanner(false);
                });
            },

            destroyBarcodeScanner() {
                return new Promise((resolve) => {
                    if (!this.barcodeScanner || this.barcodeScanner.destroyed) {
                        this.scandit = null;
                        return resolve();
                    }

                    this.barcodeScanner.destroy();

                    let timesLooped = 0;
                    while (this.barcodeScanner.destroyed === false) {

                        if (timesLooped >= 15) {
                            window.location.reload();
                        }

                        timesLooped++;
                    }

                    this.barcodeScanner = null;
                    this.scandit = null;

                    return resolve();
                });
            },

            reloadBarcodeScanner() {
                this.destroyBarcodeScanner()
                    .then(() => {
                        this.initialiseBarcodeScanner();
                    });
            }
        }
    }
</script>