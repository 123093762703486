<template>
    <div>
        <likewize-scanner
            v-if="isLikewizeScanner"
            :width="isModal ? 125 : width"
            :height="isModal ? 125 : height"
            :ratio="checkIfMobile ? 1 : isModal ? 2.7 : ratio"
            @barcodeHasBeenScanned="barcodeScanned"
        ></likewize-scanner>
        <barcode-picker
            v-else
            @barcodeHasBeenScanned="barcodeScanned"
            ref="barcodeScanner"
        ></barcode-picker>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'scanner',

    props: {
        scanner: {
            type: String,
            default: 'auto'
        },
        isModal: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: 200
        },
        height: {
            type: Number,
            default: 200
        },
        ratio: {
            type: Number,
            default: 2.3
        }
    },

    computed: {
        ...mapGetters(['appRegion']),

        isLikewizeScanner() {
            return this.scanner === 'likewize' || (this.scanner === 'auto' && this.appRegion !== 'UK');
        },

        checkIfMobile() {
            return window.innerWidth <= 576;
        }
    },

    methods: {
        barcodeScanned(data) {
            this.$emit('barcodeHasBeenScanned', data);
        },
    }
};
</script>
