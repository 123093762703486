<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M115.234 54.085c5.128-3.73 9.766-10.862 9.766-18.062 0-17.84-14.926-21.612-33.75-21.827V4.814c0-2.422-2.845-3.688-4.65-2.128l-20.626 17.81a2.814 2.814 0 000 4.258l20.625 17.812c1.803 1.557 4.651.297 4.651-2.128V30.135c18.174.262 26.008 4.773 20.694 21.767-.59 1.886 1.69 3.347 3.29 2.183z" class="highlight" stroke="#00BBDC"/><path d="M37.889 48H92.11c.927 0 1.808.405 2.41 1.109l9.715 11.333c.493.575.764 1.308.764 2.066v59.143A6.35 6.35 0 0198.65 128h-67.3a6.35 6.35 0 01-6.35-6.35V62.509c0-.758.271-1.49.764-2.066l9.714-11.333A3.175 3.175 0 0137.888 48zM25.317 61.65h78.73" class="path" stroke="#0C2340"/><path class="highlight" stroke="#00BBDC" d="M77 62v20l-11.5-6.875L54 82V62zM54 61l4.593-13h13.814L77 61"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>