<template>
    <b-modal
        id="note-modal"
        no-footer
        size="lg"
        :title="`New ${ noteableType } note`">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Add {{ noteableType }} note</label>
                    <textarea class="form-control" rows="6" placeholder="Required" v-model="newNoteText"></textarea>
                </div>
            </div>
        </div>
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="$bvModal.hide('note-modal')">Cancel</button>
            <button type="button" class="btn btn-success" @click="addNote()" :disabled="newNoteText === ''">Add note</button>
        </template>
    </b-modal>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        props: ["jobId", "noteableId", "noteableType"],

        data() {
            return {
                newNoteText: "",
                selectedItem: null
            }
        },

        computed: {
            ...mapGetters([
                "additionalData",
                "user"
            ])
        },

        methods: {
            ...mapActions([
                "addDeviceNote",
                "addJobNote",
                "displayToast"
            ]),

            /** Form submission */
            addNote() {
                if (this.noteableType === 'job') {
                    this.addJobNote({
                        jobId: this.noteableId,
                        newNoteText: this.newNoteText,
                        user: this.user
                    }).then(() => {
                        this.newNoteText = "";
                        this.$bvModal.hide('note-modal');
                    });
                }

                if (this.noteableType === 'device') {
                    this.addDeviceNote({
                        jobId: this.jobId,
                        deviceId: this.noteableId,
                        newNoteText: this.newNoteText,
                        user: this.user
                    }).then(() => {
                        this.displayToast({text: 'Note added', type: 'success'});
                        this.newNoteText = "";
                        this.$bvModal.hide('note-modal');
                    }).catch((error) => {
                        this.displayToast({text: error, type: 'danger'});
                    });
                }
            }
        }
    }
</script>