const state = {
    /*
     * Step Schema:
     * - routeName: route name as defined in /src/router/index.js
     * - title: Used to display step name in <ProgressSteps/>
     * - makeCodes: array containing make id's
     *   1. Apple
     *   2. Samsung
     * - types: array containing supported types, tablet and/or phone
     * - warranty: weirdly this is an array of the warranty states that this
     *             step supports, eg. [true] OR [false] OR [true, false]
     * - users: optional array containing user id's of people who should see this section
     *          added for WEF-1114 in order to limit its deployment to phases.
     */
    repairSteps: [
        {
            routeName: 'repair.startChecklist',
            title: 'Start Checklist',
            makeCodes:[1, 2, 3],
            warranty:[true, false]
        },
        // As part of Start Checklist need to check if this has outstanding invoices to pay
        {
            routeName: 'repair.warrantyDetails',
            title: 'Warranty Details',
            makeCodes:[2],
            warranty:[true]
        },
        {
            routeName: 'repair.beforePictures',
            title: 'Take Pictures Before',
            makeCodes:[1, 2, 3],
            warranty:[true, false]
        },
        {
            routeName: 'repair.addImei',
            title: 'Add IMEI',
            makeCodes:[1, 2, 3],
            warranty:[true, false]
        },
        {
            routeName: 'repair.addSerialNumber',
            title: 'Add Serial Number',
            makeCodes:[1, 2, 3],
            warranty:[true, false]
        },
        {
            routeName: 'repair.addBoardSize',
            title: 'Add Board Size',
            makeCodes:[1, 2, 3],
            warranty:[true, false]
        },
        {
            routeName: 'repair.confirmProblems',
            title: 'Customer Faults',
            makeCodes:[1, 2, 3],
            warranty:[true, false]
        },
        // On Confirmation of problems, need to check if the repair has gone from IW to OWW.
        {
            routeName: 'repair.defectDescriptions',
            title: 'Fault Description',
            makeCodes:[1, 2, 3],
            warranty:[true, false]
        },
        {
            routeName: 'repair.chooseFault',
            title: 'Choose Fault',
            makeCodes:[2],
            warranty:[true, false]
        },
        {
            routeName: 'repair.symptomCodes',
            title: 'Symptom Codes',
            makeCodes:[2],
            warranty:[true, false]
        },
        {
            routeName: 'repair.irisCodes',
            title: 'Iris Codes',
            makeCodes:[2],
            warranty:[true, false]
        },
        {
            routeName: 'repair.iqc',
            title: 'IQC',
            makeCodes:[2],
            warranty:[true, false]
        },
        {
            routeName: 'repair.addParts',
            title: 'Add Parts',
            makeCodes:[1, 2, 3],
            warranty:[true, false]
        },
        {
            routeName: 'repair.postRepairConfirmations',
            title: 'Post Repair Confirmations',
            makeCodes:[1, 2, 3],
            warranty:[true, false]
        },
        {
            routeName: 'repair.afterPictures',
            title: 'Take Pictures After',
            makeCodes:[1, 2, 3],
            warranty:[true, false]
        },
        {
            routeName: 'repair.oqc',
            title: 'OQC',
            makeCodes:[2],
            warranty:[true, false]
        },
        {
            routeName: 'repair.repairComplete',
            title: 'Review Repair',
            makeCodes:[1, 2, 3],
            warranty:[true, false]
        }
    ],

    mainNavigation: [
        //
        // Technician Only Navigation
        //

        {
            route: {name: 'jobList'},
            title: "Today's Repair Jobs",
            badge: (getters) => {
                if (getters.jobs && getters.jobs.length) {
                    return {
                        classList: 'badge badge-red count-badge',
                        text: getters.jobs.length
                    }
                }
                return null;
            },
            icon: 'icon-tools',
            isVisible: (getters) => {
                return getters.isTechnician;
            }
        },

        {
            route: { name: 'dailyChecklist'},
            title: "Daily checklist",
            isVisible: () => {
                // NOTE: This tile was commented out in DashboardIndex
                return false;
            }
        },

        {
            route: {name: 'sawList'},
            title: "SAW Request List",
            icon: 'icon-tools',
            badge: (getters) => {
                if (getters.mySawList && getters.mySawList.length) {
                    return {
                        classList: 'badge badge-red count-badge',
                        text: getters.mySawList.length
                    }
                }
                return null;
            },
            isVisible: (getters) => {
                // @TODO - Remove for general SAW Request release
                (getters.userService.details.id === 160 ||
                    getters.userService.details.id === 201 ||
                    getters.userService.details.id === 363 ||
                    getters.userService.details.id === 855 ||
                    getters.userService.details.id === 1125 ||
                    getters.userService.details.id === 1276
                ) && ((
                    getters.isTechnician && getters.userService.can('viewMySaws')
                ) || getters.userService.can('viewAllSaws'));

                // @TODO - Disabled entirely as of commit.
                return false;
            }
        },

        {
            route: { name: 'technicianInboundShipments'},
            title: "Inbound shipments",
            icon: 'icon-inbound-shipment',
            isVisible: (getters) => {
                return getters.isTechnician;
            }
        },

        {
            route: { name: 'technicianOutboundShipments'},
            title: "Outbound shipments",
            icon: 'icon-outbound-shipment',
            isVisible: (getters) => {
                return getters.isTechnician;
            }
        },

        {
            route: { name: 'overtime'},
            title: "Overtime",
            icon: 'icon-overtime',
            isVisible: (getters) => {
                return getters.isTechnician
            }
        },

        {
            route: { name: 'storeRepairImeiSearch'},
            title: "Collect Assets from Store",
            icon: 'icon-search-imei',
            isVisible: (getters) => {
                return getters.isTechnician && process.env.VUE_APP_COUNTRY_CODE !== 'AU';
            }
        },

        //
        // Stock picking links
        //
        {
            route: { name: 'repairKitPicking'},
            title: "Repair kit picking",
            icon: 'icon-stock-pick',
            badge: (getters) => {
                if (getters.repairKitsToBePicked && getters.repairKitsToBePicked.length) {
                    return {
                        classList: 'badge badge-red count-badge',
                        text: getters.repairKitsToBePicked.length
                    }
                }
                return null;
            },
            isVisible: (getters) => {
                if (getters.isInHouseTechnician) {
                    return false;
                }

                return getters.isSuperAdmin
                    || getters.userService.can('pickRepairKits');
            }
        },

        {
            route: { name: 'repairKitPacking'},
            title: "Repair kit packing",
            icon: 'icon-repair-kits-packing',
            badge: (getters) => {
                if (getters.repairKitsPicked && getters.repairKitsPicked.length) {
                    return {
                        classList: 'badge badge-red count-badge',
                        text: getters.repairKitsPicked.length
                    }
                }
                return null;
            },
            isVisible: (getters) => {
                if (getters.isInHouseTechnician) {
                    return false;
                }

                return getters.isSuperAdmin
                    || getters.userService.can('packRepairKits');
            }
        },

        {
            route: { name: 'outboundShipments'},
            title: "Outbound shipments",
            icon: 'icon-outbound-shipment',
            isVisible: (getters) => {
                if (getters.isInHouseTechnician) {
                    return false;
                }

                return getters.isSuperAdmin
                    || !getters.isTechnician && getters.userService.can('viewGoodsIn');
            }
        },

        {
            route: { name: 'goodsIn' },
            title: "Goods In",
            icon: 'icon-outbound-shipment',
            isVisible: (getters) => {
                if (getters.isInHouseTechnician) {
                    return false;
                }

                return getters.isSuperAdmin
                    || !getters.isTechnician && getters.userService.can('viewGoodsIn');
            }
        },

        {
            route: { name: 'relocateStock' },
            title: "Relocate Stock",
            icon: 'icon-repair-kits-returns',
            isVisible: (getters) => {
                if (getters.isStockRoomUser) {
                    return true;
                }

                return (getters.isSuperAdmin && !getters.isInHouseTechnician);
            }
        },

        {
            route: { name: 'zoneFinder'},
            title: "Zone Finder",
            icon: 'icon-search-imei',
            isVisible: (getters) => {
                if (getters.isStockRoomUser) {
                    return true;
                }

                return (getters.isSuperAdmin && !getters.isInHouseTechnician);
            }
        },

        {
            route: { name: 'inHouseRepairs'},
            title: "Pending in-house repairs",
            icon: 'icon-in-house-repair',
            isVisible: (getters) => {
                return (getters.isSuperAdmin || getters.isInHouseTechnician)
                    && process.env.VUE_APP_COUNTRY_CODE !== 'AU';
            }
        },

        {
            route: { name: 'bulkRepairImeiSearch' },
            title: "Asset IMEI search",
            icon: 'icon-search-imei',
            isVisible: (getters) => {
                return (getters.isSuperAdmin || getters.isInHouseTechnician)
                    && process.env.VUE_APP_COUNTRY_CODE !== 'AU';
            }
        },

        {
            route: {name: 'jitRepairKitPicking' },
            title: 'JIT Repair Kit Building',
            icon: 'icon-stock-pick',
            badge: (getters) => {
                const pending = getters.jitRepairKits.length;
                if (pending) {
                    return {
                        classList: 'badge badge-red count-badge',
                        text: pending
                    }
                }
            },
            isVisible: (getters) => {
                if (getters.isInHouseTechnician) {
                    return false;
                }

                return getters.isSuperAdmin
                    || getters.userService.can('pickRepairKits');
            }
        },

        // {
        //     route: { name: 'storeRepairImeiSearch'},
        //     title: "Collect Assets from Store",
        //     icon: 'icon-search-imei',
        //     isVisible: (getters) => {
        //         return (getters.isSuperAdmin && !getters.isInHouseTechnician)
        //             && process.env.VUE_APP_COUNTRY_CODE !== 'AU';
        //     }
        // },

        {
            route: {name: 'uploadPhotos' },
            title: 'Upload Photos',
            icon: 'icon-camera',
            isVisible: (getters) => {
                return (getters.isSuperAdmin || getters.isInHouseTechnician);
            }
        },
    ]
};

const getters = {
    /** Number of steps in the process */
    numberOfSteps: (__, getters) => {
        return getters.repairSteps.length;
    },

    /** Get the repair steps */
    repairSteps: (state, getters, __, rootGetters) => {
        const userId = rootGetters.user ? rootGetters.user.id : null;
        const repairStepsToReturn = state.repairSteps.filter(repairStep => {
            let userMatches;
            let makeMatches = false;
            let warrantyMatches = false;

            if (repairStep.users) {
                userMatches = repairStep.users.includes(userId);
            } else {
                userMatches = true; // User matching is only used sometimes, for scoping new feature visibility
            }

            if (getters.device) {
                makeMatches = repairStep.makeCodes.includes(parseInt(getters.device.make_id));
                warrantyMatches = repairStep.warranty.includes(getters.device.in_warranty);
            }

            return (userMatches && makeMatches && warrantyMatches);

        });

        if (!repairStepsToReturn.length) {
            return state.repairSteps;
        }

        return repairStepsToReturn;
    },

    /** Get the current step */
    currentStep: (__, getters) => (routeName) => {
        let currentStep = 0;
        getters.repairSteps.forEach((repairStep, key) => {
            if (repairStep.routeName === routeName) {
                currentStep = key;
            }
        });
        return (currentStep + 1);
    },

    /** Check if a step is active */
    isActiveStep: () => ({repairStep, routeName}) => {
        return repairStep.routeName === routeName;
    },

    /** Check if a step is completed */
    isCompleteStep: () => ({passedRepairStep, currentRouteName}) => {

        let repairStepNumber = 0;
        let currentRouteStepNumber = 0;

        state.repairSteps.forEach((repairStep, key) => {
            if (repairStep.routeName === passedRepairStep.routeName) {
                repairStepNumber = key;
            }

            if (repairStep.routeName === currentRouteName) {
                currentRouteStepNumber = key;
            }
        });

        return repairStepNumber < currentRouteStepNumber;
    },

    /** Get the next step */
    nextRepairStep: (__, getters) => (routeName) => {
        let nextStepKey = 0;

        getters.repairSteps.forEach((repairStep, key) => {
            if (repairStep.routeName === routeName) {
                nextStepKey = key + 1;
            }
        });

        // If the next step is to add the IMEI and this device requires serial validation, push to the serial step
        if (getters.repairSteps[nextStepKey].routeName === 'repair.addImei' && !getters.device.imei_validation) {
            nextStepKey = nextStepKey + 1
        }
        if (getters.repairSteps[nextStepKey].routeName === 'repair.addSerialNumber' && getters.device.imei_validation) {
            nextStepKey = nextStepKey + 1
        }

        return getters.repairSteps[nextStepKey];
    },

    /** Get the last step */
    prevRepairStep: (__, getters) => (routeName) => {
        let prevStepKey = 0;

        getters.repairSteps.forEach((repairStep, key) => {
            if (repairStep.routeName === routeName) {
                prevStepKey = key - 1;
            }
        });

        // If the previous step is to add the serial number and this device requires IMEI validation, push to the IMEI step
        if (getters.repairSteps[prevStepKey].routeName === 'repair.addSerialNumber' && getters.device.imei_validation) {
            prevStepKey = prevStepKey - 1
        }
        if (getters.repairSteps[prevStepKey].routeName === 'repair.addImei' && !getters.device.imei_validation) {
            prevStepKey = prevStepKey - 1
        }

        return getters.repairSteps[prevStepKey];
    },

    visibleMainNavigation: (state, __, _, rootGetters) => {
        return state.mainNavigation.filter((nav) => {
            return nav.isVisible(rootGetters);
        }).map((nav) => {
            return {
                ...nav,
                badge: nav.badge ? nav.badge(rootGetters) : null,
                isVisible: true,
            }
        })
    }
};

const actions = {

};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}