<template>
    <router-link
            :to="{name: 'job', params:{jobId: asset.job_id}}"
            class="card card-body card-link table-layout">
        <dl class="card-cell">
            <dt>Asset ID</dt>
            <dd>{{ asset.id }}</dd>
        </dl>
        <dl class="card-cell">
            <dt>Asset Day Number</dt>
            <dd v-if="asset.day_number">{{ asset.day_number }}</dd>
            <dd v-if="!asset.day_number">--</dd>
        </dl>
        <dl class="card-cell">
            <dt>Repair Kit ID</dt>
            <dd v-if="asset.repair_kit_id">{{ asset.repair_kit_id }}</dd>
            <dd v-if="!asset.repair_kit_id">--</dd>
        </dl>
        <dl class="card-cell">
            <dt>Repair Kit Day Number</dt>
            <dd v-if="asset.repair_kit_day_number">{{ asset.repair_kit_day_number }}</dd>
            <dd v-if="!asset.repair_kit_day_number">--</dd>
        </dl>
    </router-link>
</template>

<script>
    export default {
        props: ["asset"]
    }
</script>

<style scoped>

</style>