<template>
    <div class="signature-pad-container">
        <!-- Loading -->
        <loading-state
                v-if="!showSignaturePad"
                centered
                overlay
                title="Loading signature pad">
        </loading-state>

        <template v-if="showSignaturePad">
            <div v-if="completeJob" class="alert alert-info alert-centered mx-4">
                <animated-info></animated-info>
                <template v-if="!isRefurbOrMailIn">Get the customer to sign to complete the job</template>
                <template v-if="isRefurbOrMailIn">Sign to confirm the device has been repaired and is working correctly</template>
            </div>

            <VueSignaturePad class="signature-pad" ref="signaturePad" />
            <div class="modal-footer">
                <button class="btn btn-light" @click="clear">Clear</button>
                <div>
                    <button class="btn btn-link" @click="undo"><i class="fa fa-undo text-muted"></i></button>
                    <button v-if="!completeJob" class="btn btn-success" @click="sign">Sign</button>
                    <button v-if="completeJob" class="btn btn-success" @click="signAndCompleteJob">Sign &amp; Complete</button>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        props: {
            jobId:{},
            completeJob: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                showSignaturePad: false
            }
        },

        computed: {
            ...mapGetters([
                "isRefurbOrMailIn"
            ])
        },

        mounted() {
            setTimeout(() => {
                this.showSignaturePad = true;
            }, 1000);
        },

        methods: {
            ...mapActions([
                "setJobComplete",
            ]),

            /** Clear */
            clear() {
                this.$refs.signaturePad.clearSignature();
            },

            /** Sign */
            sign() {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                console.log(isEmpty);
                console.log(data);

                this.$emit("signaturePadSigned", data);
            },

            /** Sign the waiver and complete job */
            signAndCompleteJob() {
                this.setJobComplete({
                    jobId: this.jobId
                });
                this.sign();
            },

            /** Undo */
            undo() {
                this.$refs.signaturePad.undoSignature();
            }
        }
    };
</script>
<style>

</style>