import {firestoreAction} from "vuexfire";
import {db} from '@/firebase'
import '@firebase/storage';

const state = {
    asset: null,
    assetDevice: null,
    assetsPendingInHouseRepair: null,
    availableAssetProblems: [],
    addedAssetProblems: [],
    selectedAssetColour: null,

    assetCannotRepair: 'Cannot Repair',
    assetCannotRepairCode: 'cannot-repair',
    assetNotYetReceived: 'Not Yet Received',
    assetNotYetReceivedCode: 'not-yet-received',
    assetPendingInHouseRepair: 'Pending In-House Repair',
    assetPendingInHouseRepairCode: 'pending-in-house-repair',
    assetReceived: 'Received',
    assetReceivedCode: 'received'
};

const getters = {
    asset: state => state.asset,
    assetDevice: state => state.assetDevice,
    assetsPendingInHouseRepair: state => state.assetsPendingInHouseRepair,
    availableAssetProblems: state => state.availableAssetProblems,
    addedAssetProblems: state => state.addedAssetProblems,
    selectedAssetColour: state => state.selectedAssetColour,
};

const actions = {
    /** Add problem to asset device */
    addAssetProblem(__, {assetId, ownerId, problem}) {
        return new Promise((resolve, reject) => {
            db.collection(`assets/${assetId}/devices/${ownerId}/problems`)
                .doc(problem.id)
                .set({
                    id: problem.id,
                    code: problem.code,
                    title: problem.title
                }).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    },

    /** Clears all added asset problems in the state and on firestore */
    clearAddedAssetProblems({state, dispatch}, {assetId, ownerId}) {
        return new Promise((resolve) => {
            for (let i = 0; i < state.addedAssetProblems.length; i++) {
                db.collection(`assets/${assetId}/devices/${ownerId}/problems`)
                    .doc(state.addedAssetProblems[i].id)
                    .delete();
            }
            dispatch('clearAvailableAssetProblems');
            dispatch('loadAddedAssetProblems', {assetId: assetId, ownerId: ownerId});
            resolve();
        });
    },

    /** Clear the asset */
    clearAsset({commit}, asset) {
        commit('CLEAR_ASSET', asset);
    },

    /** Clear the asset device */
    clearAssetDevice(__, {assetId, ownerId}) {
        assetId = assetId.toString();
        ownerId = ownerId.toString();

        return new Promise((resolve, reject) => {
            db.collection(`assets/${assetId}/devices`)
                .doc(ownerId)
                .update({
                    board_type_title: null,
                    colour_title: null,
                    make_id: null,
                    make_title: null,
                    model_id: null,
                    model_title: null,
                    model_version_id: null,
                    model_version_title: null,
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                reject();
            })
        });
    },

    /** Clear available problems */
    clearAvailableAssetProblems({commit}) {
        commit('CLEAR_AVAILABLE_ASSET_PROBLEMS');
    },

    /** Clear the selected color */
    clearSelectedAssetColour({commit}) {
        commit('CLEAR_SELECTED_ASSET_COLOUR');
    },

    /** Load the problems added to the asset */
    loadAddedAssetProblems: firestoreAction(({bindFirestoreRef}, {assetId, ownerId}) => {
        return new Promise((resolve, reject) => {
            return bindFirestoreRef(
                'addedAssetProblems',
                db.collection(`assets/${assetId}/devices/${ownerId}/problems`)
                    .orderBy('title')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load an asset by ID */
    loadAsset: firestoreAction(({bindFirestoreRef}, {assetId}) => {
        return new Promise((resolve, reject) => {
            assetId = assetId.toString();
            return bindFirestoreRef(
                'asset',
                db.collection('assets').doc(assetId)
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load an asset by ID */
    loadAssetsPendingInHouseRepair: firestoreAction(({bindFirestoreRef, state}) => {
        return new Promise((resolve, reject) => {
            return bindFirestoreRef(
                'assetsPendingInHouseRepair',
                db.collection('assets')
                    .where('status_code', '==', state.assetPendingInHouseRepairCode)
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load asset device */
    loadAssetDevice: firestoreAction(({bindFirestoreRef}, {assetId, ownerId}) => {
        return new Promise((resolve, reject) => {

            ownerId = ownerId.toString();

            return bindFirestoreRef(
                'assetDevice',
                db.collection(`assets/${assetId}/devices`).doc(ownerId)
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load an asset by Reference */
    loadAssetFromReference: firestoreAction(({bindFirestoreRef}, {assetReference}) => {
        return new Promise((resolve, reject) => {

            assetReference = assetReference.toString();

            return db.collection('assets')
                .where('reference', '==', assetReference)
                .orderBy('id', 'desc')
                .limit(1)
                .get()
                .then(querySnapshot => {

                    if (!querySnapshot.docs || !querySnapshot.docs[0]) {
                        return reject();
                    }

                    let assetId = querySnapshot.docs[0].data().id.toString();

                    return bindFirestoreRef(
                        'asset',
                        db.collection('assets').doc(assetId)
                    ).then(() => {
                        resolve();
                    }).catch(() => {
                        reject();
                    })
                });
        });
    }),

    /** Load available asset problems */
    loadAvailableAssetProblems: firestoreAction(({bindFirestoreRef}, {makeId, typeId, modelId}) => {
        return new Promise((resolve, reject) => {
            return bindFirestoreRef(
                'availableAssetProblems',
                db.collection(`makes/${makeId}/types/${typeId}/models/${modelId}/problems`)
                    .orderBy('title')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Print asset label */
    printAssetLabel({commit}, assetId) {
        return new Promise((resolve) => {
            db.collection('assets')
                .doc(assetId)
                .update({label_printed: true})
                .then(() => {
                    commit('PRINT_ASSET_LABEL');
                });

            this.$axios.get(`/api/v4/assets/${assetId}/print-label`);

            resolve();
        });
    },

    /** Print DPD label */
    /** TODO: This function has just been copied from the print asset label one above until we have worked out the functionality */
    printDpdLabel(__, assetId) {
        return new Promise((resolve, reject) => {
            this.$axios.put(`/api/v4/assets/${assetId}/ship-to-customer`)
                .then(() => {
                    resolve();
                }).catch(errors => {
                    reject(errors.response.data.messages[0]);
                });
        });
    },

    completeAssetTriage(__, asset) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/v4/assets/${asset.id}/triage`, {questions: asset.triage_questions})
            .then((response) => {
                resolve(response.data.data);
            })
            .catch(errors => {
                reject(errors.response.data.messages[0]);
            });
        });
    },

    /** Delete problem from asset device */
    removeAssetProblem(__, {assetId, ownerId, problem}) {
        return new Promise((resolve, reject) => {
            db.collection(`assets/${assetId}/devices/${ownerId}/problems`)
                .doc(problem.id)
                .delete()
                .then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    setAssetStatus(__, {assetReference, status}) {
        return new Promise((resolve, reject) => {
            this.$axios.post("/api/v4/app/log/asset/" + status, {
                asset_reference: assetReference
            }).then(() => {
                resolve();
            }).catch((error) => {
                reject(error);
            });
        });
    },

    updateAssetToLevelTwo(__, {assetId}) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/v4/assets/${assetId}/leveltwo`)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch(errors => {
                    reject(errors.response.data.messages[0]);
                });
        });
    },

    getAssetByStatus(__, status) {
        return new Promise((resolve, reject) => {
            this.$axios.get("/api/v4/app/log/asset/" + status).then((response) => {
                resolve(response.data.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    /** Set asset "added to shipment" to true */
    setAssetAddedToShipment(__, assetId) {
        assetId = assetId.assetId.toString();

        return new Promise((resolve, reject) => {
            db.collection('assets')
                .doc(assetId)
                .update({
                    added_to_shipment: true
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                reject();
            })
        });
    },

    /** Change asset status to cannot repair */
    setAssetCannotRepair(__, assetId) {
        assetId = assetId.toString();
        return new Promise((resolve, reject) => {
            db.collection('assets')
                .doc(assetId)
                .update({
                    diagnosis_complete: true,
                    status: state.assetCannotRepair,
                    status_code: state.assetCannotRepairCode
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                reject();
            })
        });
    },

    /** Set the asset device make */
    setAssetDeviceMake(__, {assetId, ownerId, make}) {
        assetId = assetId.toString();
        ownerId = ownerId.toString();

        return new Promise((resolve, reject) => {
            db.collection(`assets/${assetId}/devices`)
                .doc(ownerId)
                .update({
                    make_id: make.id,
                    make_title: make.title,
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Set the asset device model */
    setAssetDeviceModel(__, {assetId, ownerId, model}) {
        assetId = assetId.toString();
        ownerId = ownerId.toString();

        return new Promise((resolve, reject) => {
            db.collection(`assets/${assetId}/devices`)
                .doc(ownerId)
                .update({
                    model_id: model.id,
                    model_title: model.title,
                    model_version_title: model.title,
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Complete asset diagnostics */
    setAssetDiagnosticComplete({state}, {assetId, technicianId}) {
        return new Promise((resolve, reject) => {
            assetId = assetId.toString();

            let problemIds = [];
            for (let i = 0; i < state.addedAssetProblems.length; i++) {
                problemIds.push(state.addedAssetProblems[i].id)
            }

            this.$axios.put(`/api/v4/assets/${assetId}/diagnose`, {
                technician_id: technicianId,
                model_version_id: state.assetDevice.model_version_id,
                problem_ids: problemIds

            }).then(() => {
                resolve();
            }).catch(errors => {
                reject(errors.response.data.messages[0]);
            });

            // db.collection('assets')
            //     .doc(assetId)
            //     .update({
            //         diagnosis_complete: true,
            //         status: state.assetReceived,
            //         status_code: state.assetReceivedCode,
            //         technician_id: technicianId
            //     })
            //     .then(() => {
            //         resolve();
            //     }).catch(() => {
            //         reject();
            //     })
        });
    },

    /** Set the asset device version */
    setAssetDeviceVersion(__, {assetId, ownerId, version}) {
        assetId = assetId.toString();
        ownerId = ownerId.toString();

        return new Promise((resolve, reject) => {
            db.collection(`assets/${assetId}/devices`)
                .doc(ownerId)
                .update({
                    board_type_title: version.board_size,
                    colour_title: version.colour_title,
                    model_version_id: version.version_id,
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                reject();
            })
        });
    },

    /** Set the selected color */
    setSelectedAssetColour({commit}, colourId) {
        commit('SET_SELECTED_ASSET_COLOUR', colourId);
    }
};

const mutations = {
    CLEAR_ASSET(state) {
        state.asset = null;
        state.assetDevice = null;
        state.availableAssetProblems = [];
        state.addedAssetProblems = [];
        state.selectedAssetColour = null;
    },

    SET_SELECTED_ASSET_COLOUR(state, colourId) {
        state.selectedAssetColour = colourId;
    },

    CLEAR_SELECTED_ASSET_COLOUR(state) {
        state.selectedAssetColour = null;
    },

    CLEAR_AVAILABLE_ASSET_PROBLEMS(state) {
        state.availableAssetProblems = [];
    },

    PRINT_ASSET_LABEL(state) {
        state.asset.label_printed = true;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}