<template>
    <div id="app-container" :class="{'menu-open': menuOpen}">

        <!-- Navbar -->
        <navbar v-if="user.id"></navbar>

        <!-- Content -->
        <main>
            <transition
                name="fade"
                mode="out-in">
                <router-view class="router-view" :class="{'menu-open': menuOpen}"></router-view>
            </transition>
        </main>

        <!-- Menu -->
        <the-menu v-if="user.id"></the-menu>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Navbar from "Components/TheNavbar";

    export default {
        beforeCreate: function() {
            document.body.className = 'home';
        },

        components: {Navbar},

        computed: {
            ...mapGetters([
                'user',
                'menuOpen'
            ]),
        },

        created() {
            this.$on("online", () => {
                this.displayToast({text: 'You\'re online', type: 'success'});
                this.isOnline = true;
            });

            this.$on("offline", () => {
                this.displayToast({text: 'You\'ve gone offline', type: 'warning'});
                this.isOnline = false;
            });
        },

        watch: {
            '$route' (to) {
                document.title = to.meta.title || 'WeFix Admin App'
            }
        },

        methods: {
            ...mapActions([
                'displayToast',
                'toggleMenu',
            ]),
        }
    }
</script>

<!--<style lang="scss">-->
<!--    @import 'styles/imports.scss';-->
<!--</style>-->
