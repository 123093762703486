<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round"><rect class="path" stroke="#0C2340" stroke-linejoin="round" x="2" y="67" width="24" height="50" rx="2"/><path d="M26 73l31-4c6-.667 11 .667 15 4s9.667 8 17 14c1.333 1.333 1.333 3 0 5-2 3-5 3-8 2-2.805-.935-7.333-4-16-8-2.667-1.333-4-2-5-1-2 2 1 3 3 4 1.333.667 8.333 4.333 21 11 3.333 1.333 6.667 1.667 10-1s12-9.667 26-21c2.667-2 5-2 7 0s1.333 5-2 9l-27 27c-3.333 3.333-7.667 4.667-13 4-5.333-.667-28.333-3-59-7" class="path" stroke="#0C2340" stroke-linejoin="round"/><path d="M19 67V28.567a4 4 0 011.027-2.676l12.21-13.567A4 4 0 0135.21 11h68.58a4 4 0 012.973 1.324l12.21 13.567A4 4 0 01120 28.567V77v-1M19.4 28h99.398" class="path" stroke="#0C2340" stroke-linejoin="round"/><path class="highlight" stroke="#00BBDC" d="M84 28v26l-14.5-8.938L55 54V28zM55 27.111L60.79 11h17.42L84 27.111"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>