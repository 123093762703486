<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M66.99 6.757l18.046 15.997-.035-3.732a3 3 0 012.972-3.028h12.015a3 3 0 013 2.96l.263 19.946 23.74 21.044a3 3 0 01-1.99 5.245l-10.005-.001-.06 56.815a3 3 0 01-3 2.997h-92.87a2.998 2.998 0 01-2.999-3l.028-56.812H4.998a3 3 0 01-1.99-5.244L63.01 6.757a3 3 0 013.98 0z" class="path" stroke="#0C2340"/><path d="M57.852 74.012L36.306 95.558a7.874 7.874 0 0011.136 11.136l18.305-18.305m16.495-7.237a17.44 17.44 0 009.568-4.904c4.561-4.561 6.115-10.99 4.665-16.824-.276-1.11-1.666-1.487-2.476-.678l-9.15 9.15-8.352-1.392-1.392-8.352 9.15-9.15c.815-.814.422-2.202-.696-2.48-5.83-1.445-12.25.112-16.806 4.667-4.878 4.877-6.22 11.947-4.19 18.112m-20.688 34.777a2.954 2.954 0 11.002-5.908 2.954 2.954 0 01-.002 5.908zm47.302 3.575l6.485-6.484c1.784-1.797 1.784-4.7 0-6.484l-14.41-14.408c-2.842-2.842-7.087-3.395-10.508-1.71L57.626 65.452v-7.64L41.876 46 34 53.874l11.813 15.749h7.642l13.117 13.115c-1.673 3.42-1.132 7.665 1.71 10.507l14.41 14.408a4.575 4.575 0 006.485 0z" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>