<template>
    <div class="uploaded-photo-item" :class="{'sm': small}">
        <div class="img-container">
            <img :src="imageData" alt="">
        </div>
        <small class="title">{{ imageType.replace('-', ' ') }} photo</small>
    </div>
</template>

<script>
    export default {
        props: {
            backgroundColour: {
                type: String,
                default: ""
            },
            imageType: {
                type: String,
                default: "other"
            },
            imageData:{
                type: String,
                default: ""
            },
            small: {
                type: Boolean,
                default: false
            },
        },

        methods: {
            showImagePreview() {
                // $('#image-preview').modal('show');
            }
        }
    }
</script>

<style scoped>

</style>