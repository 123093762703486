<template>
    <b-modal
            id="add-problem-modal"
            no-footer
            title="Add Device Problem"
            no-stacking>

        <empty-state
                v-if="loading"
                iconSize="3x"
                statusIcon="loading"
                title="Loading problems">
        </empty-state>

        <div class="card-grid grid-col-2 grid-col-lg-3">
            <div class="card-link-container" v-for="problem in problems" :key="problem.id">
                <div class="card card-select card-select-sm problem vertical"
                     :class="`${isExistingProblem(problem)}`"
                     @click="getSymptoms(problem.id)">
                        <img :src="problemIcon(problem.code)" alt="" class="img-2x">
                        {{problem.title}}
                </div>
            </div>
        </div>

        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="$bvModal.hide('add-problem-modal')">Cancel</button>
<!--            <button type="button" class="btn btn-success">Next</button>-->
        </template>
    </b-modal>
</template>

<script>
    export default {
        name: "AddProblemModal",

        data() {
            return {
                existingProblems: [],
                loading: true,
                problems: []
            }
        },

        mounted() {
            this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
                if (modalId === 'add-problem-modal') {
                    this.loadProblems();
                }
            });
        },

        methods: {
            loadProblems() {
                this.loading = true;
                this.problems = [];
                this.$http.get(`/api/v4/warranty/booking/${localStorage.revisitBookingId}`)
                    .then(res => {
                        this.loading = false;
                        this.existingProblems = res.data.data.problems;
                        const allProblems = res.data.data.device.problems_available;

                        allProblems.forEach(problem => {
                            problem.existing = false;

                            res.data.data.problems.forEach(existingProblem => {
                                if (problem.id === existingProblem.id) {
                                    problem.existing = true;
                                }
                            });

                            this.problems.push(problem);
                        });
                    });
            },

            problemIcon(problemCode) {
                return "https://res.cloudinary.com/wefix/image/upload/icons/repairs/V2/" + problemCode.replace("-replacement", "") + ".svg";
            },

            getSymptoms(problemId) {
                this.$http.get(`/api/v4/problems/${problemId}/symptoms`)
                    .then(res => {
                        const symptoms = res.data.data;
                        let symptomList = [];

                        symptoms.forEach(symptom => {
                            symptom.existing = false;

                            this.existingProblems.forEach(problem => {
                                problem.symptoms.forEach(existingSymptom => {
                                    if (symptom.id === existingSymptom.id) {
                                        symptom.existing = true;
                                    }
                                });
                            });

                            symptomList.push(symptom);
                        });

                        localStorage.symptoms = JSON.stringify(symptomList);

                        this.$bvModal.show('problem-symptom-modal');
                    })
            },

            isExistingProblem(problem) {
                if (problem.existing) {
                    return "active"
                }

                return "new"
            }
        }

    }
</script>