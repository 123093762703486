<template>
    <b-modal
            id="complete-return-shipment-modal"
            no-footer
            title="Mark return shipment complete">
        <empty-state
                statusIcon="warning"
                statusIconSize="4x"
                title="Mark this return shipment as complete?"
                subtitle="Make sure all repair kits have been packed">
        </empty-state>
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="$bvModal.hide('complete-return-shipment-modal')">Cancel</button>
            <button type="button" class="btn btn-success" @click="markShipmentComplete()">Mark as complete</button>
        </template>
    </b-modal>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters([
                'shipmentIdBeingReturned'
            ]),
        },

        methods: {
            ...mapActions([
                'clearReturnShipmentScanned',
                'displayToast'
            ]),

            markShipmentComplete() {
                this.clearReturnShipmentScanned()
                    .then(() => {
                        this.$bvModal.hide('complete-return-shipment-modal');
                        this.displayToast({text: 'Return shipment marked as complete', type: 'success'});
                    }); // TODO: We probably need some other functionality
            }
        }
    }
</script>

<style scoped>

</style>