<template>
    <div class="container h-100">
        <div class="row h-100">

            <!-- Job details and start button -->
            <div class="col-12">
                <div class="card card-body">
                    <h1 class="mb-3">Order ID: {{ job.order_id }}</h1>
                    <h3>{{ job.start_time }} - {{ job.end_time }}</h3>
                    <div class="row">
                        <dl class="col-md-6">
                            <dt>Customer name</dt>
                            <dd>{{ job.first_name }} {{ job.second_name }}</dd>
                        </dl>
                        <dl class="col-md-6">
                            <div class="row align-items-center" v-if="job.phone_number">
                                <div class="col-9">
                                    <dt>Customer's phone number</dt>
                                    <dd>{{ job.phone_number }}</dd>
                                </div>
                                <div class="col-3 text-right">
                                    <a :href="`tel:${job.phone_number}`" class="btn btn-info btn-sm"><i class="fa fa-phone mr-0"></i></a>
                                </div>
                            </div>
                            <template v-if="!job.phone_number">
                                <dt>Customer's phone number</dt>
                                <dd>Not supplied</dd>
                            </template>
                        </dl>
                    </div>

                    <hr class="mt-0" />

                    <!-- Devices -->
                    <template class="col-12" v-if="devices.length > 0">
                        <label>Devices:</label>
                        <div class="mb-2" v-for="device in devices" :key="device.id">
                            <job-start-device-details
                                :job="job"
                                :device="device"
                            ></job-start-device-details>
                        </div>
                    </template>
                    <button class="btn btn-success btn-lg btn-block" @click="startJourney">Start journey</button>
                </div>
            </div>

            <button class="btn btn-link btn-block mb-2 mt-auto text-sm" @click="selectCantDoJob()">Can't do this job?</button>

            <!-- Update status -->
<!--            <div class="col-12">-->
<!--                <div class="card card-body">-->
<!--                    <template v-if="!revisitRequired">-->
<!--                        <label>or select job status</label>-->
<!--                        <select class="custom-select mb-2" v-model="selectedStatus" @change="handleDeviceStatusSelectChange">-->
<!--                            <option value="" selected>Open this select menu</option>-->
<!--                            <option-->
<!--                                    v-for="status in filteredDeviceStatuses"-->
<!--                                    :key="status.key"-->
<!--                                    :value="status.id">-->
<!--                                {{ status.title }}-->
<!--                            </option>-->
<!--                        </select>-->

<!--                        <label v-if="subStatusIsRequired">Please specify</label>-->
<!--                        <select v-if="subStatusIsRequired" class="custom-select mb-2" v-model="selectedSubStatus">-->
<!--                            <option selected>Open this select menu</option>-->
<!--                            <option-->
<!--                                v-for="status in selectedDeviceStatusSubStatuses"-->
<!--                                :key="status.key"-->
<!--                                :value="status.id">-->
<!--                                {{ status.title }}-->
<!--                            </option>-->
<!--                        </select>-->

<!--                        <button class="btn btn-success btn-block mt-3" @click="updateDeviceStatuses" :disabled="!selectedStatus || updatingStatus || shouldDisableUpdateStatusBtn">-->
<!--                            <template v-if="!updatingStatus">Update status</template>-->
<!--                            <template v-if="updatingStatus"><animated-loading class="mr-2" size="xs"></animated-loading> Updating</template>-->
<!--                        </button>-->
<!--                        <p class="text-left text-muted mt-2 mb-0">Only change the status if you are unable to attend the repair for some reason. This will update the status of ALL devices on the order</p>-->
<!--                    </template>-->

<!--                    &lt;!&ndash; Revisit bits &ndash;&gt;-->
<!--                    <template v-if="!isRefurbOrMailIn">-->
<!--                        <template v-if="revisitRequired && isOnline && !isSelfServe">-->
<!--                            <h2>Revisit Required</h2>-->
<!--                            <p>A revisit booking is required for this status change, either click below to book a revisit or change the status.</p>-->
<!--                            <button type="button" class="btn btn-danger mb-0" @click="bookRevisitLoadJob()">Book Revisit</button>-->
<!--                        </template>-->

<!--                        <template v-if="revisitRequired && isOffline">-->
<!--                            <h2>Revisit Required</h2>-->
<!--                            <p>A revisit is required for this order, however you are currently offline.</p>-->
<!--                        </template>-->

<!--                        <template v-if="revisitRequired && isSelfServe">-->
<!--                            <h2>Revisit Required</h2>-->
<!--                            <p>A revisit is required to complete this job, as the customer is not available click the button below to send them a self serve revisit booking form.</p>-->
<!--                            <button type="button" class="btn btn-danger mb-0" @click="bookRevisitSelfServe()">Send Form</button>-->
<!--                        </template>-->
<!--                    </template>-->
<!--                    <template v-if="isRefurbOrMailIn && revisitRequired">-->
<!--                        <div class="alert alert-info mb-0">-->
<!--                            <animated-info></animated-info>-->
<!--                            <div class="alert-content">Please return this device to the office.</div>-->
<!--                        </div>-->
<!--                    </template>-->
<!--                </div>-->
<!--            </div>-->

        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        props: [
            "job",
            "devices"
        ],

        data() {
            return {
                isSelfServe: false,
                loadingRevisit: false,
                revisitRequired: false,
                selectedStatus: null,
                selectedSubStatus: null,
                updatingStatus: false
            }
        },

        mounted() {
            this.revisitRequired = false;
            this.loadDeviceStatuses();
            this.loadDevicesForJob({
                jobId: this.job.id
            });
        },

        computed: {
            ...mapGetters([
                "deviceStatuses",
                "isRefurbOrMailIn",
                "selectedDeviceStatusSubStatuses"
            ]),

            /** Device statuses filtered */
            filteredDeviceStatuses() {
                let filtered = this.deviceStatuses.filter((el) => {
                    return (
                        el.title &&
                        el.title !== "Completed" &&
                        el.title !== "Repaired Pending QC" &&
                        el.title !== "Cancelled Repair"
                    )
                });

                return filtered;
            },

            subStatusIsRequired() {
                return this.selectedDeviceStatusSubStatuses.length > 0;
            },

            subStatusHasBeenSelected() {
                return this.selectedSubStatus !== null && this.selectedSubStatus !== "Open this select menu";
            },

            shouldDisableUpdateStatusBtn() {
                if(this.subStatusIsRequired) {
                    if(!this.subStatusHasBeenSelected) {
                        return true;
                    }
                }
                return false;
            },
        },

        methods: {
            ...mapActions([
                "loadJob",
                "loadDevicesForJob",
                "loadDeviceStatus",
                "loadDeviceStatuses",
                "loadDeviceSubStatuses",
                "setDeviceComplete",
                "setDeviceNotComplete",
                "setDeviceStatus",
                "setJobCantStart",
                "startJobJourney"
            ]),

            /** Revisit load job */
            bookRevisitLoadJob() {
                this.loadingRevisit = true;
                this.$http.get(`/api/v4/warranty/booking/build/${this.job.actual_order_id}`)
                    .then(res => {
                        localStorage.revisitBookingId = res.data.data.booking_reference;
                        this.loadingRevisit = false;

                        if (res.data.data.order.devices.length !== 1) {
                            this.$router.push(`/jobs/${this.job.id}/book-revisit/device`);
                            return;
                        }

                        this.$router.push(`/jobs/${this.job.id}/book-revisit`)
                    });
            },

            /** Revisit self serve */
            bookRevisitSelfServe() {
                this.loadingRevisit = true;
                this.$http.post(`/api/v4/orders/${this.job.order_id}/revisit-invitation`, {
                    "reason": "Sorry we missed you, click below to book a revisit."
                }).then(() => {
                    this.loadingRevisit = false;
                    this.$bvModal.hide('device-status-modal');
                    this.$router.push('job');
                });
            },

            handleDeviceStatusSelectChange() {
                // reset the selected sub status
                this.selectedSubStatus = null;

                this.loadDeviceSubStatuses({
                    statusId: this.selectedStatus
                });
            },

            /** Can't do job */
            selectCantDoJob() {
                this.setJobCantStart({
                    jobId: this.job.id
                }).then(() => {
                    this.$bvModal.show("job-cant-start-modal")
                });
            },

            /** Start the journey */
            startJourney() {
                this.startJobJourney({
                    jobId: this.job.id
                }).then(() => {
                    this.updatingStatus = false;
                    location.reload();
                });
            },

            /** Updates the status of ALL devices */
            updateDeviceStatuses() {
				if (!this.selectedStatus) return;

                let revisitRequired = false;
                this.revisitRequired = false;
                this.isSelfServe = false;
                this.updatingStatus = true;

                this.deviceStatuses.forEach(status => {
                    if (status.id === this.selectedStatus) {
                        revisitRequired = status.revisit_required;

                        if (this.selectedStatus == 3 || this.selectedStatus == 4) {
                            this.isSelfServe = true;
                        }
                    }
                });

                this.devices.forEach(device => {
                    let deviceId = device.id

                    this.setDeviceStatus({
                        jobId:this.job.id,
                        deviceId: deviceId,
                        status: this.selectedStatus,
                        subStatusId: this.selectedSubStatus
                    }).then(() => {
                        // If status is Completed
                        if (this.selectedStatus === "1") {
                            this.setDeviceComplete({
                                jobId:this.job.id,
                                deviceId:deviceId
                            }).then(() => {
                                this.loadDeviceStatus({
                                    statusId: this.device.status_id
                                });
                                this.updatingStatus = false;
                            });
                            // If status is NOT Completed
                        } else if (this.selectedStatus !== "1") {
                            this.setDeviceNotComplete({
                                jobId:this.job.id,
                                deviceId:deviceId
                            }).then(() => {
                                this.loadDeviceStatus({
                                    statusId: device.status_id
                                });

                                if (!revisitRequired) {
                                    this.startJourney();
                                }

                                if (revisitRequired) {
                                    this.revisitRequired = true;
                                    this.updatingStatus = false;
                                }
                            });
                        }
                    });
                });
            }
        }
    }
</script>

<style scoped>

</style>