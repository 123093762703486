<template>
    <div id="progress-steps" class="progress-steps" v-if="device">
        <div class="container">
            <div class="collapse-header collapsed" v-b-toggle.progress-step-list>
                <dl>
                    <dt class="mb-1">{{ device.title_with_colour }} <template v-if="deviceStatus">({{ deviceStatus.title }})</template></dt>
                    <dd>Step {{ currentStep(routeName) }} of {{ numberOfSteps }}</dd>
                </dl>
            </div>
            <b-collapse id="progress-step-list">
                <div class="row pt-2" v-if="isRefurbOrMailIn">
                    <div class="col-12">
                        <button class="btn btn-success btn-sm btn-block" @click="$refs.requestAdditionalPartsModal.openModal()">Request additional parts</button>
                    </div>
                </div>
                <div class="row pt-4">
<!--                    <div class="col-12 my-3 text-center">-->
<!--                        <i class="fa fa-stopwatch mr-2"></i> <strong>Started at 10:32 (26m)</strong>-->
<!--                    </div>-->
                    <div class="col-6">
                        <button class="btn btn-red btn-sm btn-block" @click="$bvModal.show('device-status-modal')">Change status</button>
                    </div>
                    <div class="col-6">
                        <button class="btn btn-info btn-sm btn-block" @click="$bvModal.show('note-modal')">Add note</button>
                    </div>
                </div>
                <ul class="checklist">

                    <li class="checklist-item" v-for="(repairStep, key) in repairSteps" :key="key" :class="{ 'active': isActiveStep({repairStep:repairStep, routeName:routeName}), 'complete': isCompleteStep({passedRepairStep:repairStep, currentRouteName:routeName}) }">
                        {{ repairStep.title }} <router-link v-if="(isJobManager && !isInHouseTechnician) || isCompleteStep({passedRepairStep:repairStep, currentRouteName:routeName})" :to="{ name: repairStep.routeName}" class="btn btn-info btn-sm" v-b-toggle.progress-step-list>Jump to</router-link>
                    </li>

                </ul>
            </b-collapse>
        </div>

        <!-- Request Additional Parts Modal -->
        <request-additional-parts-modal ref="requestAdditionalPartsModal"></request-additional-parts-modal>

    </div>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";

    export default {

        props:["deviceStatus"],


        computed: {
            ...mapGetters([
                "currentStep",
                "device",
                "isActiveStep",
                "isCompleteStep",
                "isJobManager",
                "isRefurbOrMailIn",
                "isInHouseTechnician",
                "numberOfSteps",
                "repairSteps"
            ]),

            routeName() {
                return this.$route.name;
            }
        },

        methods: {
            ...mapActions([
                "loadDeviceStatus"
            ])
        }
    }
</script>

<style scoped>

</style>