<template>
    <b-modal
        id="job-status-modal"
        no-footer
        title="Change job status">
        <empty-state
                statusIcon="warning"
                statusIconSize="4x"
                title="Changing this job’s status may have an affect on your KPIs"
                subtitle="Please ensure you select the correct status for this job and only update when applicable">
            <slot>
                <select class="custom-select">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </slot>
        </empty-state>
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="$bvModal.hide('job-status-modal')">Cancel</button>
            <button type="button" class="btn btn-success" @click="$bvModal.hide('job-status-modal')">Update status</button>
        </template>
    </b-modal>
</template>

<script>
    export default {
        name: "StatusModal"
    }
</script>

<style scoped>

</style>