<template>
    <!-- Remove Part Modal -->
    <b-modal
            body-class="pt-0"
            id="remove-part-modal"
            no-close-on-backdrop
            no-close-on-esc
            no-footer
            title="Remove part">

        <!-- Header close button -->
        <template v-slot:modal-header-close>
            <span @click="closeModal">×</span>
        </template>

        <!-- Barcode picker -->
        <div class="mb-3">
            <scanner
                isModal
                @barcodeHasBeenScanned="barcodeScanned($event)"
            ></scanner>

        </div>

        <!-- Empty state -->
        <empty-state
                v-if="!partScanned"
                icon="scanner"
                title="Scan the part you want to remove from this repair">
        </empty-state>

        <!-- Scanned Part -->
        <template v-if="partScanned">
            <label>Part to remove</label>
            <ul class="list-group">
                <li class="list-group-item">
                    {{ partScanned.stock_item_title }}
                </li>
            </ul>
        </template>

        <!-- Footer -->
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="closeModal">Cancel</button>
            <button type="button" class="btn btn-danger" :disabled="!partScanned" @click="removePartScanned()">Remove Part</button>
        </template>
    </b-modal>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {

        props:['jobId', 'deviceId'],

        data() {
            return {
                partScanned: null
            }
        },

        computed: {
            ...mapGetters([
                "appRegion",
                "deviceParts",
                "devicePartsFaulty",
            ]),
        },

        mounted() {
            this.clearPartScanned();
        },

        created() {
            this.clearPartScanned();
        },

        methods: {
            ...mapActions([
                "displayToast",
                "removePart"
            ]),

            /** On barcode scanned */
            barcodeScanned(barcode) {
                let haltProcess = false;

                this.deviceParts.forEach(device => {
                    if (barcode.id.toString() === device.id) {
                        this.partScanned = device;
                        haltProcess = true;
                    }
                });

                if (haltProcess) {
                    return;
                }

                this.devicePartsFaulty.forEach(device => {
                    if (barcode.id.toString() === device.id) {
                        this.displayToast({text: 'This part has been marked as faulty. Please mark as not faulty to remove', type: 'danger'});
                        haltProcess = true;
                    }
                });

                if (!haltProcess) {
                    return this.displayToast({text: 'This part hasn\'t been added to the repair', type: 'danger'});
                }
            },

            /** Clear the scanned part */
            clearPartScanned() {
                this.partScanned = null;
            },

            /** Close the modal */
            closeModal() {
                this.clearPartScanned();
                this.$bvModal.hide('remove-part-modal');
                this.$emit('close');
            },

            /** Remove the part scanned */
            removePartScanned() {
                this.removePart({
                    jobId:this.jobId,
                    deviceId: this.deviceId,
                    stockSerialNumberId:this.partScanned.id
                }).then(() => {
                    this.closeModal();
                    this.$emit('partRemoved');
                });
            },
        }
    }
</script>

<style scoped>

</style>