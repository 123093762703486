<template>
    <b-modal
            id="request-additional-parts-modal"
            title="Request additional parts"
            size="lg"
            no-footer>

        <!-- Request additional parts -->
        <template v-if="!loadingParts && !requestingParts && !newRepairKitId && !stockErrorItems.length">
            <div class="row">
                <div class="col-12">
                    <h3 class="mb-2">Select additional parts</h3>
                    <p class="text-sm">A new repair kit will be built and delivered to you with only the parts that are selected here.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <ul class="list-group selection-list">
                        <li class="list-group-item" v-for="part in availablePartsRowOne" :key="part.id" :class="{'active': partRequested(part)}">
                            <label class="custom-control custom-checkbox mb-0">
                                <input type="checkbox" class="custom-control-input" :id="part.id" :checked="partRequested(part)" @change="toggleRequestedPart(part)">
                                <label class="custom-control-label mb-0" :for="part.id">{{ part.title_with_colour }}</label>
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="col-6">
                    <ul class="list-group selection-list">
                        <li class="list-group-item" v-for="part in availablePartsRowTwo" :key="part.id" :class="{'active': partRequested(part)}">
                            <label class="custom-control custom-checkbox mb-0">
                                <input type="checkbox" class="custom-control-input" :id="part.id" :checked="partRequested(part)" @change="toggleRequestedPart(part)">
                                <label class="custom-control-label mb-0" :for="part.id">{{ part.title_with_colour }}</label>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <!-- No stock available -->
        <template v-if="stockErrorItems.length">
            <empty-state
                    class="pb-0"
                    statusIcon="error"
                    title="Stock not available"
                    subtitle="The below stock items are unavailable:">
            </empty-state>

            <div class="row mb-3">
                <div class="col-12 text-center">
                    <ul class="list-unstyled">
                        <li class="text-sm" v-for="stockErrorItem in stockErrorItems" :key="stockErrorItem">
                            {{ stockErrorItem }}
                        </li>
                    </ul>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <h5>Return this asset to the 'awaiting parts' location</h5>
                </div>
            </div>
        </template>

        <!-- Parts successfully requested -->
        <empty-state
                v-if="!loadingParts && !requestingParts && newRepairKitId"
                statusIcon="success"
                title="These parts are in stock"
                :subtitle="`They will be delivered to you in repair kit ${newRepairKitId}`">
        </empty-state>

        <!-- Loading parts -->
        <empty-state
                v-if="loadingParts"
                class="bg-transparent"
                statusIcon="loading"
                title="Loading available parts"
                subtitle="Please wait">
        </empty-state>

        <!-- Requesting parts -->
        <empty-state
                v-if="requestingParts"
                class="bg-transparent"
                statusIcon="loading"
                title="Checking stock of parts"
                subtitle="Please wait">
        </empty-state>

        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="$bvModal.hide('request-additional-parts-modal')" :disabled="requestingParts">{{ (newRepairKitId || stockErrorItems.length) ? 'Close' : 'Cancel' }}</button>
            <button type="button" class="btn btn-success" @click="requestParts()" :disabled="loadingParts || requestingParts || !requestedPartIds.length" v-if="!newRepairKitId && !stockErrorItems.length">Request parts</button>
        </template>

        <remove-parts-modal></remove-parts-modal>
    </b-modal>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    export default {
        data() {
            return {
                loadingParts: false,
                requestingParts: false,
                partsForDevice: [],
                requestedPartIds: [],

                stockErrorItems: [],

                newRepairKitId: null
            }
        },

        computed: {
            ...mapGetters([
                "job",
                "device",
                "isRefurbOrMailIn"
            ]),

            availablePartsRowOne() {
                let parts = [];
                for (let i = 0; i < this.partsForDevice.length; i++) {
                    if (i < (this.partsForDevice.length / 2)) {
                        parts.push(this.partsForDevice[i]);
                    }
                }
                return parts;
            },

            availablePartsRowTwo() {
                let parts = [];
                for (let i = 0; i < this.partsForDevice.length; i++) {
                    if (i >= (this.partsForDevice.length / 2)) {
                        parts.push(this.partsForDevice[i]);
                    }
                }
                return parts;
            }
        },

        methods: {
            ...mapActions([
                "displayToast",
                "requestAdditionalParts",
                "fetchAvailableParts"
            ]),

            openModal() {
                this.loadingParts = false;
                this.error = null;
                this.newRepairKitId = null;
                this.requestedPartIds = [];
                this.partsForDevice = [];
                this.stockErrorItems = [];
                this.fetchAvailablePartsForDevice();
                this.$bvModal.show('request-additional-parts-modal')
            },

            fetchAvailablePartsForDevice() {
                let that = this;

                this.fetchAvailableParts({deviceId: this.device.id})
                    .then(response => {
                        that.loadingParts = false;
                        that.partsForDevice = response.data.data;
                    })
                    .catch(response => {
                        this.displayToast({text: response.response.data.messages[0], type: 'error'});
                        that.loadingParts = false;
                    });
            },

            requestParts() {
                if (this.requestingParts) return;

                this.requestingParts = true;

                this.requestAdditionalParts({
                    deviceId: this.device.id,
                    requestedPartIds: this.requestedPartIds

                }).then(response => {
                    this.requestingParts = false;
                    this.newRepairKitId = response.data.data.repair_kit_id;

                }).catch(response => {
                    let errors = response.response.data.messages;

                    if (errors.missing_stock) {
                        this.stockErrorItems = errors.missing_stock;

                    } else {
                        this.displayToast({text: errors[0], type: 'error'});
                    }

                    this.requestingParts = false;
                });
            },

            partRequested(part) {
                return this.requestedPartIds.some(el => el.id === part.id);
            },

            toggleRequestedPart(part) {
                for (let i = 0; i < this.requestedPartIds.length; i++) {
                    if (this.requestedPartIds[i] === part.id) {
                        this.requestedPartIds.splice(i, 1);
                        return;
                    }
                }
                this.requestedPartIds.push(part.id);
            }
        }
    }
</script>

<style scoped>

</style>
