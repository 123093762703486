const state = {
  jitRepairKits: [],
  loading: false,
  error: null,
};

const getters = {
  jitRepairKits: state => state.jitRepairKits,
  jitRepairKitsLoading: state => state.loading
};

const actions = {
  loadJitOrdersAwaitingRepairKits({commit}) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);

    this.$axios.get("/api/v4/stock/orders/jit/pending-kit").then(response => {
      commit('SET_LOADING', false);
      if (response.data && response.data.data) {
        commit('SET_JIT_REPAIR_KITS', response.data.data);
      }
    }).catch(e => {
      commit('SET_LOADING', false);
      commit('SET_ERROR', e.message);
    })
  },
};

const mutations = {
  SET_JIT_REPAIR_KITS(state, payload) {
    state.jitRepairKits = payload;
    console.log(payload);
  },
  SET_LOADING(state, payload = false) {
    state.loading = payload;
  },
  SET_ERROR(state, payload) {
    state.error = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
}