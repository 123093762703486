import {firestoreAction} from "vuexfire";
import {db} from '@/firebase'

const state = {
    symptomOneCodes: null,
    symptomTwoCodes: null,
    symptomThreeCodes: null,
    symptomCodeOne:null,
    symptomCodeTwo:null,
    symptomCodeThree:null,
    currentSymptomCode:"one"
};

const getters = {
    symptomOneCodes: state => state.symptomOneCodes,
    symptomTwoCodes: state => state.symptomTwoCodes,
    symptomThreeCodes: state => state.symptomThreeCodes,
    symptomCodeOne: state => state.symptomCodeOne,
    symptomCodeTwo: state => state.symptomCodeTwo,
    symptomCodeThree: state => state.symptomCodeThree,
    currentSymptomCode: state => state.currentSymptomCode
};

const actions = {
    /** Load the current symptom codes for the device */
    loadSymptomCodesForDevice: ({dispatch}, {jobId, deviceId}) => {
        return db.collection(`jobs/${jobId}/devices/${deviceId}/symptom_codes/`)
            .limit(1)
            .get()
            .then(querySnapshot => {
                const queryDocumentSnapshot = querySnapshot.docs[0];

                if (!queryDocumentSnapshot) {
                    return;
                }

                let data = queryDocumentSnapshot.data();

                dispatch("setSymptomCodeOne", {
                    title:data.cat_1_title,
                    code:data.cat_1_code,
                }).then(() => {
                    dispatch("setSymptomCodeTwo", {
                        title:data.cat_2_title,
                        code:data.cat_2_code,
                    });
                }).then(() => {
                    dispatch("setSymptomCodeThree", {
                        title:data.cat_3_title,
                        code:data.cat_3_code,
                    });
                });
            });
    },

    /** Load all symptom code ones from firestore */
    loadSymptomOneCodes: firestoreAction(({bindFirestoreRef}) => {
        return new Promise((resolve, reject) => {
            bindFirestoreRef(
                'symptomOneCodes',
                db.collection(`symptom_codes`)
                    .orderBy('title')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load all symptom code two from firestore */
    loadSymptomTwoCodes: firestoreAction(({bindFirestoreRef, state}) => {
        return new Promise((resolve, reject) => {
            bindFirestoreRef(
                'symptomTwoCodes',
                db.collection(`symptom_codes/${state.symptomCodeOne.code}/category_2`)
                    .orderBy('title')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load all symptom code three from firestore */
    loadSymptomThreeCodes: firestoreAction(({bindFirestoreRef, state}) => {
        return new Promise((resolve, reject) => {
            bindFirestoreRef(
                'symptomThreeCodes',
                db.collection(`symptom_codes/${state.symptomCodeOne.code}/category_2/${state.symptomCodeTwo.code}/category_3`)
                    .orderBy('title')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Set code one */
    setSymptomCodeOne({commit, dispatch}, code) {
        commit("SET_CURRENT_SYMPTOM_CODE", "two");
        commit("SET_SYMPTOM_CODE_ONE", code);
        dispatch('loadSymptomTwoCodes');
    },

    /** Set code two */
    setSymptomCodeTwo({commit, dispatch}, code) {
        commit("SET_CURRENT_SYMPTOM_CODE", "three");
        commit("SET_SYMPTOM_CODE_TWO", code);
        dispatch('loadSymptomThreeCodes');
    },

    /** Set code three */
    setSymptomCodeThree({commit}, code) {
        return new Promise((resolve) => {
            commit("SET_CURRENT_SYMPTOM_CODE", "complete");
            commit("SET_SYMPTOM_CODE_THREE", code);
            resolve();
        });
    },

    /** Clear code one, two and three */
    editSymptomCodeOne({commit}) {
        commit("SET_SYMPTOM_CODE_ONE", null);
        commit("SET_SYMPTOM_CODE_TWO", null);
        commit("SET_SYMPTOM_CODE_THREE", null);
        commit("SET_SYMPTOM_TWO_CODES", []);
        commit("SET_SYMPTOM_THREE_CODES", []);
        commit("SET_CURRENT_SYMPTOM_CODE", "one");
    },

    /** Clear code two and three */
    editSymptomCodeTwo({commit}) {
        commit("SET_SYMPTOM_CODE_TWO", null);
        commit("SET_SYMPTOM_CODE_THREE", null);
        commit("SET_SYMPTOM_THREE_CODES", []);
        commit("SET_CURRENT_SYMPTOM_CODE", "two");
    },

    /** Clear code three */
    editSymptomCodeThree({commit}) {
        commit("SET_SYMPTOM_CODE_THREE", null);
        commit("SET_CURRENT_SYMPTOM_CODE", "three");
    },

    /** Confirm the selected symptom codes */
    confirmSymptomCodes: ({state}, {jobId, deviceId}) => {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices/${deviceId}/symptom_codes`).doc(deviceId)
                .set({
                    cat_1_code: state.symptomCodeOne.code,
                    cat_1_title: state.symptomCodeOne.title,
                    cat_2_code: state.symptomCodeTwo.code,
                    cat_2_title: state.symptomCodeTwo.title,
                    cat_3_code: state.symptomCodeThree.code,
                    cat_3_title: state.symptomCodeThree.title,
                }).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
        });
    }
};

const mutations = {
    SET_SYMPTOM_CODE_ONE(state, code) {
        state.symptomCodeOne = code;
    },
    SET_SYMPTOM_CODE_TWO(state, code) {
        state.symptomCodeTwo = code;
    },
    SET_SYMPTOM_CODE_THREE(state, code) {
        state.symptomCodeThree = code;
    },
    SET_SYMPTOM_TWO_CODES(state, code) {
        state.symptomTwoCodes = code;
    },
    SET_SYMPTOM_THREE_CODES(state, code) {
        state.symptomThreeCodes = code;
    },
    SET_CURRENT_SYMPTOM_CODE(state, code) {
        state.currentSymptomCode = code;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}