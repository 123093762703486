<template>
    <!-- Scan Serial Number Modal -->
    <b-modal
            body-class="d-flex flex-column py-0"
            id="part-serial-no-scan-modal"
            no-close-on-backdrop
            no-close-on-esc
            title="Add part">

        <!-- Header close button -->
        <template v-slot:modal-header-close>
            <span @click="closeModal">×</span>
        </template>

        <!-- Barcode picker -->
        <div class="mb-3">
          <scanner
              isModal
              class="d-md-none"
              @barcodeHasBeenScanned="barcodeScanned($event)"
          ></scanner>
        </div>

        <!----------------------------
          Can Scan
        ------------------------------>
        <template v-if="canScan">
            <ul class="timeline-list mb-0">

                <!----------------------------
                  WeFix Serial Number
                ------------------------------>
                <li>
                    <dl>
                        <dt>WeFix stock serial number</dt>
                        <dd>{{ barcode.id }}</dd><!-- TODO: Display the stock serial number - is this correct? -->
                    </dl>
                </li>

                <!----------------------------
                  New Part Serial Number
                ------------------------------>
                <!-- If the new part number isn't scanned -->
                <li class="current" v-if="!newPartScanned">

                    <!-- Empty State -->
                    <empty-state
                            align="left"
                            class="p-0"
                            icon="scanner"
                            inline
                            small
                            subtitle="Scan the serial number of the NEW PART"
                            title="">
                    </empty-state>
                    <button class="btn btn-danger btn-sm d-none d-md-inline-block mt-2" @click="cantScanNewSerial">Can't scan serial number</button>
                </li>

                <!-- If the new part number is scanned -->
                <li v-if="newPartScanned">
                    <dl>
                        <dt>New part serial number</dt>
                        <dd>
                            <template v-if="newPartScanned !== 'CNS'">{{ newPartScanned }}</template><template v-if="newPartScanned === 'CNS'">Could not scan</template>
                            <button class="btn btn-link btn-sm" @click="editNewPartSerial">Edit</button>
                        </dd>
                    </dl>
                </li>

                <!----------------------------
                  Old Part Serial Number
                ------------------------------>
                <!-- If the new part number isn't scanned -->
                <li class="disabled" v-if="(newPartScanned === null && !oldPartScanned) || (editingNewPartSerial && !oldPartScanned)">
                    <dl>
                        <dt>Old part serial number</dt>
                        <dd>Scan new part serial first</dd>
                    </dl>
                </li>

                <!-- If the new part number is scanned but the old part isn't -->
                <li class="current" v-if="newPartScanned !== null && !oldPartScanned && !editingNewPartSerial">

                    <!-- Empty State -->
                    <empty-state
                            align="left"
                            class="p-0"
                            icon="scanner"
                            inline
                            small
                            subtitle="Scan the serial number of the OLD PART"
                            title="">
                    </empty-state>
                    <button class="btn btn-danger btn-sm d-none d-md-inline-block mt-2" @click="cantScanOldSerial">Can't scan serial number</button>
                </li>

                <!-- If the old part number is scanned -->
                <li v-if="(newPartScanned !== null && oldPartScanned) || (editingNewPartSerial && oldPartScanned)">
                    <dl>
                        <dt>Old part serial number</dt>
                        <dd>
                            <template v-if="oldPartScanned !== 'CNS'">{{ oldPartScanned }}</template><template v-if="oldPartScanned === 'CNS'">Could not scan</template>
                            <button class="btn btn-link btn-sm" @click="oldPartScanned = false">Edit</button>
                        </dd>
                    </dl>
                </li>

                <!----------------------------
                  OCTA FAB QR
                ------------------------------>
                <template v-if="isOctaFabQr && newPartScanned && oldPartScanned">
                    <li v-if="!octaFabQrFrameScanned">
                        <dl>
                            <dt>Is the Frame required to be replaced?</dt>
                            <dd v-if="octaFabQrFrameRequired === null">
                                <button class="btn btn-info mr-3" @click="octaFabQrFrameRequired = false">Yes
                                </button>
                                <button class="btn btn-info" @click="octaFabQrFrameRequired = true">No
                                </button>
                            </dd>
                            <dd v-if="octaFabQrFrameRequired === false">
                                Frame serial will be captured when the Frame part is added.
                                <button class="btn btn-link btn-sm" @click="octaFabQrFrameRequired = null">Edit</button>
                            </dd>
                            <dd v-if="octaFabQrFrameRequired === true">
                                <!-- Empty State -->
                                <empty-state
                                    align="left"
                                    class="p-0"
                                    icon="scanner"
                                    inline
                                    small
                                    subtitle="Scan the serial number of the OLD FRAME"
                                    title="">
                                </empty-state>
                                <button class="btn btn-danger btn-sm d-none d-md-inline-block mt-2" @click="cantScanOldFrameForOctaFabQr">Can't scan serial number</button>
                                <button class="btn btn-link btn-sm" @click="octaFabQrFrameRequired = null">Edit</button>
                            </dd>
                        </dl>
                    </li>

                    <li v-if="octaFabQrFrameScanned">
                        <dl>
                            <dt>Old part serial number for Frame</dt>
                            <dd>
                                <template v-if="octaFabQrFrameScanned !== 'CNS'">{{ octaFabQrFrameScanned }}</template><template v-if="octaFabQrFrameScanned === 'CNS'">Could not scan</template>
                                <button class="btn btn-link btn-sm" @click="octaFabQrFrameScanned = null">Edit</button>
                            </dd>
                        </dl>
                    </li>
                </template>

            </ul>

            <div class="mb-4 mt-auto row d-md-none">
                <div class="col-12 text-center">
                    <button class="btn btn-info btn-sm" @click="canScan = false">Issues scanning?</button>
                </div>
            </div>

        </template>

        <!----------------------------
          Can't Scan
        ------------------------------>
        <template v-if="!canScan">

            <ul class="timeline-list mb-0">
                <li>
                    <h4 class="mb-2">Step 1</h4>
                    <p>
                        On your computer go to {{appUri}}/jobs/{{jobId}}/repair/{{deviceId}}
                    </p>
                </li>
                <li>
                    <h4 class="mb-2">Step 2</h4>
                    <p>
                        Use your Honeywell handheld scanner to first scan the part, then the old part serial number, followed by the new part serial number
                    </p>
                </li>
                <li>
                    <h4 class="mb-2">Step 3</h4>
                    <p>
                        When both serial numbers have been scanned, you can continue the repair either on your computer or mobile
                    </p>
                </li>
            </ul>

            <div class="mb-4 mt-auto row">
                <div class="col-12 text-center">
                    <button class="btn btn-info btn-sm" @click="canScan = true">Back to scanner</button>
                </div>
            </div>
        </template>

        <template v-slot:modal-footer>
            <button type="button" class="btn btn-success ml-auto" :disabled="!newPartScanned || !oldPartScanned || (octaFabQrFrameRequired === true && !octaFabQrFrameScanned)" @click="saveSerials()">Add part</button>
        </template>

    </b-modal>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {

        props:['barcode', 'deviceId', 'jobId', 'serialTypeId'],

        data() {
            return {
                canScan: true,
                editingNewPartSerial: false,
                newPartScanned: null,
                oldPartScanned: null,

                octaFabQrFrameRequired: null,
                octaFabQrFrameScanned: null
            }
        },

        computed: {
            ...mapGetters(['appUri', 'appRegion']),

            isOctaFabQr() {
                return this.serialTypeId === 2;
            }
        },

        mounted() {
            this.canScan = true;
        },

        watch: {
            serialTypeId: function() {
                if (this.serialTypeId === 2) {
                    this.octaFabQrFrameRequired = null;
                } else {
                    this.octaFabQrFrameRequired = false;
                }
            }
        },

        methods: {
            ...mapActions([
                "displayToast"
            ]),

            /** Barcode scanned */
            barcodeScanned(barcode) {
                let barcodeData = barcode;

                if (barcode.data) {
                    barcodeData = barcode.data;
                }

                if (typeof barcodeData !== 'string' && typeof barcodeData !== 'number') {
                    return this.displayToast({text: 'Invalid serial number. Please ensure you scan the part serial number', type: 'danger'});
                }

                if (!this.newPartScanned) {
                    if (barcodeData == this.oldPartScanned) {
                        return this.displayToast({text: 'This is the OLD part serial number. Please scan the NEW part serial number', type: 'danger'});
                    }

                    this.newPartScanned = barcodeData
                    this.editingNewPartSerial = false
                    return;
                }

                if (this.newPartScanned && !this.oldPartScanned) {
                    if (barcodeData == this.newPartScanned) {
                        return this.displayToast({text: 'This is the NEW part serial number. Please scan the OLD part serial number', type: 'danger'});
                    }

                    this.oldPartScanned = barcodeData;
                    this.canScan = true;
                }

                if (this.newPartScanned && this.oldPartScanned && this.octaFabQrFrameRequired && !this.octaFabQrFrameScanned) {
                    if (barcodeData == this.oldPartScanned || barcodeData == this.newPartScanned) {
                        return this.displayToast({text: 'Please scan the OLD part serial number for the FRAME, not this current part', type: 'danger'});
                    }

                    this.octaFabQrFrameScanned = barcodeData;
                    this.canScan = true;
                }
            },

            cantScanNewSerial() {
                this.newPartScanned = "CNS"
            },

            cantScanOldSerial() {
                this.oldPartScanned = "CNS";
            },

            cantScanOldFrameForOctaFabQr() {
                this.octaFabQrFrameScanned = "CNS";
            },

            clearSerials() {
                this.newPartScanned = null;
                this.oldPartScanned = null;
                this.octaFabQrFrameRequired = null;
                this.octaFabQrFrameScanned = null;
            },

            closeModal() {
                this.clearSerials();
                this.canScan = true;
                this.$bvModal.hide('part-serial-no-scan-modal');
                this.$emit('close');
            },

            editNewPartSerial() {
                this.newPartScanned = false;
                this.editingNewPartSerial = true;
            },

            /** Save the serial numbers */
            saveSerials() {
                this.$emit('serialsScanned', {
                    newSerial: this.newPartScanned,
                    oldSerial: this.oldPartScanned,
                    octaFabQrFrameOldSerial: this.octaFabQrFrameScanned
                });
                this.closeModal();
            }
        }
    }
</script>

<style scoped>

</style>