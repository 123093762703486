<template>
    <div class="search-banner" :class="{'sticky-top': sticky}">
        <div class="container pb-0 pt-1">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa" :class="'fa-' + icon"></i></span>
                </div>
                <!-- Normal input - set "type" to change keyboard on mobile -->
                <input v-if="!numeric" :type="type" class="form-control" :placeholder="placeholder" v-model="enteredValue" @keyup="emitSearchValue()">
                <!-- Display numeric keypad on mobile -->
                <input v-if="numeric" type="text" inputmode="numeric" pattern="[0-9]*" class="form-control" :placeholder="placeholder" v-model="enteredValue" @keyup="emitSearchValue()">
                <span class="input-clear" v-if="enteredValue" @click="clearEnteredValue()"><i class="fas fa-times-circle"></i></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            icon: {
                type: String,
                default: "search"
            },
            numeric: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: "Search"
            },
            filteredSearchValue: {
                default: ""
            },
            sticky: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: "text"
            },
        },

        data() {
            return {
                enteredValue: ""
            }
        },


        methods: {
            /** Clear search input */
            clearEnteredValue() {
                this.enteredValue = "";
                this.emitSearchValue();
            },

            /** Pass searched value to parent */
            emitSearchValue() {
                this.$emit("update:filteredSearchValue", this.enteredValue);
            }
        }
    }
</script>

<style scoped>

</style>