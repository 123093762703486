<template>
    <div>
        <article class="card card-body note mb-4" :class="{'card-body-sm': small}" v-for="note in jobNotes" :key="note.id">
            <h5 class="author">{{ note.added_by }} <small class="text-normal" v-if="note.created_at">- {{ moment.unix(note.created_at.seconds).format('ddd Do MMM \'YY [at] HH:mm') }}</small></h5>
            <p v-html="note.text"></p>
        </article>
    </div>
</template>

<script>
    export default {
        props: {
            jobNotes: {},
            small: {
                type: Boolean,
                default: false
            },
        }
    }
</script>

<style scoped>

</style>