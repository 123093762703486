<script>
export default {
    // eslint-disable-next-line no-undef
    mixins: [Laraform],

    created() {
        this.on('response', (response) => {
            this.$emit('response', response);
        })
    },
}
</script>