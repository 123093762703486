export default class User {

	constructor(details) {
		this.details = details;
	}

	/**
	 * Check if the user is from reviveaphone
	 * @returns {boolean}
	 */
	get isFromWeFix()
	{
		return this.details.company.name === 'Reviveaphone';
	}

    /**
	 * Api key for the user
     * @returns String
     */
	apiKey()
	{
		if (this.details && this.details.api) {
            return this.details.api.key;
        }

        return "no_key";
    }

	/**
	 * Check if the users company has a permission
	 * 
	 * @param  {string} tag 
	 * @return {boolean}        
	 */
	companyHasPermission(tag) 
	{
		let permissionCategories = this.details.company.permissions;

		for (let category in permissionCategories) {

			let permissions = permissionCategories[category];

			for (let i in permissions) {
				let permission = permissions[i];

				if (permission && permission.tag == tag) {
					return true;
				}
			}
		}

		return false;
	}

	/**
	 * Check if the users role has a permission
	 * 
	 * @param  {string} tag 
	 * @return {boolean}        
	 */
	roleHasPermission(tag)
	{
		let permissionCategories = this.details.role.permissions;

		for (let category in permissionCategories) {

			let permissions = permissionCategories[category];

			for (let i in permissions) {
				let permission = permissions[i];

				if (permission && permission.tag == tag) {
					return true;
				}
			}
		}

		return false;
	}

	/**
	 * Check if a user can do a certain action
	 * 
	 * @param  {string} action 
	 * @return {boolean}        
	 */
	can(action) {
		if (this.roleIs('superAdmin') || this.roleIs('areaManager')) {
			return true;
		}

		return this.roleHasPermission(action);
	}

	/**
	 * Check user role
	 * @returns {boolean}
	 */
	roleIs(roleTag) {
		return this.details.role.tag === roleTag;
	}

	/**
	 * Check if the user is a technician
	 * @returns {boolean}
	 */
	isTechnician() {
		return this.roleIs('technician');
	}

	/**
	 * Check if this user can manage technician jobs
	 * @returns {boolean}
	 */
	isJobManager() {
		return (
			this.roleIs('areaManager') ||
			this.roleIs('superAdmin')
		);
	}

	/**
	 * Check if the user is an area manager
	 * @returns {boolean}
	 */
	isAreaManager() {
		return this.roleIs('areaManager');
	}

	/**
	 * Check if the user is a super admin
	 * @returns {boolean}
	 */
	isSuperAdmin() {
		return this.roleIs('superAdmin');
	}
}