<template>
    <!-- Part Status Modal -->
    <b-modal
            body-class="pt-0"
            id="part-status-modal"
            no-footer
            title="Part fault status">

        <!-- Header close button -->
        <template v-slot:modal-header-close>
            <span @click="closeModal">×</span>
        </template>

        <!-- Fault status list -->
        <template v-if="partScanned">
            <label>Select the fault status for this part</label>
            <ul class="list-group selection-list">
                <li class="list-group-item" :class="{'active': faultStatus === 'Not faulty'}">
                    <label class="custom-control custom-radio mb-0">
                        <input type="radio" class="custom-control-input" name="faulty-status" value="Not faulty" id="not-faulty-check" v-model="faultStatus">
                        <label class="custom-control-label mb-0" for="not-faulty-check">Part is not faulty</label>
                    </label>
                </li>
                <li class="list-group-item" :class="{'active': faultStatus === 'Faulty'}">
                    <label class="custom-control custom-radio mb-0">
                        <input type="radio" class="custom-control-input" name="faulty-status" value="Faulty" id="faulty-check" v-model="faultStatus">
                        <label class="custom-control-label mb-0" for="faulty-check">Part is faulty</label>
                    </label>
                </li>
                <li class="list-group-item" :class="{'active': faultStatus === 'Broken'}">
                    <label class="custom-control custom-radio mb-0">
                        <input type="radio" class="custom-control-input" name="faulty-status" value="Broken" id="broken-check" v-model="faultStatus">
                        <label class="custom-control-label mb-0" for="broken-check">Part broken on site</label>
                    </label>
                </li>
            </ul>
        </template>


        <!-- Footer -->
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="closeModal">Cancel</button>
            <button type="button" class="btn btn-success">Update status</button>
        </template>
    </b-modal>
</template>

<script>
    export default {
        data() {
            return {
                faultStatus: "Not faulty",
                partScanned: true
            }
        },

        methods: {
            closeModal() {
                this.$bvModal.hide('part-status-modal');
                this.$emit('close');
            }
        }
    }
</script>

<style scoped>

</style>