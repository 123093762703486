<template>
    <b-modal
            id="unsaved-changes-modal"
            no-footer
            title="You have unsaved changes">
        <empty-state
                statusIcon="warning"
                statusIconSize="4x"
                title="Are you sure you want to leave this page?"
                subtitle="You have unsaved changes which will be permanently lost if you leave this page">
        </empty-state>
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-danger" @click="goBack">Leave page</button>
            <button type="button" class="btn btn-success" @click="$bvModal.hide('unsaved-changes-modal')">Stay on page</button>
        </template>
    </b-modal>
</template>

<script>
    export default {
        props: ['backTo', 'jobId'],


        computed: {
            expectedJobId() {
                if (!this.jobId) {
                    return null;
                }
                return this.jobId;
            },

            routerLinkName() {
                if (this.device.current_step) {
                    return this.device.current_step
                }

                return 'repair'
            }
        },

        methods: {
            goBack() {
                if (this.backTo === 'job') {
                    this.$router.push({name: 'job', props:{jobId: this.jobId}});
                }
                this.$bvModal.hide('unsaved-changes-modal');
            }
        }
    }
</script>

<style scoped>

</style>