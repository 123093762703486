<template>
    <div class="card card-body table-layout" :class="{ 'is-past' : isPast(overtime.date) }">
        <div class="card-cell">
            {{ moment(overtime.date).format('ddd Do MMM \'YY') }}
        </div>
        <div class="card-cell">
            <template v-if="overtime.start_time && overtime.end_time">{{ moment(overtime.start_time, 'HH:mm:SS').format('HH:mm') }} - {{ moment(overtime.end_time, 'HH:mm:SS').format('HH:mm') }}</template>
            <template v-if="!overtime.start_time || !overtime.end_time">Time not specified</template>
        </div>
        <div class="card-cell text-right">
            <button class="btn btn-danger btn-sm" v-if="showDeleteButton" @click="cancelOvertime">Cancel</button>
        </div>
    </div>
</template>

<script>
    const moment = require('moment');
    export default {
        props:["overtime"],

        computed: {
            /** Show the delete button */
            showDeleteButton() {
                var now = moment().format('YYYY-MM-DD');

                if (this.overtime.date > now) {
                    return true;
                }

                return false;
            }
        },

        methods: {
            /** Show the cancel modal */
            cancelOvertime() {
                this.$emit("openNoteModal");
            },

            /** Show the cancel modal */
            isPast(date) {
                return moment(date).isBefore(moment())
            }
        }
    }
</script>

<style scoped>
    .is-past {
        opacity: .5;
    }
</style>