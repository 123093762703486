<template>
    <!-- Faulty Part Modal -->
    <b-modal
            body-class="pt-0"
            id="part-faulty-modal"
            no-close-on-backdrop
            no-close-on-esc
            no-footer
            title="Faulty part">

        <!-- Header close button -->
        <template v-slot:modal-header-close>
<!--            <span v-if="markPartType !== 'broken'" @click="closeModal">×</span>-->
            <span v-if="!markPartType || markPartType === 'pass'" @click="closeModal">×</span>
            <span v-if="markPartType"></span>
        </template>

        <div v-if="!markPartType" class="form-group text-center">
            <label class="mb-4">Is this part physically damaged?</label>

            <div class="col-auto">
                <button class="btn btn-info btn-block mb-3" @click="markPartType = 'broken'">Yes, it's physically damaged</button>
            </div>

            <div class="col-auto">
                <button class="btn btn-info btn-block" @click="markPartType = 'faulty'">No, the part is faulty</button>
            </div>
        </div>

        <div v-if="markPartType === 'broken'" class="text-center">
            <empty-state
                statusIcon="warning"
                statusIconSize="4x"
                title='Part is Physically Damaged'
                subtitle="You must contact and report this to the technicial support team before you may continue with the repair.">
            </empty-state>
        </div>

        <div v-if="markPartType === 'faulty'" class="text-center">
          <empty-state
              statusIcon="warning"
              statusIconSize="4x"
              title='Part is Faulty'
              subtitle="You must contact and report this to the technicial support team before you may continue with the repair.">
          </empty-state>
        </div>

        <div v-if="markPartType === 'pass'">
            <!-- Barcode picker -->
            <div class="mb-3">
                <scanner
                    isModal
                    @barcodeHasBeenScanned="barcodeScanned($event)"
                ></scanner>
            </div>

            <!-- Empty state -->
            <empty-state
                v-if="!partScanned"
                icon="scanner"
                title="Scan the faulty part">
            </empty-state>

            <!-- Scanned Part -->
            <template v-if="partScanned">
                <label>Faulty part</label>
                <ul class="list-group">
                    <li class="list-group-item">
                        {{ stockItem.title }}
                    </li>
                </ul>
            </template>
        </div>

        <!-- Footer -->
        <template v-slot:modal-footer>
            <button v-if="!markPartType || markPartType === 'pass'" type="button" class="btn btn-light" @click="closeModal">Cancel</button>
            <button v-if="markPartType === 'pass'" type="button" class="btn btn-success" :disabled="!partScanned" @click="partScannedFaulty()">Mark part as faulty</button>

<!--            <button v-if="canCloseBrokenPartModal" type="button" class="btn btn-success btn-block" @click="closeModal()">Confirm part is set to the correct status</button>-->
        </template>

    </b-modal>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {

        props:['jobId', 'deviceId'],

        data() {
            return {
                markPartType: null,
                barcode: null,
                partScanned: false
            }
        },

        computed: {
            ...mapGetters([
                "appRegion",
                "deviceParts",
                "devicePartsFaulty",
                "stockItem",
                "isJobManager"
            ]),

            canCloseBrokenPartModal() {
                return (
                    this.markPartType === 'broken' &&
                    this.isJobManager
                );
            }
        },

        mounted() {
            this.clearPartScanned();
        },

        methods: {
            ...mapActions([
                "displayToast",
                "loadStockItem",
                "markPartAsFaulty"
            ]),

            /** On barcode scanned */
            barcodeScanned(barcode) {
                let haltProcess = false;
                this.partScanned = false;
                this.barcode = barcode;

                this.devicePartsFaulty.forEach(device => {
                    if (barcode.id.toString() === device.id) {
                        this.displayToast({text: 'This part has already been marked as faulty', type: 'danger'});
                        haltProcess = true;
                    }
                });

                if (haltProcess) {
                    return;
                }

                this.loadStockItem({
                    stockItemId: barcode.stock_id
                }).then(() => {
                    this.partScanned = true;
                });
            },

            /** Clear the scanned part */
            clearPartScanned() {
                this.markPartType = null
                // if (true) { Switch this with the below if functionality causes issues, to turn it off
                if (this.appRegion !== 'UK') {
                    this.markPartType = 'pass';
                }
                this.barcode = null;
                this.partScanned = false;
            },

            /** Close the modal */
            closeModal() {
                this.clearPartScanned();
                this.$bvModal.hide('part-faulty-modal');
                this.$emit('close');
            },

            /** Mark the part as faulty */
            partScannedFaulty() {
                this.markPartAsFaulty({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                    stockSerialNumberId: this.barcode.id,
                }).then(() => {
                    this.clearPartScanned();
                    this.closeModal();
                    this.$emit('partMarkedFaulty');
                }).catch(e => {
                    this.displayToast({text: e, type: 'danger'});
                })
            },

        }
    }
</script>

<style scoped>

</style>