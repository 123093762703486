<template>
    <b-modal
        id="job-cant-start-modal"
        hide-footer
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        no-footer
        title="Can't do this job">
        <h2>Please do the following:</h2>
        <ul class="timeline-list mb-0">
            <li>
                <h4 class="mb-2">Step 1</h4>
                <p>
                    Tap the "Continue" button below and then select a device on the job.
                </p>
            </li>
            <li>
                <h4 class="mb-2">Step 2</h4>
                <p>
                    From the progress bar at the top of the screen, tap the "Change status" button and select the reason why you can't carry out the repair.
                </p>
            </li>
            <li>
                <h4 class="mb-2">Step 3</h4>
                <p>
                    Repeat these steps for each device on the job. Once all devices have been updated, mark the job as complete.
                </p>
            </li>
        </ul>
        <button class="btn btn-light btn-block" @click="closeModal">Continue</button>
        <button class="btn btn-link btn-block" @click="goBack()">Back</button>
    </b-modal>
</template>

<script>
    import {mapActions} from "vuex"

    export default {
        props: [
            "jobId"
        ],

        methods: {
            ...mapActions([
                "setJobCantStartFalse"
            ]),

            /** Close the modal and reload the page */
            closeModal() {
                this.$bvModal.hide("job-cant-start-modal");
                location.reload();
            },

            goBack() {
                this.setJobCantStartFalse({
                    jobId: this.jobId
                }).then(() => {
                    this.$bvModal.hide("job-cant-start-modal")
                });
            }
        }
    }
</script>

<style scoped>

</style>