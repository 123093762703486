<template>
  <div>

    <loading-state
        fullscreen
        v-if="loading">
    </loading-state>

    <div v-if="!loading" class="container">

      <button :disabled="loading" @click="updateStatus" class="btn btn-success form-control create-btn">Update Status</button>
      <div class="container-fluid" v-if="this.saw !== null">
        <!-- Results -->
          <div id="submitted-form">
            <div>
              <h4>SO Details</h4>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label>SO Number:</label>
                    {{ saw.so_number }}
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Device:</label>
                    {{ saw.device_name }}
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Technician:</label>
                    {{ saw.technician }}
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Status:</label>
                    {{ saw.status }}
                  </div>
                </div>
              </div>
              <h4>Submitted Request Details</h4>
              <div class="row">
                <div class="col-md-12">
                  <div class="table-container">
                    <template v-for="field in formInput">
                      <div v-bind:key="field.fieldName" class="row saw_underlined p-3"
                           v-if="field.fieldType !== 'hidden' && field.fieldType !== 'multifile'">
                        <div class="col-md-4"><strong>{{ field.fieldName }}</strong></div>
                        <div class="col-md-6">{{ field.value }}</div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <h4>Other SAW Data</h4>
              <div class="row">
                <div class="col-md-12">
                  <div class="table-container">
                    <div v-bind:key="field.fieldName" v-for="field in otherSawData" class="row saw_underlined p-3">
                      <div class="col-md-4"><strong>{{ field.fieldName }}</strong></div>
                      <div class="col-md-6">{{ field.value }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Images</h4>
              <div class="row">
                <div class="col-md-6">
                  <template v-for="file in saw.submitted_data.files">
                    <div v-bind:key="file.original_name" class="row saw_underlined p-3">
                      <div class="col-md-4"><strong>{{ file.original_name }}</strong></div>
                      <div class="col-md-8">
                        <img :src="'/api/v4/saw/'+$route.params.sawId+'/image?key='+user.api.key+'&path='+file.path"/>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <h4>History</h4>
              <div class="row">
                <div class="col-md-12">
                  <div class="table-container">
                    <div v-bind:key="status.date" v-for="status in saw.history" class="row saw_underlined p-3">
                      <div class="col-md-4"><strong>{{ status.status }}</strong></div>
                      <div class="col-md-6">{{ moment(status.date).format('ddd Do MMM \'YY') }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";

export default {


  data: () => ({
    loading: true,
    saw: null,
    selectedSaw: null,
    buttons: [],
    deviceId: null,
    formInput: [],
    otherSawData: [],
  }),

  created() {
    this.getSawById(this.$route.params.sawId);
  },

  methods: {
    ...mapActions([
      "displayToast",
      "loadSaw",
      "updateSaw",
      "formatDate",
    ]),

    updateStatus() {
      this.loading = true;
      this.displayToast({text: 'Updating now...', type: 'info', duration: 6000});
      this.updateSaw(this.$route.params.sawId).then(() => {
        setTimeout(() => {
          this.getSawById(this.saw.id)
        }, 10000)
      }).catch((errors) => {
        this.displayToast({text: errors.response.data.messages, type: 'error', duration: 6000});
      });
    },

    getSawById(sawId) {
      this.loading = true;
      this.loadSaw(sawId).then((result) => {
        this.saw = result.data;
        this.formInput = [];
        this.otherSawData = [];

        Object.keys(this.saw.submitted_data).forEach(field => {
          if (this.saw.schema[field] !== undefined) {
            if (this.saw.schema[field].type === 'multifile') {
              this.filesPresent = true;
            }
            this.formInput.push({
              fieldType: this.saw.schema[field].type,
              fieldName: this.saw.schema[field].label,
              value: this.saw.submitted_data[field],
            });
          } else {
            if (field === 'reference') {
              return;
            }
            if (typeof this.saw.submitted_data[field] === 'string' || typeof this.saw.submitted_data[field] === 'number') {
              this.otherSawData.push({
                fieldName: field,
                value: this.saw.submitted_data[field],
              });
            }
          }
        });

        if (this.filesPresent && !this.saw.submitted_data.files) {
          this.filesPresent = false;
        }
        this.loading = false;
      }).catch((errors) => {
        this.loading = false;
        this.displayToast({text: errors.response.data.messages, type: 'error', duration: 6000});
      })
    },
  }
}
</script>
