<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M100 100v22H30v-22h70zM77 9l23 23.37V52H30V9h47zm-.5 25.5h22M76.5 9v25.5" class="Combined-Shape" stroke="#00BBDC"/><path d="M12 52h106c5.523 0 10 4.477 10 10v38h0H2V62c0-5.523 4.477-10 10-10zm87 29a5 5 0 100-10 5 5 0 000 10z" class="path" stroke="#0C2340"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>