<template>
    <div class="card card-body" :class="{'card-overlap': markers.length > 0 && isOnline}">
        <div class="row">
            <div class="col-md-12">
                <!-- Status banner -->
                <div class="alert" :class="{'alert-success': job.complete, 'alert-warning': !job.complete}">
                    <animated-success v-if="job.complete"></animated-success>
                    <icon-tools v-if="!job.complete"></icon-tools>
                    <div class="alert-content">
                        Job Status: <strong v-if="job.complete">Completed</strong><strong v-if="!job.complete">Pending</strong>
                    </div>
                    <button class="btn btn-light btn-sm" v-b-modal.waiver-signature-modal v-if="!job.complete && showJobCompleteButton">Mark complete</button>
                </div>
                <h1>{{ job.start_time }} - {{ job.end_time }}</h1>
            </div>
            <dl class="col-md-12">
                <dt>Repair Location</dt>
                <dd><template v-if="job.first_name || job.second_name">{{ job.first_name }} {{ job.second_name }}, </template>{{ job.address_1 }}, <template v-if="job.address_2">{{ job.address_2 }}, </template>{{ job.town }}, <span class="text-uppercase">{{ job.postcode }}</span></dd>
            </dl>
            <div class="col-sm-8 col-lg-9">
                <div class="row">
                    <dl :class="appRegion === 'AU' ? 'col-md-12' : 'col-md-6'">
                        <dt>Order ID</dt>
                        <dd>
                            <template v-if="job.order_id">{{ job.order_id }}</template>
                            <template v-if="!job.order_id">--</template>
                        </dd>
                    </dl>
                    <dl class="col-sm-6">
                        <dt>Customer's phone number</dt>
                        <dd>
                            <template v-if="job.phone_number">{{ job.phone_number }}</template>
                            <template v-if="!job.phone_number">--</template>
                        </dd>
                    </dl>
                    <dl class="col-sm-6">
                        <dt>Customer's email</dt>
                        <dd>
                            <template v-if="job.email">{{ job.email }}</template>
                            <template v-if="!job.email">--</template>
                        </dd>
                    </dl>
                </div>
                <b-navbar toggleable="sm" class="p-0 h-auto row">
                    <b-collapse id="full-details" is-nav>
                        <div class="row m-0 w-100">
                            <dl class="col-sm-6">
                                <dt>Job type</dt>
                                <dd class="text-capitalize">
                                    <template v-if="job.job_type">{{ job.job_type }}</template>
                                    <template v-if="!job.job_type">--</template>
                                </dd>
                            </dl>
                            <template v-if="job.job_type !== 'general'">
                                <dl class="col-sm-6">
                                    <dt>Warranty status</dt>
                                    <dd v-if="job.in_warranty">In-Warranty</dd>
                                    <dd v-if="!job.in_warranty">Out of Warranty</dd>
                                </dl>
                                <dl class="col-sm-6">
                                    <dt>Company</dt>
                                    <dd>
                                        <template v-if="job.company_name">{{ job.company_name }}</template>
                                        <template v-if="!job.company_name">--</template>
                                    </dd>
                                </dl>
                            </template>
                            <dl class="col-sm-6">
                                <dt>ETA</dt>
                                <dd v-if="job.current_eta">{{ job.current_eta }}</dd>
                                <dd v-if="!job.current_eta">--</dd>
                            </dl>
                        </div>
                    </b-collapse>
                </b-navbar>
            </div>
            <div class="col-sm-4 col-lg-3">
                <b-navbar-toggle class="btn btn-outline-light btn-block d-sm-none text-sm mb-3 icon-right" target="full-details">
                    Show <span class="when-opened">less <i class="fa fa-chevron-up"></i></span> <span class="when-closed">more <i class="fa fa-chevron-down"></i></span>
                </b-navbar-toggle>
                <!-- TODO: Add functionality to the 3 buttons below -->
                <a class="btn btn-red btn-block mb-3" v-if="job.latitude && job.longitude" @click="mapsSelector" target="_blank">Get directions</a>
                <a v-if="!job.started_journey" class="btn btn-gradient-success btn-block mb-3" @click="startJourney" target="_blank">Start Journey</a>
                <a v-if="job.started_journey && !job.complete" class="btn btn-gradient-success btn-block mb-3" disabled target="_blank"><span class="additional-tracking-text"><animated-loading class="mr-2" size="sm"></animated-loading> Tracking &</span> Updating ETA</a>
<!--                <a class="btn btn-red btn-block mb-3" v-if="job.latitude && job.longitude" :href="`https://maps.google.com/?daddr=${job.latitude},${job.longitude}`" target="_blank">Get directions</a>-->
                <a class="btn btn-info btn-block mb-3" v-if="job.phone_number" :href="`tel:${job.phone_number}`">Call customer</a>
                <a class="btn btn-info btn-block mb-3" v-if="job.order_id && !isShipmentHandlingOrLunchBreak" :href="`${weFixUri}/company/orders/${job.order_id}`" target="_blank">View in admin</a>
                <!--                            <button class="btn btn-info btn-block">Call SSM</button>-->
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {

        props:['jobId', 'markers', 'showJobCompleteButton'],

        data() {
            return {
                loading: true,
            };
        },

        computed: {
            ...mapGetters([
                "appRegion",
                "devices",
                "job",
                "isTechnician",
                "jobNotes",
                "user",
                "weFixUri",
                "isShipmentHandlingOrLunchBreak"
            ]),
        },

        methods: {
            ...mapActions([
                "loadJob",
                "startJobJourney",
            ]),

            mapsSelector() {
                if /* if we're on iOS, open in Apple Maps */
                ((navigator.platform.indexOf("iPhone") != -1) ||
                    (navigator.platform.indexOf("iPad") != -1) ||
                    (navigator.platform.indexOf("iPod") != -1))
                    window.open("maps://maps.google.com/maps?daddr=" + this.job.latitude +","+ this.job.longitude +"&amp;ll=");
                else /* else use Google */
                    window.open("https://maps.google.com/maps?daddr=" + this.job.latitude +","+ this.job.longitude +"&amp;ll=");
            },

            startJourney() {
                this.startJobJourney({
                    jobId: this.jobId
                }).then(() => {
                    this.$bvModal.hide('job-complete-modal');
                });
            }
        }
    }
</script>

<style scoped>

.additional-tracking-text {
    display: none;
}

@media (max-width: 575px) {
    .additional-tracking-text {
        display: inline-block;
    }
}
</style>