import Vue from 'vue';
import Vuex from 'vuex';
import persisted from 'vuex-persistedstate';
import {vuexfireMutations} from 'vuexfire'

import assets from './modules/assets';
import consignments from './modules/consignments';
import device from './modules/device';
import globals from './modules/globals';
import image from './modules/image';
import irisCodes from './modules/irisCodes';
import job from './modules/job';
import navigation from './modules/navigation';
import note from './modules/note';
import overtime from './modules/overtime';
import parts from './modules/parts';
import problems from './modules/problems';
import repairKit from './modules/repairKit';
import stockItems from './modules/stockItems';
import symptomCodes from './modules/symptomCodes';
import user from './modules/user';
import waiver from './modules/waiver';
import sawRequests from "./modules/sawRequests";
import jitRepairKit from './modules/jitRepairKit';

Vue.use(Vuex);

export const store = new Vuex.Store({
    plugins: [
        persisted({
            paths: [
                'assets',
                'user',
                'repairKit'
            ]
        })
    ],
    state: {},
    modules: {
        assets,
        consignments,
        device,
        globals,
        image,
        job,
        navigation,
        note,
        overtime,
        parts,
        problems,
        repairKit,
        jitRepairKit,
        stockItems,
        irisCodes,
        symptomCodes,
        user,
        waiver,
        sawRequests
    },
    mutations: vuexfireMutations
});

export default store;