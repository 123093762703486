<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M68 48h24.111c.927 0 1.808.405 2.41 1.109l9.715 11.333c.493.575.764 1.308.764 2.066v59.143A6.35 6.35 0 0198.65 128h-67.3a6.35 6.35 0 01-6.35-6.35V62.509c0-.758.271-1.49.764-2.066l9.714-11.333A3.175 3.175 0 0137.888 48H61m33.1-.213l13.788-11.57a2 2 0 012.818.247l9.999 9.724a2 2 0 01-.247 2.818l-14.789 12.57m-69.6-13.789l-13.788-11.57a2 2 0 00-2.818.247l-10 9.724a2 2 0 00.247 2.818l14.79 12.57m.817.075h78.73" class="path" stroke="#0C2340"/><path d="M66.324 49.245L85.24 30.39c1.625-1.629.473-4.393-1.83-4.393H72.243V4.571A2.57 2.57 0 0069.659 2H59.33a2.57 2.57 0 00-2.582 2.571v21.426H45.59c-2.302 0-3.453 2.775-1.829 4.393l18.905 18.855a2.593 2.593 0 003.659 0z" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>