<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M2 2.019V55.98M9 2.02V55.98M23 2.02V55.98M30 2.02V55.98M37 2.02V55.98M44 2.02V55.98M58 2.02V55.98M72 2.02V55.98M79 2.02V55.98" class="highlight" stroke="#00BBDC"/><path d="M76 112c19.33 0 35-15.67 35-35S95.33 42 76 42 41 57.67 41 77s15.67 35 35 35zm25.5-10.5L128 128" class="path" stroke="#0C2340"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>