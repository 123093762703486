<template>
    <b-modal
            id="confirm-modal"
            no-footer
            title="Are you sure?">
        <empty-state
                statusIcon="warning"
                statusIconSize="4x"
                :title="confirmTitle"
                :subtitle="confirmText">
        </empty-state>
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-success" @click="confirm">Confirm</button>
            <button type="button" class="btn btn-warn" @click="$bvModal.hide('confirm-modal')">Cancel</button>
        </template>
    </b-modal>
</template>

<script>
    export default {
        props: [
            'confirmTitle',
            'confirmText',
            'confirmKey',
        ],

        methods: {
            confirm() {
                this.$emit('actionConfirmed', this.confirmKey);
            },
        }
    }
</script>

<style scoped>

</style>