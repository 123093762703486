<template>
    <div>
        <h5 class="mb-2">{{ device.title_with_colour }}</h5>
        <p class="mb-2 text-muted text-sm">IMEI: <template v-if="device.imei">{{ device.imei }}</template><template v-if="!device.imei">Unavailable</template></p>
        <ul class="list-unstyled mt-0 text-muted text-sm">
            <li v-for="repair in repairList" :key="repair.id">
                {{repair.title}}
            </li>
        </ul>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

    export default {
        props: ["device", "job"],

        data() {
            return {
                repairList: []
            }
        },

        mounted() {
            let that = this;

            this.loadRepairsForDevice({jobId: this.job.id, deviceId: this.device.id}).then(function(data) {
                that.repairList = data;
            });
        },

        computed: {
            ...mapGetters([
                "deviceRepairs"
            ]),
        },

        methods: {
            ...mapActions([
                "loadRepairsForDevice",
            ])
        }
    }
</script>

<style scoped>

</style>