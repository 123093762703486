import firebase from 'firebase/app'
import 'firebase/firestore'

let firebaseConfig = {};
if (process.env.NODE_ENV === 'production') {
    if (process.env.VUE_APP_COUNTRY_CODE === "AU") {
        firebaseConfig = {
            apiKey: "AIzaSyDdOtpr0MNqrbWsQPMDkPZbCFO_mLQtSfo",
            authDomain: "wefix-australia.firebaseapp.com",
            databaseURL: "https://wefix-australia.firebaseio.com",
            projectId: "wefix-australia",
            storageBucket: "wefix-australia.appspot.com",
            messagingSenderId: "915379235604",
            appId: "1:915379235604:web:3eb06103e7679a4cfd7001"
        };


    } else if (process.env.VUE_APP_COUNTRY_CODE === "CA") {
        firebaseConfig = {
            apiKey: "AIzaSyBTk_C8Dsd2mFWnLwwKha-pdE0kGW-MyDI",
            authDomain: "wefix-canada.firebaseapp.com",
            databaseURL: "https://wefix-canada.firebaseio.com",
            projectId: "wefix-canada",
            storageBucket: "wefix-canada.appspot.com",
            messagingSenderId: "350138037796",
            appId: "1:350138037796:web:7049751d1d74d6ad2f4ab1"
        };

    } else if (process.env.VUE_APP_COUNTRY_CODE === "DE") {
        firebaseConfig = {
            apiKey: "AIzaSyDUGSIJqZ8nA8i3z25dNDr4Ks71wSIwrmg",
            authDomain: "likewize-repair---germany.firebaseapp.com",
            databaseURL: "https://likewize-repair---germany.firebaseio.com",
            projectId: "likewize-repair---germany",
            storageBucket: "likewize-repair---germany.appspot.com",
            messagingSenderId: "287283287218",
            appId: "1:287283287218:web:0e1116b1dcc32bb766a5ab"
        };

    } else if (process.env.VUE_APP_COUNTRY_CODE === "US") {
        firebaseConfig = {
            apiKey: "AIzaSyCnpXKrhc6hXume3OzqbKVjp3Lmn1lSuMQ",
            authDomain: "wefix-usa.firebaseapp.com",
            databaseURL: "https://wefix-usa.firebaseio.com",
            projectId: "wefix-usa",
            storageBucket: "wefix-usa.appspot.com",
            messagingSenderId: "466820747153",
            appId: "1:466820747153:web:05c0fd86d3e321f2a45430"
        };

    } else {
        firebaseConfig = {
            apiKey: "AIzaSyCZCE6RB-FTx3UwtBhY2WKIzui12SRX-S8",
            authDomain: "wefix-production.firebaseapp.com",
            databaseURL: "https://wefix-production.firebaseio.com",
            projectId: "wefix-production",
            storageBucket: "wefix-production.appspot.com",
            messagingSenderId: "1006006010611",
            appId: "1:1006006010611:web:1200fd3b95c46861ecad86"
        };
    }
} else {
    firebaseConfig = {
        apiKey: "AIzaSyAolh75hlDPUlxZEF7wNVTDD3LKKMPtOsc",
        authDomain: "sinuous-vortex-201512.firebaseapp.com",
        databaseURL: "https://sinuous-vortex-201512.firebaseio.com",
        projectId: "sinuous-vortex-201512",
        storageBucket: "sinuous-vortex-201512.appspot.com",
        messagingSenderId: "1060284197306",
        appId: "1:1060284197306:web:73c775b65b5dfa76c05583"
    };
}

// Get a Firestore instance
export const db = firebase
    .initializeApp(firebaseConfig)
    .firestore();

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore;
export { Timestamp, GeoPoint }