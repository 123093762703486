<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M92 25h10c5.523 0 10 4.477 10 10v83c0 5.523-4.477 10-10 10H28c-5.523 0-10-4.477-10-10V35c0-5.523 4.477-10 10-10h10M65.5 2C72.956 2 79 8.044 79 15.5c0 1.21-.16 2.385-.458 3.501L89 19a3 3 0 013 3v13a3 3 0 01-3 3H41a3 3 0 01-3-3V22a3 3 0 013-3l11.458.001A13.518 13.518 0 0152 15.5C52 8.044 58.044 2 65.5 2zm0 8a5.5 5.5 0 100 11 5.5 5.5 0 000-11z" class="path" stroke="#0C2340"/><path class="highlight" stroke="#00BBDC" d="M47 80l10 10 26-26"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>