<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M64.75 112c6.076 0 11-4.925 11-11s-4.924-11-11-11c-6.075 0-11 4.925-11 11s4.925 11 11 11zM38.888 80.449C46.272 72.816 54.89 69 64.742 69c9.851 0 18.52 3.816 26.005 11.449M22.167 69C35.165 55.014 49.36 48.021 64.75 48.021c15.391 0 29.58 6.993 42.566 20.979M5 53.25c16.866-17.222 36.806-25.833 59.818-25.833s42.952 8.611 59.821 25.833" class="path" stroke="#0C2340"/><path class="highlight" stroke="#00BBDC" d="M21.75 112l93-93"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>