<template>
  <div>

    <loading-state
        fullscreen
        v-if="loading">
    </loading-state>

    <div v-if="!loading" class="container">

      <h2>View SAW Requests</h2>
      <div class="card-table mb-4" v-if="sawList.length > 0">
        <div v-for="saw in sawList" :key="saw.key" class="card-link-container">
          <router-link
              :to="{name: 'sawView', params:{sawId: saw.id}}"
              class="card card-body card-link table-layout">
            <dl class="card-cell">
              <dt>Saw ID</dt>
              <dd>{{ saw.id }}</dd>
            </dl>
            <dl class="card-cell">
              <dt>SO Number</dt>
              <dd>{{ saw.so_number }}</dd>
            </dl>
            <dl class="card-cell">
              <dt>Device</dt>
              <dd>{{ saw.device_name }}</dd>
            </dl>
            <dl class="card-cell">
              <dt>Status</dt>
              <dd>{{ saw.status }}</dd>
            </dl>
            <dl class="card-cell">
              <dt>Created</dt>
              <dd>{{ saw.created_at }}</dd>
            </dl>
            <dl class="card-cell">
              <dt>Last Updated</dt>
              <dd>{{ saw.updated_at }}</dd>
            </dl>
          </router-link>
        </div>
      </div>
      <div class="card mb-4 mt-3" v-if="sawList.length === 0">
        <empty-state
            emptyIcon
            icon="tools"
            inline
            small
            title="No SAW Requests to view">
        </empty-state>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";

export default {


  data: () => ({
    loading: true,
    sawList: [],
    selectedSaw: null,
    buttons: [],
    deviceId: null,
  }),

  created() {
    this.deviceId = this.$route.params.deviceId;
    this.loadMySawList().then(response => {
      this.loading = false;
      response.data.forEach(saw => {
        this.sawList.push(saw);
      })
    }).catch((errors) => {
      this.loading = false;
      this.sawList = [];
      this.displayToast({text: errors.response.data.messages, type: 'error'})
    });
  },

  methods: {
    ...mapActions([
      "loadMySawList",
      "displayToast",
    ]),

  }
}
</script>
