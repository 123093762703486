<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><rect class="path" stroke="#0C2340" x="25" y="2" width="80" height="126" rx="10"/><path d="M47 118.5h36m-33-66c1.133-9 6.23-13.5 15.292-13.5s14.16 3.938 15.293 11.813c1.132 12.374-5.664 18.562-20.39 18.562v13.5m0 7.281h.85" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>