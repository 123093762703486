<template>
    <div v-if="asset">
        <!-- Awaiting update overlay -->
        <empty-state
                v-if="awaitingUpdate"
                statusIcon="loading"
                title="We're waiting for an update on your asset"
                subtitle="Please wait">
        </empty-state>

        <!-- Assets awaiting parts -->
        <empty-state
                v-if="assetsAwaitingParts"
                statusIcon="error"
                :title="`Some assets are still awaiting parts`">
            <slot>
                <router-link :to="{name: 'job', params: {jobId: this.asset.job_id}}" class="btn btn-light">View job</router-link>
            </slot>
        </empty-state>

        <!-- Validating overlay -->
        <empty-state
                v-if="repairKitBeingPicked"
                statusIcon="loading"
                title="Checking available parts"
                subtitle="Please do not begin this repair until a repair kit has been delivered to you">
        </empty-state>

        <!-- Pending in-house-repair -->
        <empty-state
                v-if="repairKitPicked"
                icon="tools"
                :title="`This asset is pending an in-house repair`">
            <slot>
                <router-link :to="{name: 'job', params: {jobId: this.asset.job_id}}" class="btn btn-light">View job</router-link>
                <div class="card card-body-sm pb-0 text-left text-md-center">
                    <div class="row">
                        <dl class="col-6 mb-2">
                            <dt>Repair kit ID</dt>
                            <dd>{{ asset.repair_kit_id }}</dd>
                        </dl>
                        <dl class="col-6 mb-2">
                            <dt>Repair kit day number</dt>
                            <dd>{{ asset.repair_kit_day_number }}</dd>
                        </dl>
                    </div>
                </div>
            </slot>
        </empty-state>

        <!-- Diagnosis completed -->
        <empty-state
                v-if="notPendingInHouseRepair"
                statusIcon="error"
                :title="`<span class='font-weight-light'>The asset with the IMEI</span> ${ imei } <span class='font-weight-light'>has already passed the diagnosis stage. Try searching for another IMEI.</span>`">
            <slot>
                <div class="justify-content-center row">
                    <div class="col-md-5" v-if="asset.job_id">
                        <router-link :to="{name: 'job', params: {jobId: this.asset.job_id}}" class="btn btn-light btn-block">View job</router-link>
                    </div>
                    <div class="col-md-5">
                        <button class="btn btn-light btn-block" @click="printAssetLabel(asset.id)">Reprint asset label</button>
                    </div>
                </div>
            </slot>
        </empty-state>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        props: ['asset', 'imei'],

        computed: {
            awaitingUpdate() {
                return (
                    !this.assetsAwaitingParts &&
                    !this.notPendingInHouseRepair &&
                    !this.repairKitBeingPicked &&
                    !this.repairKitPicked
                );
            },

            assetsAwaitingParts() {
                return (this.asset.status_code === "awaiting-stock");
            },

            notPendingInHouseRepair() {
                return (
                    this.asset.diagnosis_complete &&
                    this.asset.status_code !== "pending-in-house-repair" &&
                    this.asset.status_code !== "received" &&
                    this.asset.status_code !== "awaiting-stock"
                );
            },

            repairKitBeingPicked() {
                return (
                    this.asset.diagnosis_complete &&
                    (this.asset.status_code === "pending-in-house-repair" || this.asset.status_code === "received") &&
                    !this.asset.repair_kit_id
                );
            },

            repairKitPicked() {
                return (
                    this.asset.diagnosis_complete &&
                    this.asset.status_code === "pending-in-house-repair" &&
                    this.asset.repair_kit_id
                );
            },
        },

        methods: {
            ...mapActions([
                "printAssetLabel"
            ]),
        }
    }
</script>

<style scoped>

</style>