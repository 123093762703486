<template>
    <b-modal
            id="complete-shipment-modal"
            no-footer
            title="Mark shipment complete">
        <empty-state
                statusIcon="warning"
                statusIconSize="4x"
                title="Are you sure you want to mark this shipment as complete?"
                subtitle="There are still repair kits missing from this shipment">
        </empty-state>
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="$bvModal.hide('complete-shipment-modal')">Cancel</button>
            <button type="button" class="btn btn-danger" @click="markShipmentComplete()">Mark as complete</button>
        </template>
    </b-modal>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters([
                'shipmentBeingPacked'
            ]),
        },

        methods: {
            ...mapActions([
                'clearShipmentScanned'
            ]),

            markShipmentComplete() {
                this.clearShipmentScanned();
                this.$bvModal.hide('complete-shipment-modal');
            }
        }
    }
</script>

<style scoped>

</style>