<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><rect class="path" stroke="#0C2340" x="7" y="2" width="116" height="126" rx="10"/><path d="M20.6 109h88.8c1.988 0 3.6-1.228 3.6-2.743V15.743c0-1.515-1.612-2.743-3.6-2.743H20.6c-1.988 0-3.6 1.228-3.6 2.743v90.514c0 1.515 1.612 2.743 3.6 2.743zM55 119.5h20" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>