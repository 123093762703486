import {firestoreAction} from "vuexfire";
import {db} from '@/firebase'

const state = {
    confirmedProblems: [],
    problems:[]
};

const getters = {
    confirmedProblems: state => state.confirmedProblems,
    problems: state => state.problems,
};

const actions = {
    /** Load the added confirmed problems */
    loadAddedConfirmedProblems: firestoreAction(({bindFirestoreRef}, {jobId, deviceId}) => {
        return new Promise((resolve, reject) => {
            return bindFirestoreRef(
                'confirmedProblems',
                db.collection(`jobs/${jobId}/devices/${deviceId}/confirmed_problems`)
                    .orderBy('title')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load all problems */
    loadAllProblems: firestoreAction(({bindFirestoreRef}) => {
        return bindFirestoreRef(
            'problems',
            db.collection(`/problems`)
                .orderBy('title')
        )
    }),

    /** Add confirmed problem to device */
    addConfirmedProblem(__, {jobId, deviceId, problemId}) {
        return new Promise((resolve) => {
            this.$axios.post(`/api/v4/orders/devices/${deviceId}/confirmed-problem`, {
                technician_job_id: jobId,
                problem_id: problemId
            }).then(() => {
                resolve();
            }).catch(() => {
                resolve();
            });
        });
    },

    /** Remove confirmed problem from device */
    removeConfirmedProblem(__, {jobId, deviceId, problemId}) {
        return new Promise((resolve) => {
            this.$axios.post(`/api/v4/orders/devices/${deviceId}/confirmed-problem/destroy`, {
                technician_job_id: jobId,
                problem_id: problemId
            }).then(() => {
                resolve();
            }).catch(() => {
                resolve();
            });
        });
    },

    /** Send the alert to Likewize for the confirmed problems */
    sendConfirmedProblems(__, {deviceId, jobId}) {
        return new Promise((resolve) => {
            this.$axios.post(`/api/v4/orders/devices/${deviceId}/confirmed-problem/send`, {
                technician_job_id: jobId
            }).then(() => {
                resolve();
            }).catch(() => {
                resolve();
            });
        });
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}