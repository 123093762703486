import {firestoreAction} from "vuexfire";
import moment from "moment";
import {db} from '@/firebase'
import {firebase} from '@firebase/app';
import '@firebase/storage';

const state = {
    allJobs: [],
    completedJobs: [],
    jobs: [],
    payments: [],
    job: null,
    jobNotes: [],
};

const getters = {
    allJobs: state => state.allJobs,
    completedJobs: state => state.completedJobs,
    jobs: state => state.jobs,
    job: state => state.job,
    payments: state => state.payments,
    paymentForDevice: state => (deviceId) => state.payments.find(payment => payment.device_id === deviceId),
    jobNotes: state => state.jobNotes,

    isRefurbOrMailIn: state => (state.job.job_type === 'refurbishment' || state.job.job_type === 'mail-in' || state.job.job_type === 'customer-mail-in' || state.job.job_type === 're-repair'),
    isShipmentHandlingOrLunchBreak: state => (state.job.job_type === 'shipment_handling' || state.job.job_type === 'lunch_break')
};

const actions = {
    /** Add a signature to the job */
    addSignature(__, {jobId, imageableId, imageableType, imageData, signatureType}) {
        return new Promise(async (resolve, reject) => {

            // let filename = `${imageableId}:${imageableType}.png`;
            let filename = `${imageableId}-${signatureType}.png`;

            const ref = firebase.storage().ref(`/signatures/${jobId}/${filename}`);

            imageData = imageData.replace("data:image/png;base64,", "");

            await ref.putString(imageData, "base64");

            db.collection(`jobs/${jobId}/signatures`)
                .doc(imageableId)
                .set({
                    title:"signatures",
                    filename:filename,
                    imageable_id:imageableId,
                    imageable_type:imageableType,
                    created_at: firebase.firestore.FieldValue.serverTimestamp(),
                    signature_type:signatureType
                }).then(() => {
                resolve();
            })
                .catch(() => {
                    reject();
                });
        });
    },

    /** Load today's jobs */
    loadAllTodaysJobs: firestoreAction(({getters, bindFirestoreRef}) => {
        return new Promise((resolve) => {
            if (getters.user.role.tag !== 'technician') {// TODO: Can this be updated to use the "isTechnician" function?
                return resolve();
            }

            let date = moment().format('YYYY-MM-DD');
            // date = "2020-05-18";

            return bindFirestoreRef(
                'allJobs',
                db.collection('jobs')
                    .where('technician_id', '==', getters.user.technician.id)
                    .where('date', '==', date)
                    .orderBy('start_time', 'asc')
            ).then(() => {
                resolve();
            }).catch(() => {
                resolve();
            })

        });
    }),

    /** Load a job */
    loadJob: firestoreAction(({bindFirestoreRef}, {jobId}) => {
        return new Promise((resolve, reject) => {
            jobId = jobId.toString();

            return bindFirestoreRef(
                'job',
                db.collection('jobs').doc(jobId)
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    loadDoorstepPaymentsForJob: firestoreAction(({bindFirestoreRef}, {jobId}) => {
        return new Promise((resolve, reject) => {
            jobId = jobId.toString();
            return bindFirestoreRef(
              'payments',
              db.collection(`jobs/${jobId}/payments`)
            ).then(() => {
                resolve()
            }).catch((error) => {
                console.log(error);
                reject();
            })
        });
    }),

    /** Load today's jobs */
    loadTodaysJobs: firestoreAction(({getters, bindFirestoreRef}) => {
        return new Promise((resolve) => {
            if (getters.user.role.tag !== 'technician') {// TODO: Can this be updated to use the "isTechnician" function?
                return resolve();
            }

            let date = moment().format('YYYY-MM-DD');
            // date = "2020-05-18";

            return bindFirestoreRef(
                'jobs',
                db.collection('jobs')
                    .where('technician_id', '==', getters.user.technician.id)
                    .where('date', '==', date)
                    .where('complete', '==', false)
                    .orderBy('start_time', 'asc')
                ).then(() => {
                    resolve();
                }).catch(() => {
                    resolve();
                });
        });
    }),

    /** Load today's completed jobs */
    loadTodaysCompletedJobs: firestoreAction(({getters, bindFirestoreRef}) => {
        return new Promise((resolve) => {
            if (getters.user.role.tag !== 'technician') {// TODO: Can this be updated to use the "isTechnician" function?
                return resolve();
            }

            let date = moment().format('YYYY-MM-DD');
            // date = "2020-05-18";

            return bindFirestoreRef(
                'completedJobs',
                db.collection('jobs')
                    .where('technician_id', '==', getters.user.technician.id)
                    .where('date', '==', date)
                    .where('complete', '==', true)
                    .orderBy('start_time', 'asc')
            ).then(() => {
                resolve();
            }).catch(() => {
                resolve();
            });
        });
    }),

    /** Load a job's notes */
    loadJobNotes: firestoreAction(({bindFirestoreRef}, {jobId}) => {
        return new Promise((resolve, reject) => {
            jobId = jobId.toString();

            bindFirestoreRef(
                'jobNotes',
                db.collection(`jobs/${jobId}/notes`)
                    .orderBy('created_at', 'desc')
                ).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    }),

    /** Set job as can't start  */
    setJobCantStart(__, {jobId}) {
        return new Promise((resolve, reject) => {

            db.collection(`jobs`)
                .doc(jobId)
                .update({
                    cant_start: true
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Set job can't start to false  */
    setJobCantStartFalse(__, {jobId}) {
        return new Promise((resolve, reject) => {

            db.collection(`jobs`)
                .doc(jobId)
                .update({
                    cant_start: false
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Set job to completed  */
    setJobComplete(__, {jobId}) {
        return new Promise((resolve, reject) => {

            db.collection(`jobs`)
                .doc(jobId)
                .update({
                    complete: true
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                reject();
            })
        });
    },

    /** Technician has started the journey to the job  */
    startJobJourney(__, {jobId}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs`)
                .doc(jobId)
                .update({
                    started_journey: true
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                reject();
            })
        });
    },

};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}