import {firestoreAction} from "vuexfire";
import {db} from '@/firebase'

const state = {
    signatureImage: null,
    waiverName: null,
    waiverOptions: [],
    waiverSigned: true
};

const getters = {
    signatureImage: state => state.signatureImage,

    waiverName: state => state.waiverName,
    waiverOptions: state => state.waiverOptions,
    waiverSigned: state => state.waiverSigned,
};

const actions = {
    loadSignatureImage: firestoreAction(({bindFirestoreRef}, {jobId, imageableId}) => {
        return new Promise((resolve, reject) => {

            bindFirestoreRef(
                'signatureImage',
                db.collection(`jobs/${jobId}/signatures`).doc(imageableId.toString())
                ).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    }),

    /** Load waiver options */
    loadWaiverOptions: firestoreAction(({bindFirestoreRef}, {jobId, deviceId}) => {
        return new Promise((resolve, reject) => {

            bindFirestoreRef(
                'waiverOptions',
                db.collection(`jobs/${jobId}/devices/${deviceId}/waiver_options`)
                    .orderBy('title')
                ).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    }),

    /** Set the waiver name */
    setWaiverName({getters}, {jobId, deviceId}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices/`).doc(deviceId)
                .update({waiver_name: getters.device.waiver_name})// TODO: Update the waiver name
                .then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Set waiver signed */
    setWaiverSigned(__, {jobId, deviceId}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices/`).doc(deviceId)
                .update({waiver_signed: true})
                .then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Set a waiver option as checked */
    setWaiverOptionChecked({getters}, {jobId, deviceId}) {
        return new Promise((resolve, reject) => {

            getters.waiverOptions.forEach((waiverOption, key) => {
                let waiverOptionsCollection = db.collection(`jobs/${jobId}/devices/${deviceId}/waiver_options`).doc(waiverOption.id);

                if (waiverOption.checked) {
                    waiverOptionsCollection.update({checked: true})
                        .then(() => {
                            if (key === (getters.waiverOptions.length - 1)) {
                                resolve();
                            }
                        }).catch(() => {
                            reject();
                        })
                } else {
                    waiverOptionsCollection.update({checked: false})
                        .then(() => {
                            if (key === (getters.waiverOptions.length - 1)) {
                                resolve();
                            }
                        }).catch(() => {
                            reject();
                        })
                }
            });
        });
    },

};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}