<template>
    <div class="container the-menu" :class="{'menu-open': menuOpen}">
        <b-navbar-nav>
            <div class="align-items-center card flex-row mb-2 p-2" v-if="isOffline">
                <icon-signal-none size="sm" class="mr-2"></icon-signal-none> <span>Offline</span>
            </div>
            <b-nav-item :to="{ name: 'dashboard'}" @click="toggleMenu()">Home</b-nav-item>

            <b-nav-item
                v-for="(nav, idx) in visibleMainNavigation"
                :key="`menu-nav-${idx}`"
                :to="nav.route" @click="toggleMenu()">
              {{ nav.title }}
            </b-nav-item>

            <b-nav-item :to="{ name: 'logout'}" @click="toggleMenu()" class="mt-5"><i class="fa fa-power-off mr-2"></i> Log out</b-nav-item>
            <span class="version-number">Version: {{version}}</span>
        </b-navbar-nav>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    const { version } = require('../../../package.json')

    export default {
        data() {
            return {
                version: version
            }
        },

        computed: {
            ...mapGetters([
                "visibleMainNavigation",
                "menuOpen"
            ]),
        },

        methods: {
            ...mapActions([
                "toggleMenu",
            ]),
        }
    }
</script>

<style scoped>

</style>