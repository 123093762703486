<template>

    <b-modal
            id="change-device-modal"
            ref="changeDeviceModal"
            @hidden="onHidden()"
            size="fs"
            title="Change Device">
        <div class="container">

            <!-- Make -->
            <section>
                <label>Who makes the model?</label>
                <div class="card-grid grid-col-2 grid-col-md-3 grid-col-lg-4">
                    <div class="card-link-container" v-for="(make, index) in deviceMakes" :key="index">
                        <div class="card card-select card-select-sm horizontal justify-content-start" @click="setMake(make)" :class="{'active': selectedMake === make}">
                            <img :src="`https://likewizerepair-uk.s3.eu-west-2.amazonaws.com/makes/${make.code}/${make.code}.svg`" :alt="`${make.title}`" class="img-lg">
                            {{ make.title }}
                        </div>
                    </div>
                </div>
            </section>

            <!-- Type -->
            <section v-if="selectedMake">
                <label>What type of device is it?</label>
                <div class="card-grid grid-col-2 grid-col-md-3 grid-col-lg-4">
                    <div class="card-link-container" v-for="(type, index) in deviceTypes" :key="index">
                        <div class="card card-select card-select-sm horizontal justify-content-start" @click="setType(type)" :class="{'active': selectedType === type}">
                            <icon-mobile v-if="type.code === 'phone'" class="icon-all-white" size="lg"></icon-mobile>
                            <icon-tablet v-if="type.code === 'tablet'" class="icon-all-white" size="lg"></icon-tablet>
                            {{ type.title }}
                        </div>
                    </div>
                </div>
            </section>

            <!-- Model -->
            <section v-if="selectedType">
                <label>Which model is it?</label>
                <div class="card-grid grid-col-2 grid-col-md-3 grid-col-lg-4">
                    <div class="card-link-container" v-for="(model, index) in filteredModelList" :key="index">
                        <div class="card card-select card-select-sm" @click="setModel(model)" :class="{'active': selectedModel === model}">
                            {{ model.title }}
                        </div>
                    </div>
                </div>

                <!-- Load more button -->
                <div class="row" v-if="deviceModels.length > deviceListLimit && deviceListLimit !== null">
                    <div class="col-6 offset-3 col-md-4 offset-md-4">
                        <button class="btn btn-light btn-block mb-4" @click="deviceListLimit = null">Load more</button>
                    </div>
                </div>

            </section>

            <!-- Colour -->
            <section v-if="selectedModel">
                <label>What is the colour?</label>
                <div class="card-grid grid-col-2 grid-col-md-3 grid-col-lg-4">
                    <div class="card-link-container" v-for="(colour, index) in deviceColours" :key="index">
                        <div class="card card-select card-select-sm" @click="setColour(colour)" :class="{'active': selectedColour === colour}">
                            {{ colour.colour_title }}
                        </div>
                    </div>
                </div>
            </section>

        </div>

        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="$bvModal.hide('change-device-modal')">Cancel</button>
            <button type="button" class="btn btn-success" v-if="showDeviceChangeButton">Confirm device change</button>
        </template>

    </b-modal>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'

    export default {
        data() {
            return {
                deviceListLimit: 12,
                showDeviceChangeButton: false
            }
        },

        computed: {
            ...mapGetters([
                'deviceMakes',
                'selectedMake',
                'deviceTypes',
                'selectedType',
                'deviceModels',
                'selectedModel',
                'deviceColours',
                'selectedColour'
            ]),

            filteredModelList() {
                return this.deviceListLimit ? this.deviceModels.slice(0,this.deviceListLimit) : this.deviceModels
            }
        },

        mounted() {
            this.resetChangeDeviceModal();
            this.showDeviceChangeButton = false;
            this.loadDeviceMakes();
        },

        methods: {
            ...mapActions([
                'resetChangeDeviceModal',
                'loadDeviceMakes',
                'setSelectedMake',
                'loadDeviceTypes',
                'setSelectedType',
                'clearSelectedType',
                'loadDeviceModels',
                'setSelectedModel',
                'clearSelectedModel',
                'loadModelColours',
                'setSelectedColour',
                'clearSelectedColour'
            ]),

            onHidden () {
                this.resetChangeDeviceModal();
            },

            /** Set the make and load types */
            setMake(make) {
                this.resetChangeDeviceModal();
                this.showDeviceChangeButton = false;
                this.deviceListLimit = 12;
                this.setSelectedMake(make);
                this.loadDeviceTypes({
                    makeId: this.selectedMake.id
                });
            },

            /** Set the type and load models */
            setType(type) {
                this.setSelectedType(type);
                this.deviceListLimit = 12;
                this.clearSelectedModel();
                this.loadDeviceModels({
                    makeId: this.selectedMake.id,
                    typeId: this.selectedType.id
                });
            },

            /** Set the model and load colours */
            setModel(model) {
                this.setSelectedModel(model);
                this.loadModelColours({
                    makeId: this.selectedMake.id,
                    typeId: this.selectedType.id,
                    modelId: this.selectedModel.id,
                });
            },

            /** Set the colour and load change button */
            setColour(colour) {
                this.setSelectedColour(colour);
                this.showDeviceChangeButton = true;
            }
        }
    }
</script>

<style scoped>

</style>