import {firestoreAction} from "vuexfire";
import {db} from '@/firebase'

const state = {
    deviceParts:[],
    devicePartsFaulty:[],
};

const getters = {
    deviceParts: state => state.deviceParts,
    devicePartsFaulty: state => state.devicePartsFaulty,
};

const actions = {
    addPart(__, {deviceId, jobId, stockSerialNumberId, oldSerialNumber = null, newSerialNumber = null, octaFabQrFrameOldSerialNumber = null}) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/v4/orders/devices/${deviceId}/part`, {
                job_id: jobId,
                stock_serial_number_id: stockSerialNumberId,
                lcd_working: false,
                old_number: oldSerialNumber,
                new_number: newSerialNumber,
                octa_fab_qr_frame_old_number: octaFabQrFrameOldSerialNumber

            }).then(() => {
                resolve();
            }).catch(errors => {
                reject(errors.response.data);
            });
        });
    },

    /** Load faulty parts */
    loadFaultyParts: firestoreAction(({bindFirestoreRef}, {jobId, deviceId}) => {
        return bindFirestoreRef(
            'devicePartsFaulty',
            db.collection(`jobs/${jobId.toString()}/devices/${deviceId.toString()}/parts`)
                .where('faulty', '==', true)
        )
    }),

    /** Load a parts for a device */
    loadParts: firestoreAction(({bindFirestoreRef}, {jobId, deviceId}) => {
        return bindFirestoreRef(
            'deviceParts',
            db.collection(`jobs/${jobId.toString()}/devices/${deviceId.toString()}/parts`)
                .where('faulty', '==', false)
                .orderBy('stock_item_title')
        )
    }),

    /** Mark part as faulty */
    markPartAsFaulty(__, {jobId, deviceId, stockSerialNumberId}) {
        return new Promise((resolve, reject) => {

            let serialNumberReference = db.collection(`jobs/${jobId}/devices/${deviceId}/parts`).doc(stockSerialNumberId.toString());

            serialNumberReference.get()
                .then((snapshot) => {
                    if (!snapshot.exists) {
                        reject("Part not added, Please add this part first then mark as faulty.");
                    }

                    db.collection(`jobs/${jobId}/devices/${deviceId}/parts`).doc(stockSerialNumberId.toString())
                        .update({
                            faulty: true
                        }).then(() => {
                            resolve();
                        }).catch(() => {
                            reject();
                        });
                });
        });
    },

    /** Mark part as not faulty */
    markPartAsNotFaulty(__, {jobId, deviceId, stockSerialNumberId}) {
        return new Promise((resolve, reject) => {

            let serialNumberReference = db.collection(`jobs/${jobId}/devices/${deviceId}/parts`).doc(stockSerialNumberId.toString());

            serialNumberReference.get()
                .then((snapshot) => {

                    if (!snapshot.exists) {
                        reject("Part not added, Please add this part first then mark as faulty.");
                    }

                    db.collection(`jobs/${jobId}/devices/${deviceId}/parts`).doc(stockSerialNumberId.toString())
                        .update({
                            faulty:false
                        }).then(() => {
                            resolve();
                        }).catch(() => {
                            reject();
                        });
                });
        });
    },

    /** Remove a part */
    removePart(__, {jobId, deviceId, stockSerialNumberId}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices/${deviceId}/parts`)
                .doc(stockSerialNumberId.toString())
                .delete()
                .then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
        });
    },

    /** Set can't add serial */
    setNoSerialSupplied(__, {jobId, deviceId}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices`)
                .doc(deviceId)
                .update({no_serial_supplied: true})
                .then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Set no parts required */
    setNoPartsRequired(__, {jobId, deviceId, noPartsUsedReason}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices`)
                .doc(deviceId)
                .update({
                    no_parts_used: true,
                    no_parts_used_reason: noPartsUsedReason
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Move stock item / asset for location integrity */
    relocateStockToLocation(__, {locationId, scanningType, barcodeId, barcodeType, bulkMoveConfirmed}) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/v5/location/${locationId}/stock`, {
                scanning_type: scanningType,
                barcode_id: barcodeId,
                barcode_type: barcodeType,
                bulk_move_confirmed :bulkMoveConfirmed
            }).then(response => {
                resolve(response);
            }).catch(errors => {
                reject(errors.response.data);
            });
        });
    }
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}