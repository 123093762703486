<template>
    <!-- TODO: We will need a way to either dynamically generate an ID based on the image, or come up with a different way to get the modal to show -->
    <b-modal
        body-class="img-preview"
        content-class="no-footer"
        id="image-preview"
        hide-footer
        size="lg"
        title-html="[timestamp] <small>Front Photo</small>">
        <img src="https://images.unsplash.com/photo-1560617544-b4f287789e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80">
    </b-modal>
</template>

<script>
    export default {
        name: "ImagePreviewModal"
    }
</script>

<style scoped>

</style>