<template>
    <div class="container barcode-container">
        <qrcode-stream @decode="handleBarcodeScanned" v-if="displayScanner"></qrcode-stream>
    </div>
</template>

<script>
import {QrcodeStream} from "vue-qrcode-reader";
import {BarcodeScanned} from "@/models/barcodeScanned";

export default {
    components: { QrcodeStream },
    props: {

    },

    data() {
        return {
            displayScanner: true
        }
    },

    beforeDestroy() {
        this.destroyBarcodeScanner();
    },

    methods: {
        handleBarcodeScanned(data) {
            let barcodeScanned = data;
            if (this.isJson(data)) {
                barcodeScanned = new BarcodeScanned(JSON.parse(data));
            }
            this.$emit("barcodeHasBeenScanned", barcodeScanned);
        },

        destroyBarcodeScanner() {
            this.displayScanner = false;
        },

        /** Check if string is valid json */
        isJson(string) {
            try {
                JSON.parse(string);
            } catch (e) {
                console.log(e);
                return false;
            }
            return true;
        }
    }
}
</script>