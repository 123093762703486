<template>
    <b-modal
            id="adding-part-modal"
            hide-footer
            hide-header-close
            no-close-on-backdrop
            no-close-on-esc
            title="Adding Part">

        <!-- Adding -->
        <empty-state
                v-if="adding"
                statusIcon="loading"
                statusIconSize="4x"
                title="Adding part...">
        </empty-state>

        <!-- Success -->
        <empty-state
                v-if="success"
                statusIcon="success"
                statusIconSize="4x"
                title="Part added successfully">
            <slot>
                <button class="btn btn-light" @click="closeModal()">Close</button>
            </slot>
        </empty-state>

        <!-- Error -->
        <empty-state
                v-if="!adding && error"
                statusIcon="error"
                statusIconSize="4x"
                title="There was an issue adding this part"
                :subtitle="error">
            <slot>
                <button class="btn btn-light" v-if="!reScanImei" @click="closeModal()">Close</button>
                <button class="btn btn-light" v-if="reScanImei" @click="goToImeiPage()">Re-scan device <span v-text="deviceType === 'phone' ? 'IMEI' : 'serial number'"></span></button>
            </slot>
        </empty-state>

    </b-modal>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                adding: true,
                code: null,
                error: null,
				success: false
            }
        },

        computed: {
            ...mapGetters([
                'device'
            ]),

            /** Get the device type **/
            deviceType() {
                if (this.device && this.device.type === "tablet") {
                    return "tablet";
                }

                return "phone";
            },

            /** Check if the IMEI/serial number needs to be rescanned **/
            reScanImei() {
                return this.code && (
                    this.code === 'NO_SERVICE_ORDER' ||
                    this.code === 'CLOSED_SERVICE_ORDER' ||
                    this.code === 'CANCELLED_SERVICE_ORDER'
                );
            }
        },

        methods: {
            closeModal() {
                this.$bvModal.hide('adding-part-modal');
                this.adding = true;
                this.code = null;
                this.error = null;
				this.success = false;
            },

            displayError(error) {
                this.adding = false;
                this.code = error.code;
                this.error = error.messages[0];
				this.success = false;
            },

            goToImeiPage() {
                this.closeModal();
                if (this.deviceType === 'phone') {
                    this.$router.push({name: 'repair.addImei'});
                } else {
                    this.$router.push({name: 'repair.addSerialNumber'});
                }
            },

            openModal() {
                this.adding = true;
                this.code = null;
                this.error = null;
				this.success = false;
                this.$bvModal.show('adding-part-modal');
            },

            partAdded() {
                this.adding = false;
                this.code = null;
                this.error = null;
                this.success = true;
                let self = this;
                setTimeout(() => {
                    self.$bvModal.hide('adding-part-modal');
				}, 3000)
            }
        }
    }
</script>

<style scoped>

</style>