const state = {
    sawList: [],
    mySawList: [],
    mySaw: null,
};

const getters = {
    sawList: state => state.sawList,
    mySawList: state => state.mySawList,
    mySaw: state => state.mySaw,
};

const actions = {
    /** Confirm the scanned asset */
    loadSawList(_, deviceId) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/v4/saw/type/active/${deviceId}`).then(response => {
                this.sawList = response.data.data;
                resolve(response.data);
            })
                .catch(errors => {
                    reject(errors.response.data.messages[0]);
                });
        });
    },
    loadMySawList() {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/v4/saw/list?order_by=created_at`).then(response => {
                this.mySawList = response.data.data;
                resolve(response.data.data);
            })
                .catch(errors => {
                    reject(errors.response.data.messages[0]);
                });
        });
    },
    loadSaw(_, sawId) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/v4/saw/${sawId}`).then(response => {
                this.mySaw = response.data.data;
                resolve(response.data);
            })
                .catch(errors => {
                    reject(errors.response.data.messages[0]);
                });
        });
    },
    updateSaw(_, sawId) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/v4/saw/${sawId}/update`, {
                'saw_id': sawId
            }).then((response) => {
                resolve(response.data);
            }).catch((errors) => {
                reject(errors.response.data.messages[0]);
            });
        });
    }
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}