<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-width="4"><path class="highlight" stroke="#00BBDC" transform="rotate(15 45.241 105.966)" d="M59.741 88.966v34l-14.5-8.441-14.5 8.44v-34"/><path class="highlight" stroke="#00BBDC" transform="scale(-1 1) rotate(15 0 -545.437)" d="M100.259 88.966v34l-14.5-8.441-14.5 8.44v-34"/><path class="highlight" stroke="#00BBDC" stroke-linejoin="round" d="M47 51l10 10 26-26"/><path d="M63.211 92.208l-5.474 1.594a6.4 6.4 0 0 1-6.066-1.385l-4.24-3.81a6.4 6.4 0 0 0-3.224-1.553l-5.623-.94a6.4 6.4 0 0 1-4.865-3.879l-2.167-5.273a6.4 6.4 0 0 0-2.23-2.797l-4.66-3.286a6.4 6.4 0 0 1-2.7-5.607l.336-5.69a6.4 6.4 0 0 0-.796-3.489l-2.771-4.982a6.4 6.4 0 0 1 0-6.222l2.771-4.982a6.4 6.4 0 0 0 .796-3.488l-.335-5.691a6.4 6.4 0 0 1 2.7-5.607l4.658-3.286a6.4 6.4 0 0 0 2.23-2.797l2.168-5.273a6.4 6.4 0 0 1 4.865-3.88l5.623-.94a6.4 6.4 0 0 0 3.223-1.551l4.24-3.81a6.4 6.4 0 0 1 6.067-1.386l5.474 1.594a6.4 6.4 0 0 0 3.578 0l5.474-1.594a6.4 6.4 0 0 1 6.066 1.385l4.24 3.81a6.4 6.4 0 0 0 3.224 1.553l5.623.94a6.4 6.4 0 0 1 4.865 3.879l2.167 5.273a6.4 6.4 0 0 0 2.23 2.797l4.66 3.286a6.4 6.4 0 0 1 2.7 5.607l-.336 5.69a6.4 6.4 0 0 0 .796 3.489l2.771 4.982a6.4 6.4 0 0 1 0 6.222l-2.771 4.982a6.4 6.4 0 0 0-.796 3.488l.335 5.691a6.4 6.4 0 0 1-2.7 5.607l-4.658 3.286a6.4 6.4 0 0 0-2.23 2.797l-2.168 5.273a6.4 6.4 0 0 1-4.865 3.88l-5.623.94a6.4 6.4 0 0 0-3.223 1.551l-4.24 3.81a6.4 6.4 0 0 1-6.067 1.386l-5.474-1.594a6.4 6.4 0 0 0-3.578 0z" class="path" stroke="#0C2340" stroke-linejoin="round"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>