<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M118 33c5.523 0 10 4.477 10 10v59c0 5.523-4.477 10-10 10H12c-5.523 0-10-4.477-10-10V43c0-5.523 4.477-10 10-10h6v-3a2 2 0 012-2h8a2 2 0 012 2v3h5l10.01-11.55A10 10 0 0152.565 18h26.868a10 10 0 017.342 3.211l.215.24L97 33h21z" class="path" stroke="#0C2340"/><path d="M65.452 44.726c-14.583 0-26.404 11.822-26.404 26.404 0 14.583 11.821 26.405 26.404 26.405S91.856 85.713 91.856 71.13c-.021-14.573-11.83-26.382-26.404-26.404z" class="highlight" stroke="#00BBDC"/><path d="M65.452 82.482c-6.27 0-11.352-5.082-11.352-11.352 0-6.269 5.083-11.351 11.352-11.351 6.27 0 11.352 5.082 11.352 11.351-.007 6.267-5.086 11.345-11.352 11.352h0z" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>