<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M49.705 58.024L6.612 101.116c-6.15 6.15-6.15 16.122 0 22.271 6.15 6.15 16.122 6.15 22.272 0l36.61-36.609m32.99-14.473a34.88 34.88 0 0019.136-9.808c9.123-9.123 12.23-21.981 9.33-33.649-.552-2.222-3.333-2.975-4.952-1.356l-18.3 18.3-16.704-2.784-2.784-16.704 18.3-18.3c1.63-1.629.844-4.405-1.393-4.96-11.66-2.89-24.499.223-33.611 9.333-9.755 9.755-12.44 23.896-8.38 36.225m-41.375 69.554a5.906 5.906 0 110-11.813 5.906 5.906 0 010 11.813z" class="path" stroke="#0C2340"/><path d="M112.355 125.305l12.968-12.967c3.568-3.593 3.568-9.4 0-12.968L96.507 70.555c-5.684-5.684-14.174-6.792-21.015-3.42L49.26 40.904V25.623L17.76 2 2.012 17.748l23.624 31.497h15.281L67.15 75.476c-3.347 6.841-2.264 15.33 3.42 21.015l28.816 28.814c3.593 3.593 9.4 3.593 12.969 0z" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>