<template>
    <footer class="fixed-footer">
        <div class="container">
<!--            <router-link :to="{ name: 'job'}" class="btn btn-danger d-none d-md-inline-block">Back to job</router-link>-->
            <router-link :to="{ name: prevRoute, params:params}" v-if="prevRoute" class="btn btn-light" @click.native="previousButtonClicked()"><i class="fa fa-arrow-left text-sm"></i> <span class="d-none d-md-inline-block">{{ prevLabel }}</span><span class="d-md-none">Prev</span></router-link>
            <router-link :to="{ name: nextRoute, params:params}" v-if="nextRoute" class="btn btn-success continue-btn icon-right ml-auto" @click.native="nextButtonClicked()">
                <span class="d-none d-md-inline-block" :class="{'d-inline-block': nextLabel === 'Repair Complete'}">{{ nextLabel }}</span>
                <span class="d-md-none" :class="{'d-none': nextLabel === 'Repair Complete'}">Next</span> <i class="fa text-sm" :class="btnIcon"></i>
            </router-link>
        </div>
    </footer>
</template>

<script>

    export default {
        props: {
            additionalParams: {
                type: Object,
                default:null
            },
            btnIcon: {
                type: String,
                default: "fa-arrow-right"
            },
            nextLabel: {
                type: String,
                default: "Next Step"
            },
            nextRoute: {
                type: String,
                default: ""
            },
            prevLabel: {
                type: String,
                default: "Prev Step"
            },
            prevRoute: {
                type: String,
                default: ""
            }
        },

        computed: {
            /** Get the params */
            params() {
                return this.paramsWithAdditional;
            },

            /** Get the params with any additional passed through */
            paramsWithAdditional() {

                if (!this.additionalParams) {
                    return this.$route.params;
                }

                return {...this.$route.params, ...this.additionalParams};
            },
        },

        methods: {
            previousButtonClicked() {
                this.$emit("previousButtonClicked");
            },

            nextButtonClicked() {
                this.$emit("nextButtonClicked");
            }
        }
    }
</script>

<style scoped>

</style>