<template>
    <div class="card card-body" :class="{'card-overlap': markers.length > 0 && isOnline}">
        <div class="row">
            <div class="col-md-12">
                <!-- Status Banner -->
                <div class="alert" :class="{'alert-success': job.complete, 'alert-warning': !job.complete}">
                    <animated-success v-if="job.complete"></animated-success>
                    <icon-tools v-if="!job.complete"></icon-tools>
                    <div class="alert-content">
                        Job Status: <strong v-if="job.complete">Completed</strong><strong v-if="!job.complete">Pending</strong>
                    </div>
                    <button class="btn btn-light btn-sm" v-b-modal.waiver-signature-modal v-if="!job.complete && showJobCompleteButton">Mark complete</button>
                </div>
                <h1>{{ job.start_time }} - {{ job.end_time }}</h1>
            </div>
            <dl class="col-md-12">
                <dt>Repair location</dt>
                <dd><template v-if="job.first_name || job.second_name">{{ job.first_name }} {{ job.second_name }}, </template>{{ job.address_1 }}, <template v-if="job.address_2">{{ job.address_2 }}, </template>{{ job.town }}, <span class="text-uppercase">{{ job.postcode }}</span></dd>
            </dl>
            <div class="col-sm-8 col-lg-9">
                <div class="row">
                    <dl class="col-6">
                        <dt>Order ID</dt>
                        <dd>
                            <template v-if="job.order_id">{{ job.order_id }}</template>
                            <template v-if="!job.order_id">--</template>
                        </dd>
                    </dl>
                    <dl class="col-6">
                        <dt>Job type</dt>
                        <dd class="text-capitalize">
                            <template v-if="job.job_type">{{ job.job_type }}</template>
                            <template v-if="!job.job_type">--</template>
                        </dd>
                    </dl>
                    <template v-if="job.job_type !== 'general'">
                        <dl class="col-sm-6">
                            <dt>Warranty status</dt>
                            <dd v-if="job.in_warranty">In-Warranty</dd>
                            <dd v-if="!job.in_warranty">Out of Warranty</dd>
                        </dl>
                        <dl class="col-sm-6">
                            <dt>Company</dt>
                            <dd>
                                <template v-if="job.company_name">{{ job.company_name }}</template>
                                <template v-if="!job.company_name">--</template>
                            </dd>
                        </dl>
                    </template>
                </div>
            </div>
            <div class="col-sm-4 col-lg-3">
                <a class="btn btn-info btn-block mb-3" v-if="job.order_id" :href="`${weFixUri}/company/orders/${job.order_id}`" target="_blank">View in admin</a>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {

        props:['jobId', 'markers', 'showJobCompleteButton'],

        data() {
            return {
                loading: true,
            };
        },

        computed: {
            ...mapGetters([
                "devices",
                "job",
                "isTechnician",
                "jobNotes",
                "user",
                "weFixUri"
            ]),
        },

        methods: {
            ...mapActions([
                "loadJob"
            ]),

            mapsSelector() {
                if /* if we're on iOS, open in Apple Maps */
                ((navigator.platform.indexOf("iPhone") != -1) ||
                    (navigator.platform.indexOf("iPad") != -1) ||
                    (navigator.platform.indexOf("iPod") != -1))
                    window.open("maps://maps.google.com/maps?daddr=" + this.job.latitude +","+ this.job.longitude +"&amp;ll=");
                else /* else use Google */
                    window.open("https://maps.google.com/maps?daddr=" + this.job.latitude +","+ this.job.longitude +"&amp;ll=");
            }
        }
    }
</script>

<style scoped>

</style>