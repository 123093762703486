<template>
    <div class="container pb-2 pt-1">
        <div class="row" v-if="!selectedWarehouse">
            <div class="col-12 text-center">
                <h4>Select a warehouse to pick from:</h4>
            </div>
            <div class="col-12 mb-1">
                <button class="btn btn-success btn-block d-md-inline-block mb-1 w-full" @click="selectedWarehouse = 3">Crewe AFC2 ({{ getCountForKitWarehouse(3) }})</button>
            </div>
            <div class="col-12 mb-1">
                <button class="btn btn-success btn-block d-md-inline-block mb-1 w-full" @click="selectedWarehouse = 1">{{ mainWarehouseName }} ({{ getCountForKitWarehouse(1) }})</button>
            </div>
        </div>
        <div class="row" v-if="selectedWarehouse && !selectedType">
            <div class="col-12 text-center">
                <h4>Select a repair kit type to pick:</h4>
            </div>
<!--            <div class="col-12 mb-1" v-if="appRegion === 'UK'">-->
<!--                <button class="btn btn-info btn-block d-md-inline-block mb-1 w-full" @click="selectedWarehouse = null">Change Warehouse</button>-->
<!--            </div>-->
            <div class="col-12 mb-1">
                <button class="btn btn-success btn-block d-md-inline-block mb-1 w-full" @click="emitSearchValue(selectedWarehouse, 'all')">All Kit Types ({{ getCountForKitType('all') }})</button>
            </div>
            <div class="col-12 mb-1">
                <button class="btn btn-success btn-block d-md-inline-block mb-1 w-full" @click="emitSearchValue(selectedWarehouse, 'fleet')">Fleet Kits ({{ getCountForKitType('fleet') }})</button>
            </div>
            <div class="col-12 mb-1">
                <button class="btn btn-success btn-block d-md-inline-block mb-1 w-full" @click="emitSearchValue(selectedWarehouse, 'in_house')">In-House Kits ({{ getCountForKitType('in_house') }})</button>
            </div>
            <div class="col-12 mb-1">
                <button class="btn btn-success btn-block d-md-inline-block mb-1 w-full" @click="emitSearchValue(selectedWarehouse, 'fsl')">FSL Kits ({{ getCountForKitType('fsl') }})</button>
            </div>
        </div>
        <div class="row" v-if="selectedWarehouse && selectedType">
            <div class="col-12 text-center">
                <h4>{{ selectedType }} Repair Kits in {{ selectedWarehouseName }}</h4>
            </div>
            <div class="col-12 text-center">
                <button class="btn btn-success btn-block d-md-inline-block mb-0 w-full" @click="resetPickingFilterSelection()">Change Selections</button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        searchValue: {
            default: ""
        },
        repairKitsToBePicked: {
            default: ""
        },
    },

    data() {
        return {
            selectedWarehouse: null,
            selectedType: null,
        }
    },

    mounted() {
        if (this.appRegion === 'UK') {
            this.selectedWarehouse = 3;
            localStorage.setItem('selectedWarehouse', 3);
        } else {
            this.selectedWarehouse = 1;
            localStorage.setItem('selectedWarehouse', 1);
        }

        // Multi-warehouse setup for HIVE. Left commented out for if we use two warehouses again
        // if (this.appRegion !== 'UK') {
        //     this.selectedWarehouse = 1;
        //     localStorage.setItem('selectedWarehouse', 1);
        //
        // } else if (localStorage.getItem('selectedWarehouse') && localStorage.getItem('selectedWarehouse') != 'undefined') {
        //     this.selectedWarehouse = localStorage.getItem('selectedWarehouse');
        // }

        if(localStorage.getItem('selectedKitType') && localStorage.getItem('selectedKitType') != 'undefined') {
            this.selectedType = localStorage.getItem('selectedKitType');
        }

        this.emitSearchValue(this.selectedWarehouse, this.selectedType);
    },

    computed: {
        ...mapGetters(["appRegion"]),

        mainWarehouseName() {
            if (this.appRegion === 'UK') {
                return "Maldon";
            }
            return "Picking Warehouse";
        },

        selectedWarehouseName() {
            if (this.selectedWarehouse == 1) {
                return this.mainWarehouseName;
            }
            return "Crewe AFC2";
        }
    },

    methods: {
        resetPickingFilterSelection() {
            if (this.appRegion === 'UK') {
                this.selectedWarehouse = 3;
            } else {
                this.selectedWarehouse = 1;
            }
            this.selectedType = null;
            this.emitSearchValue(this.selectedWarehouse, null);
        },

        getCountForKitWarehouse(warehouseId) {
            return this.repairKitsToBePicked.filter((item) => item.warehouse_id == warehouseId).length;
        },

        getCountForKitType(kitType) {
            if (!this.selectedWarehouse) {
                return 0;
            }
            if (kitType === 'all') {
                return this.repairKitsToBePicked.filter((item) => item.warehouse_id == this.selectedWarehouse).length;
            }
            var repairKitType = this.repairKitsToBePicked.filter((item) => item.warehouse_id == this.selectedWarehouse && item.kit_type == kitType);
            return repairKitType.length;
        },

        /** Pass searched value to parent */
        emitSearchValue(warehouse, type) {
            this.selectedWarehouse = warehouse;
            this.selectedType = type;
            this.$emit("update:searchValue", {warehouse: warehouse, type: type});
            localStorage.setItem('selectedWarehouse', warehouse);
            localStorage.setItem('selectedKitType', type);
        }
    }
}
</script>

<style scoped>
.w-full {
    width: 100% !important;
}
</style>