<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><rect class="path" stroke="#0C2340" stroke-width="4" x="2" y="17" width="126" height="94" rx="5"/><path d="M14.654 28.68v70.64m9.154-70.64v70.64m18.307-70.64v70.64m9.154-70.64v70.64m9.154-70.64v70.64m9.154-70.64v70.64m18.308-70.64v70.64m18.307-70.64v70.64m9.154-70.64v70.64" class="highlight" stroke="#00BBDC" stroke-width="4.34"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>