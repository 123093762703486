import {firestoreAction} from "vuexfire";
import {db} from '@/firebase'
// import job from "./job";

const state = {
    device:null,
    devices:{},

    deviceAssets:{},

    deviceRepairs:{},
    deviceModelStockItemTypesRequiredSerials:{},

    deviceMakes: {},
    selectedMake: null,

    deviceTypes: {},
    selectedType: null,

    deviceModels: {},
    selectedModel: null,

    deviceColours: {},
    selectedColour: null,

    deviceStatus: null,
    deviceStatuses:[],
    deviceSyncStatus: null,
    deviceSyncErrors: null,
    deviceNotes:[],

    deviceImei: null,
    deviceSerial: null,

    soWarrantyErrors: {},

    selectedDeviceStatusSubStatuses: []
};

const getters = {
    device: state => state.device,

    devices: state => state.devices,
    deviceAssets: state => state.deviceAssets,
    deviceRepairs: state => state.deviceRepairs,
    deviceModelStockItemTypesRequiredSerials: state => state.deviceModelStockItemTypesRequiredSerials,

    deviceMakes: state => state.deviceMakes,
    selectedMake: state => state.selectedMake,

    deviceTypes: state => state.deviceTypes,
    selectedType: state => state.selectedType,

    deviceModels: state => state.deviceModels,
    selectedModel: state => state.selectedModel,

    deviceColours: state => state.deviceColours,
    selectedColour: state => state.selectedColour,

    deviceStatuses: state => state.deviceStatuses,
    deviceStatus: state => state.deviceStatus,
    deviceSyncStatus: state => state.deviceSyncStatus,

    deviceNotes: state => state.deviceNotes,

    deviceImei: state => state.deviceImei,
    deviceSerial: state => state.deviceSerial,

    soWarrantyErrors: state => state.soWarrantyErrors,
    selectedDeviceStatusSubStatuses: state => state.selectedDeviceStatusSubStatuses
};

const actions = {
    /** Confirm the scanned asset */
    checkAsset(__, {jobId, assetId, technicianId}) {
        return new Promise((resolve, reject) => {
            this.$axios.put(`/api/v4/assets/${assetId}/check`, {
                job_id: jobId,
                technician_id: technicianId
            }).then(response => {
                resolve(response.data);
            })
                .catch(errors => {
                    reject(errors.response.data.messages[0]);
                });
        });
    },

    /** Set imei_scanned to false */
    clearImeiScanned(__, {jobId, deviceId}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices`)
                .doc(deviceId)
                .update({
                    imei_scanned: false
                }).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
        });
    },

    /** Set parts_confirmed to false */
    clearPartsUsedConfirmed(__, {jobId, deviceId}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices`)
                .doc(deviceId)
                .update({
                    parts_confirmed: false
                }).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
        });
    },

    /** Set serial_number_entered to false */
    clearSerialNumberEntered(__, {jobId, deviceId}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices`)
                .doc(deviceId)
                .update({
                    serial_number_entered: false
                }).then(() => {
                resolve();
            }).catch(() => {
                reject();
            });
        });
    },

    /** Set the selected color */
    clearSelectedColour({commit}) {
        commit('CLEAR_SELECTED_COLOUR');
    },

    /** Set the selected model */
    clearSelectedModel({commit}) {
        commit('CLEAR_SELECTED_MODEL');
    },

    /** Set the selected type */
    clearSelectedType({commit}) {
        commit('CLEAR_SELECTED_TYPE');
    },

    /** Load the device's assets */
    loadDeviceAssets: firestoreAction(({bindFirestoreRef}, {jobId, deviceId}) => {
        return new Promise((resolve, reject) => {
            return bindFirestoreRef(
                'deviceAssets',
                db.collection(`jobs/${jobId}/devices/${deviceId}/assets`)
            ).then(() => {
                resolve();
            }).catch((error) => {
                console.log(error);
                reject();
            })
        });
    }),

    /** Load a device */
    loadDeviceForJob: firestoreAction(({bindFirestoreRef}, {jobId, deviceId}) => {
        return new Promise((resolve, reject) => {
            jobId = jobId.toString();
            deviceId = deviceId.toString();

            return bindFirestoreRef(
                'device',
                db.collection(`jobs/${jobId}/devices`).doc(deviceId)
            ).then(() => {
                resolve();
            }).catch((error) => {
                console.log(error);
                reject();
            })
        });
    }),

    /** Load makes */
    loadDeviceMakes: firestoreAction(({bindFirestoreRef}) => {
        return new Promise((resolve, reject) => {

            return bindFirestoreRef(
                'deviceMakes',
                db.collection('makes')
                    .orderBy('code')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load models */
    loadDeviceModels: firestoreAction(({bindFirestoreRef}, {makeId, typeId}) => {
        return new Promise((resolve, reject) => {
            bindFirestoreRef(
                'deviceModels',
                db.collection(`makes/${makeId}/types/${typeId}/models`)
                    .where('active', '==', 1)
                    .orderBy('priority')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load a device's notes */
    loadDeviceNotes: firestoreAction(({bindFirestoreRef}, {jobId, deviceId}) => {
        return new Promise((resolve, reject) => {
            // jobId = jobId.toString();
            // deviceId = deviceId.toString();

            bindFirestoreRef(
                'deviceNotes',
                db.collection(`jobs/${jobId}/devices/${deviceId}/notes`)
                    .orderBy('created_at', 'desc')
            ).then(() => {
                resolve();
            }).catch((error) => {
                console.log(error);
                reject();
            })
        });
    }),

    /** Load device repairs */
    loadDeviceRepairs: firestoreAction(({bindFirestoreRef}, {jobId, deviceId}) => {
        return new Promise((resolve, reject) => {

            // deviceId = deviceId.toString();
            // jobId = jobId.toString();

            bindFirestoreRef(
                'deviceRepairs',
                db.collection(`jobs/${jobId}/devices/${deviceId}/repairs`)
                    .orderBy('title')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load device repairs */
    loadDeviceModelStockItemTypesRequiredSerials: firestoreAction(({bindFirestoreRef}, {jobId, deviceId}) => {
        return new Promise((resolve, reject) => {
            bindFirestoreRef(
                'deviceModelStockItemTypesRequiredSerials',
                db.collection(`jobs/${jobId.toString()}/devices/${deviceId.toString()}/stock_item_types_required_serials`).orderBy('title')
            ).then(() => {
                resolve();
            }).catch((error) => {
                reject(error);
            })
        });
    }),

    /** Load job devices */
    loadDevicesForJob: firestoreAction(({bindFirestoreRef}, {jobId}) => {
        return new Promise((resolve, reject) => {

            return bindFirestoreRef(
                'devices',
                db.collection(`jobs/${jobId}/devices`)
                ).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    }),

    /** Load the status of a device */
    loadDeviceStatus: firestoreAction(({bindFirestoreRef}, {statusId}) => {
        return new Promise((resolve, reject) => {

            if (!statusId) {
                return resolve();
            }

            bindFirestoreRef(
                'deviceStatus',
                db.collection(`device_statuses`)
                    .doc(statusId.toString())
                ).then(() => {
                    resolve();
                }).catch((error) => {
                    console.log(error);
                    reject();
                })
        });
    }),

    /** Load device statuses */
    loadDeviceStatuses: firestoreAction(({bindFirestoreRef}) => {
        return new Promise((resolve, reject) => {
            bindFirestoreRef(
                'deviceStatuses',
                db.collection(`device_statuses`)
                    .orderBy('title')
            ).then(() => {
                resolve();
            }).catch((error) => {
                console.log(error);
                reject();
            })
        });
    }),

    /** Load device sub statuses */
    loadDeviceSubStatuses: firestoreAction(({bindFirestoreRef}, {statusId}) => {
        return new Promise((resolve, reject) => {
            bindFirestoreRef(
                'selectedDeviceStatusSubStatuses',
                db.collection(`device_statuses/${statusId}/sub_statuses`)
                    .orderBy('title')
            ).then(() => {
                resolve();
            }).catch((error) => {
                console.log(error);
                reject();
            })
        });
    }),

    /** Load device types */
    loadDeviceTypes: firestoreAction(({bindFirestoreRef}, {makeId}) => {
        return new Promise((resolve, reject) => {

            bindFirestoreRef(
                'deviceTypes',
                db.collection(`makes/${makeId}/types`)
                    .orderBy('title')
                ).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    }),

    /** Load models */
    loadModelColours: firestoreAction(({bindFirestoreRef}, {makeId, typeId, modelId}) => {
        return new Promise((resolve, reject) => {
            bindFirestoreRef(
                'deviceColours',
                db.collection(`makes/${makeId}/types/${typeId}/models/${modelId}/colours`)
                    .orderBy('colour_title')
                ).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    }),

    /** Load a device's notes */
    loadSoWarrantyErrors: firestoreAction(({bindFirestoreRef}, {jobId, deviceId}) => {
        return new Promise((resolve, reject) => {

            bindFirestoreRef(
                'soWarrantyErrors',
                db.collection(`jobs/${jobId}/devices/${deviceId}/so_warranty_errors`)
            ).then(() => {
                resolve();
            }).catch((error) => {
                console.log(error);
                reject();
            })
        });
    }),

    /** Load a device's notes */
    loadDeviceSyncStatus: firestoreAction(({bindFirestoreRef}, {jobId, deviceId}) => {
        return new Promise((resolve, reject) => {
            // jobId = jobId.toString();
            // deviceId = deviceId.toString();
            bindFirestoreRef(
                'deviceSyncStatus',
                db.collection(`jobs/${jobId}/devices/${deviceId}/key_value_data`).doc('part_sync_status')
            ).then(() => {
                console.log(`jobs/${jobId}/devices/${deviceId}/key_value_data/part_sync_status`);
                resolve();
            }).catch((error) => {
                console.log(error);
                reject();
            })
        });
    }),

    /** Load the repairs for all devices */
    loadRepairsForDevice(__, {jobId, deviceId}) {
        return db.collection(`jobs/${jobId}/devices/${deviceId}/repairs`)
            .get()
            .then(snapshot => snapshot.docs.map(doc => doc.data()))
    },

    /** Reset the selected details in the change device modal */
    resetChangeDeviceModal({commit}) {
        return new Promise((resolve) => {
            commit('CLEAR_SELECTED_MAKE');
            commit('CLEAR_SELECTED_TYPE');
            commit('CLEAR_SELECTED_MODEL');
            commit('CLEAR_SELECTED_COLOUR');
            resolve();
        });
    },

    /** Save the IMEI */
    saveImei(__, {jobId, deviceId, imei, technicianId, logImeiScan}) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/v4/orders/devices/${deviceId}/imei`, {
                imei: imei,
                technician_id: technicianId,
                log_imei_scan: logImeiScan
            }).then(response => {
                db.collection(`jobs/${jobId}/devices`)
                    .doc(deviceId)
                    .update({
                        imei_scanned: true
                    }).then(() => {
                        return resolve();
                    }).catch(() => {
                        return reject();
                    });

                return resolve(response.data);
            })
            .catch(errors => {
                if (errors.response.data.code === 'NON_EU_DEVICE' || errors.response.data.code === 'IW_DEVICE_OOW') {
                    db.collection(`jobs/${jobId}/devices`)
                        .doc(deviceId)
                        .update({
                            imei_scanned: true
                        });
                }

                reject(errors.response.data);
            });
        });
    },

    /** Save the serial number */
    saveSerialNumber(__, {jobId, deviceId, serialNumber}) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/v4/orders/devices/${deviceId}/serial-number`, {
                serial_number: serialNumber
            }).then(response => {
                db.collection(`jobs/${jobId}/devices`)
                    .doc(deviceId)
                    .update({
                        serial_number_entered: true
                    }).then(() => {
                    return resolve();
                }).catch(() => {
                    return reject();
                });
                return resolve(response.data);
            })
                .catch(error => {
                    return reject(error.response);
                });
        });
    },

    /** Set the device */
    setDevice({commit}, device) {
        commit('SET_DEVICE', device);
    },

    /** Set the current step of the repair process  */
    setCurrentStep(__, {jobId, deviceId, currentStep}) {
        return new Promise((resolve, reject) => {
            if (!currentStep.includes("repair")) {
                return resolve();
            }
            db.collection(`jobs/${jobId}/devices`)
                .doc(deviceId)
                .update({
                    current_step: currentStep
                }).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    },

    /** Set device reviewed to completed  */
    setDeviceReviewComplete(__, {jobId, deviceId}) {
        return new Promise((resolve, reject) => {

            db.collection(`jobs/${jobId}/devices`)
                .doc(deviceId)
                .update({
                    review_complete: true
                }).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Set device to completed  */
    setDeviceComplete(__, {jobId, deviceId}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices`)
                .doc(deviceId)
                .update({
                    complete: true,
                    status_id: 1
                }).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Set device to completed  */
    setDeviceRepairedPendingQC(__, {jobId, deviceId}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices`)
                .doc(deviceId)
                .update({
                    complete: true,
                    status_id: 37
                }).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    },

    /** Set device to not completed  */
    setDeviceNotComplete(__, {jobId, deviceId}) {
        return new Promise((resolve, reject) => {

            db.collection(`jobs/${jobId}/devices`)
                .doc(deviceId)
                .update({
                    complete: false
                }).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Change device status */
    setDeviceStatus(__, {jobId, deviceId, status, subStatusId, completeDescription = ''}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices`)
                .doc(deviceId)
                .update({
                    status_id: status,
                    sub_status_id: subStatusId,
                    complete_description: completeDescription
                }).then(() => {
                resolve();
            }).catch((errors) => {
                reject(errors);
            })
        });
    },

    /** Change device status */
    setDeviceSubStatus(__, {jobId, deviceId, status}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices`)
                .doc(deviceId)
                .update({
                    sub_status_id: status
                }).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    },

    setDefectDescriptions(__, {jobId, deviceId, description, frequency}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices`)
                .doc(deviceId)
                .update({
                    customer_fault_description: description,
                    fault_occurrence_frequency: frequency
                }).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    },

    storeCompleteDescription(__, {deviceId, completeDescription}) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/v4/orders/devices/${deviceId}/complete-description`, {
                complete_description: completeDescription
            }).then(() => {
                return resolve();
            }).catch(error => {
                return reject(error.response);
            });
        });
    },

    /** Confirm parts used  */
    setPartsUsedConfirmed(__, {jobId, deviceId}) {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices`)
                .doc(deviceId)
                .update({
                    parts_confirmed: true
                }).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Set the selected color */
    setSelectedColour({commit}, colourId) {
        commit('SET_SELECTED_COLOUR', colourId);
    },

    /** Set the selected make */
    setSelectedMake({commit}, makeId) {
        commit('SET_SELECTED_MAKE', makeId);
    },

    /** Set the selected model */
    setSelectedModel({commit}, modelId) {
        commit('SET_SELECTED_MODEL', modelId);
    },

    /** Set the selected type */
    setSelectedType({commit}, typeId) {
        commit('SET_SELECTED_TYPE', typeId);
    },

    /** Upload warranty validation image */
    uploadWarrantyValidationImage(__, {deviceId, formData}) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/v4/orders/devices/${deviceId}/validation-images`, formData).then(() => {
                resolve();
            }).catch(() => {
                reject();
            });
        });
    },

    /** Upload warranty validation image */
    syncServiceOrderWithGspn(__, {deviceId}) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/v4/app/service-order/sync/${deviceId}`).then(() => {
                resolve();
            }).catch(() => {
                reject();
            });
        });
    },

    /** Upload warranty validation image */
    bypassGspnSyncErrors(__, {deviceId}) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/v4/app/service-order/bypass/${deviceId}`).then(() => {
                resolve();
            }).catch(() => {
                reject();
            });
        });
    },

    fetchAvailableParts(__, {deviceId}) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/v4/orders/devices/${deviceId}/parts-for-device`)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    reject(response);
                });
        });
    },

    requestAdditionalParts(__, {deviceId, requestedPartIds}) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/v4/orders/devices/${deviceId}/request-additional-parts`, {
                stock_item_ids: requestedPartIds
            }).then(response => {
                resolve(response);

            }).catch(errors => {
                reject(errors);
            });
        });
    }
};

const mutations = {
    CLEAR_SELECTED_COLOUR(state) {
        state.selectedColour = null;
    },

    CLEAR_SELECTED_MAKE(state) {
        state.selectedMake = null;
    },

    CLEAR_SELECTED_MODEL(state) {
        state.selectedModel = null;
    },

    CLEAR_SELECTED_TYPE(state) {
        state.selectedType = null;
    },

    SET_DEVICE(state, device) {
        state.device = device;
    },

    SET_SELECTED_COLOUR(state, colourId) {
        state.selectedColour = colourId;
    },

    SET_SELECTED_MAKE(state, makeId) {
        state.selectedMake = makeId;
    },

    SET_SELECTED_MODEL(state, modelId) {
        state.selectedModel = modelId;
    },

    SET_SELECTED_TYPE(state, typeId) {
        state.selectedType = typeId;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}