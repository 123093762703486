<template>
    <article class="card card-link">
        <router-link :to="{ name: `${routerLinkName}`, params:{deviceId: deviceId}}" class="card-body">
            <div class="alert alert-info alert-sm" v-if="device.repeat_repair_order">
                <animated-info></animated-info>
                <div class="alert-content">This device is a repeat repair.</div>
            </div>
            <div class="alert alert-danger alert-sm" v-if="assetsAwaitingParts">
                <animated-error></animated-error>
                <div class="alert-content">Some assets are awaiting parts</div>
            </div>
            <div class="media">
                <div class="media-left">
                    <img v-if="device.version_image" :src="device.version_image" class="img-2x">
                    <template v-if="!device.version_image">
                        <icon-mobile v-if="device.type === 'phone'" size="2x" class="icon-all-muted"></icon-mobile>
                        <icon-tablet v-if="device.type === 'tablet'" size="2x" class="icon-all-muted"></icon-tablet>
                    </template>
                </div>
                <div class="media-body">
                    <h5 class="mb-2">{{ device.title_with_colour }}</h5>
                    <div class="row">
                        <dl class="col-md-6 mb-3">
                            <dt class="mb-0">IMEI</dt>
                            <dd>
                                <template v-if="device.imei">{{ device.imei }}</template>
                                <template v-if="!device.imei">Unavailable</template>
                            </dd>
                        </dl>
                        <dl class="col-sm-6 mb-3">
                            <dt class="mb-0">Board</dt>
                            <dd>
                                <template v-if="device.board">{{ device.board }}</template>
                                <template v-if="!device.board">--</template>
                            </dd>
                        </dl>
                        <dl class="col-sm-6 mb-3" v-if="device.so_number">
                            <dt class="mb-0">SO Number</dt>
                            <dd>
                                {{ device.so_number }}
                            </dd>
                        </dl>
                        <dl class="col-sm-6 mb-3" v-if="device.customer_reference">
                            <dt class="mb-0">Customer Ref</dt>
                            <dd>
                                {{ device.customer_reference }}
                            </dd>
                        </dl>
                    </div>
                    <div class="row">
                        <dl class="col-sm-12 mb-3">
                            <dt class="mb-0">Repairs</dt>
                            <dd v-for="repair in repairList" :key="repair.id">
                                {{ repair.title }}
                            </dd>
                        </dl>
                    </div>
                </div>
                <div class="media-right align-self-center first-xs first-sm">
                    <div class="badge" v-if="device.status_title" :class="{'badge-success': device.complete, 'badge-warning': !device.complete}">
                        {{ device.status_title }}
                    </div>
                </div>
            </div>
        </router-link>
        <a href="#" class="card-footer text-link" @click="addNote()">
            <i class="fa fa-plus mr-2"></i> Add Note
        </a>

    </article>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'

    export default {

        props:['device', 'job', 'assetsAwaitingParts'],

        data() {
            return {
                repairList: []
            }
        },

        mounted() {
            let that = this;

            this.loadDeviceStatus({
                statusId: this.device.status_id
            });

            this.loadRepairsForDevice({jobId: this.job.id, deviceId: this.device.id}).then(function(data) {
                that.repairList = data;
            });

        },

        computed: {
            ...mapGetters([
                "additionalData",
                "deviceStatus"
            ]),

            deviceId() {
                return this.device.id;
            },

            routerLinkName() {
                if (this.device.current_step && this.device.current_step !== 'job') {
                    return this.device.current_step
                }

                return 'repair'
            }
        },

        methods: {
            ...mapActions([
                "setDevice",
                "loadRepairsForDevice",
                "loadDeviceStatus",
                "setNoteableId",
                "setNoteableType",
                "setAdditionalData"
            ]),

            /** Open the note modal */
            addNote() {
                this.$emit("openNoteModal");
            },
        }
    }
</script>