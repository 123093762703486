<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd"><path d="M128 105.192v8.599H92v-8.6h36zm0-18.899v2.3H92v-2.3h36zm0-12.598v2.3H92v-2.3h36zm0-25.198v8.599H92v-8.599h36zm0-18.898v8.599H92v-8.6h36zM128 17v2.3H92V17h36z" class="highlight" stroke="#00BBDC"/><path d="M35.367 103.192h10.04c3.485 0 6.3-2.815 6.3-6.3V74.903" class="highlight" stroke="#00BBDC" stroke-linecap="round" stroke-linejoin="round"/><path d="M30.556 111.066l26.36-45.67h17.539a3.159 3.159 0 003.15-3.15V30.749a3.159 3.159 0 00-3.15-3.15H20.91c-10.433 0-18.898 8.465-18.898 18.898 0 10.315 8.287 18.682 18.563 18.859L3.272 95.317c-2.619 4.528-1.063 10.296 3.464 12.914l10.906 6.3c4.528 2.618 10.296 1.063 12.914-3.465zm26.36-45.67h17.539a3.159 3.159 0 003.15-3.15V30.749a3.159 3.159 0 00-3.15-3.15H20.91c-10.433 0-18.898 8.465-18.898 18.898 0 10.315 8.287 18.682 18.563 18.859l36.34.04z" class="path" stroke="#0C2340" stroke-linecap="round" stroke-linejoin="round"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>