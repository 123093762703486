<template>
    <div class="card-link-container">
        <div class="card card-body picking-item">
            <div class="header">
                <a href="#">Kit: 12345</a>
                <h4>5 <small>items</small></h4>
            </div>
            <div class="footer">
                <div>
                    Day: 1
                </div>
                <div>
                    <i class="fal fa-tools"></i> 30/01/2020
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardListItem"
    }
</script>

<style scoped>

</style>