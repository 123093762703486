<template>
    <article class="card card-link">
        <a @click="selectDevice" class="card-body">
            <div class="media">
                <div class="media-left">
                    <img v-if="device.version_image" :src="device.version_image" class="img-2x">
                    <template v-if="!device.version_image">
                        <icon-mobile v-if="device.type === 'phone'" size="2x" class="icon-all-muted"></icon-mobile>
                        <icon-tablet v-if="device.type === 'tablet'" size="2x" class="icon-all-muted"></icon-tablet>
                    </template>
                </div>
                <div class="media-body">
                    <h5 class="mb-2">{{ device.title_with_colour }}</h5>
                    <p class="mb-0 text-muted text-sm">IMEI: <template v-if="device.imei">{{ device.imei }}</template><template v-if="!device.imei">Unavailable</template></p>
                    <p class="mb-0 mt-1 text-muted text-sm" v-if="device.board">Board: {{ device.board }}</p>
                    <p class="mb-0 mt-1 text-muted text-sm" v-if="device.so_number">SO: {{ device.so_number }}</p>
                </div>
            </div>
        </a>

    </article>
</template>

<script>
    export default {

        props:['device'],

        methods: {
            selectDevice() {
                this.$emit('deviceSelected');
            }
        }
    }
</script>
