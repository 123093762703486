<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round"><path d="M26.956 42.69l1.684-18.156C29.078 19.821 33.266 16 37.995 16h82.198c4.728 0 8.207 3.821 7.77 8.534l-7.603 81.932c-.438 4.713-4.626 8.534-9.355 8.534H28.807c-4.728 0-8.207-3.821-7.77-8.534l2.22-23.919" class="path" stroke="#0C2340" stroke-linejoin="round"/><path d="M95 16l-2.66 29-15.255-9.969L60 45l2.66-29H95zM2.5 50.5h44m-44 22h44m-37-11h44" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>