<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M48.939 41.53v26.531h21.428M102 65c0-27.614-22.386-50-50-50S2 37.386 2 65s22.386 50 50 50c8.577 0 16.649-2.16 23.703-5.964" class="path" stroke="#0C2340"/><path d="M99.5 122c15.74 0 28.5-12.76 28.5-28.5S115.24 65 99.5 65 71 77.76 71 93.5 83.76 122 99.5 122zm.5-43v30m15-15H85" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>