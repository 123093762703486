<template>
    <b-modal
            id="overtime-delete-modal"
            no-footer
            title="Cancel overtime request">
        <empty-state
                statusIcon="warning"
                statusIconSize="4x"
                :title="`Are you sure you want to cancel this overtime request?`"
                subtitle="If any jobs have already been booked for this time, the request will not be cancelled">
        </empty-state>
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="$bvModal.hide('overtime-delete-modal')">Cancel</button>
            <button v-if="!cancelling" type="button" class="btn btn-danger" @click="confirmDeleteOvertime()">Cancel overtime</button>
            <button v-if="cancelling" type="button" class="btn btn-danger" disabled><animated-loading class="mr-2" size="sm"></animated-loading> Cancelling</button>
        </template>
    </b-modal>
</template>

<script>
    import {mapActions} from "vuex"

    export default {
        props: ["technicianId", "overtimeId"],

        data() {
            return {
                cancelling: false
            }
        },

        methods: {
            ...mapActions([
                "deleteOvertime",
                "displayToast"
            ]),

            confirmDeleteOvertime() {
                this.cancelling = true;
                this.deleteOvertime({
                    technicianId: this.technicianId,
                    overtimeId: this.overtimeId
                }).then(() => {
                    this.cancelling = false;
                    this.$bvModal.hide('overtime-delete-modal');
                    this.displayToast({text: 'Overtime request cancelled', type: 'success'});
                    location.reload();
                }).catch((errorMessages) => {
                    this.cancelling = false;
                    this.$bvModal.hide('overtime-delete-modal');
                    this.displayToast({text: errorMessages[0], type: 'error'});
                });
            }
        }
    }
</script>

<style scoped>

</style>