<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M118 33c5.523 0 10 4.477 10 10v59c0 5.523-4.477 10-10 10H12c-5.523 0-10-4.477-10-10V43c0-5.523 4.477-10 10-10h6v-3a2 2 0 012-2h8a2 2 0 012 2v3h5l10.01-11.55A10 10 0 0152.565 18h26.868a10 10 0 017.342 3.211l.215.24L97 33h21z" class="path" stroke="#0C2340"/><path d="M88.671 59.582C84.306 51.057 75.445 45.22 65.22 45.205c-13.697 0-24.952 10.464-26.221 23.84M71.455 58.94l17.736 2.812L92 44M41.329 82.418c4.365 8.525 13.226 14.361 23.452 14.377 13.697 0 24.952-10.464 26.221-23.84M58.545 83.06l-17.736-2.812L38 98" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>