import firebase from 'firebase/app'
import 'firebase/firestore'
import {db} from '@/firebase'

const state = {
    noteableId:null,
    noteableType:null,

    additionalData: {}
};

const getters = {
    noteableId: state => state.noteableId,
    noteableType: state => state.noteableType,

    additionalData: state => state.additionalData,
};

const actions = {
    /** Set the noteable type */
    setNoteableId({commit}, noteableId) {
        commit('SET_NOTEABLE_ID', noteableId)
    },

    /** Set the noteable type */
    setNoteableType({commit}, noteableType) {
        commit('SET_NOTEABLE_TYPE', noteableType)
    },

    /** Set the noteable type */
    setAdditionalData({commit}, additionalData) {
        commit('SET_ADDITIONAL_DATA', additionalData)
    },

    /** Add note to device */
    addDeviceNote: (__, {jobId, deviceId, newNoteText, user}) => {
        return new Promise((resolve, reject) => {

            let newDeviceNoteCollection = db.collection(`jobs/${jobId}/devices/${deviceId}/notes`);
            let newDeviceNoteRef = newDeviceNoteCollection.doc();
            let newDeviceNoteId = newDeviceNoteRef.id;

            newDeviceNoteCollection.add({
                added_by: user.name,
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
                note_id: newDeviceNoteId,
                text: newNoteText
            }).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    },

    /** Add note to job */
    addJobNote(__, {jobId, newNoteText, user}) {
        return new Promise((resolve, reject) => {

            let newNoteCollection = db.collection(`jobs/${jobId}/notes`);
            let newNoteRef = newNoteCollection.doc();
            let newNoteId = newNoteRef.id;

            newNoteCollection.add({
                added_by: user.name,
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
                note_id: newNoteId,
                text: newNoteText
            }).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }
};

const mutations = {
    SET_NOTEABLE_ID(state, noteableId) {
        state.noteableId = noteableId;
    },

    SET_NOTEABLE_TYPE(state, noteableType) {
        state.noteableType = noteableType;
    },

    SET_ADDITIONAL_DATA(state, additionalData) {
        state.additionalData = additionalData;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}