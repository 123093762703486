<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round"><path d="M5 47l35 32m85-32L90 79M45 47l9 30m31-30l-9 30M90 80.5v45a2.5 2.5 0 01-2.5 2.5h-45a2.5 2.5 0 01-2.5-2.5v-45a2.5 2.5 0 012.5-2.5h45a2.5 2.5 0 012.5 2.5z" class="path" stroke="#0C2340" stroke-linejoin="round"/><path class="highlight" stroke="#00BBDC" d="M72 78v12l-7-4.125L58 90V78z"/><path d="M65 2c29.62 0 54.328 19.325 60 45h-.202c-1.386-4.24-9.722-7.5-19.798-7.5-10.076 0-18.412 3.26-19.797 7.5h-.405c-1.386-4.24-9.722-7.5-19.798-7.5-10.076 0-18.412 3.26-19.798 7.5h-.404c-1.386-4.24-9.722-7.5-19.798-7.5-10.076 0-18.412 3.26-19.798 7.5H5C10.672 21.325 35.38 2 65 2zM45 44c0-23.2 6.667-37.2 20-42M85 44c2-23.2-6.667-37.2-20-42" class="highlight" stroke="#00BBDC" stroke-linejoin="round"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>