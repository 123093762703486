import Vue from 'vue'
import moment from "moment";

/**
 * State
 */
const state = {
    menuOpen: false,
    initialisingScanner: false
};

/**
 * Getters
 */
const getters = {
    menuOpen: state => state.menuOpen,

    initialisingScanner: state => state.initialisingScanner,

    weFixUri: () => {
        if(process.env.VUE_APP_API_URI != null){
            return process.env.VUE_APP_API_URI;
        }
        return "https://wefix.co.uk";
    },

    appUri: () => {
        if(process.env.VUE_APP_APP_URI != null){
            return process.env.VUE_APP_APP_URI;
        }
        return "https://app.wefix.co.uk";
    },

    techSupportNumber: () => {
        if (process.env.VUE_APP_COUNTRY_CODE === "AU") {
            return '';
        } else {
            return '0808 164 8936';
        }
    },

    appRegion: () => {
        return process.env.VUE_APP_COUNTRY_CODE;
    }
};

/**
 * Actions
 */
const actions = {
    /** Display toast */
    displayToast(_, {text, type = 'info', duration = 5000, dismissible = true}) {
        return new Promise((resolve) => {
            type = (type === 'danger') ? 'error' : type;

            let toastSettings = {
                containerClass: "custom-toast",
                position: "top-right",
                type: type,
                duration: duration,
                closeOnSwipe: true,
                icon: {
                    name: ''
                }
            };

            if (dismissible) {
                toastSettings.action = {
                    onClick : (e, toastObject) => {
                        toastObject.goAway(0);
                    }
                };
            }

            switch (type) {
                case 'success':
                    toastSettings.icon.name = 'fal fa-check-circle';
                    break;
                case 'warning':
                    toastSettings.icon.name = 'fal fa-exclamation-circle';
                    break;
                case 'danger':
                case 'error':
                    toastSettings.icon.name = 'fal fa-times-circle';
                    break;
                default:
                    toastSettings.icon.name = 'fal fa-info-circle';
            }

            Vue.toasted.show(text, toastSettings);

            resolve();
        });
    },

    /** Toggle the main menu */
    toggleMenu({commit, state}) {
        if (state.menuOpen === false) {
            commit('OPEN_MENU');
            return;
        }

        commit('CLOSE_MENU');
    },

    logToApi(_, {action, event = 'start', repairKitId = null, assetId = null, stockSerialNumberId = null, shipmentId = null}) {
        this.$axios.post(`/api/v4/app/log/${action}/${event}`, {
            repair_kit_id: repairKitId,
            asset_id: assetId,
            stock_serial_number_id: stockSerialNumberId,
            shipment_id: shipmentId
        }).catch(errors => {
            console.error(errors);
        });
    },

    setInitialisingScanner({commit}, value) {
        commit('SET_INITIALISING_SCANNER', value);
    },

    formatDate(_, {date}) {
        return moment(date).format('ddd Do MMM \'YY');
    }
};

/**
 * Mutations
 */
const mutations = {
    OPEN_MENU(state) {
        state.menuOpen = true;
    },

    CLOSE_MENU(state) {
        state.menuOpen = false;
    },

    SET_INITIALISING_SCANNER(state, value) {
        state.initialisingScanner = value;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}