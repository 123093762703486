<template>
    <div :class="'img-' + size" class="status-icon success-animated">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"><circle cx="65.1" cy="65.1" r="62.1" fill="none" stroke="#61D395" stroke-miterlimit="10" stroke-width="6" class="path circle"/><path fill="none" stroke="#61D395" stroke-linecap="round" stroke-miterlimit="10" stroke-width="6" d="M100.2 40.2L51.5 88.8 29.8 67.5" class="path check"/></svg>
    </div>
</template>

<script>
    export default {
        name: "SuccessAnimated",

        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>