<template>
    <div :class="'img-' + size" class="custom-icon">
		<svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><rect class="path" stroke="#0C2340" x="25" y="2" width="80" height="126" rx="10"/><path d="M47 118.5h36M37 66l4.509 11.491L53 82l-11.491 4.509L37 98l-4.509-11.491L21 82l11.491-4.509L37 66zM16.5 37l3.523 8.977L29 49.5l-8.977 3.523L16.5 62l-3.523-8.977L4 49.5l8.977-3.523L16.5 37zM44 15l2.818 7.182L54 25l-7.182 2.818L44 35l-2.818-7.182L34 25l7.182-2.818L44 15z" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>