<template>
<!--    <router-link :to="{ name: 'job', params: {jobId: jobId}}" class="card card-body card-link table-layout" :class="{'disabled': !isActive}">-->
    <router-link :to="{ name: 'job', params: {jobId:job.id}}" class="card card-body card-link table-layout">
        <template v-if="!isGeneral">
            <div class="card-cell">{{ jobName }}</div>
            <div class="card-cell text-no-wrap">{{ companyName }} <template v-if="companyName !== 'Samsung In-warranty' && job.in_warranty">In-warranty</template></div>
        </template>

        <template v-if="isGeneral">
            <div class="card-cell position-relative"><div class="colspan-text">{{ job.note }}</div></div>
            <div class="card-cell"></div>
        </template>

        <div class="card-cell time">{{ job.start_time }} - {{ job.end_time }}</div>
        <div class="card-cell status"><div class="badge badge-info text-capitalize">{{ job.job_type }}</div></div>
        <div class="card-cell status">
            <div class="badge badge-success" v-if="job.complete">Completed</div>
            <div class="badge badge-warning" v-if="!job.complete">Pending</div>
        </div>
    </router-link>
</template>

<script>
    export default {
        props: [
            "isActive",
            "job",
            "jobId"
        ],

        computed: {
            companyName() {
                if (this.job.company_name === "Reviveaphone") {
                    return "WeFix";
                }
                return this.job.company_name;
            },

            jobName() {
                if (this.job.job_type === 'refurbishment') {
                    return this.job.asset_day_code;
                }
                return `${ this.job.first_name } ${ this.job.second_name }`;
            },

            isGeneral() {
                return this.job.job_type === 'general';
            },
        }
    }
</script>