<template>
    <b-modal
            id="cannot-repair-asset-modal"
            no-footer
            title="Cannot repair asset">
        <empty-state
                statusIcon="warning"
                statusIconSize="4x"
                title='Are you sure you want to mark this asset as "cannot repair"?'
                subtitle="Only select this option if there is absolutely no way to repair the device.">
        </empty-state>
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="$bvModal.hide('cannot-repair-asset-modal')">Cancel</button>
            <button type="button" class="btn btn-danger" @click="cannotRepairConfirm">Mark as cannot repair</button>
        </template>
    </b-modal>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters([
                'asset',
                'assetDevice',
            ])
        },

        methods: {
            ...mapActions([
                'setAssetCannotRepair'
            ]),

            cannotRepairConfirm() {
                this.setAssetCannotRepair(this.asset.id).then(() => {
                    this.$bvModal.hide('cannot-repair-asset-modal');
                    this.$router.push({name: 'bulkRepairImeiSearch', params:{cannotRepairAsset: true}});
                });
            }
        }
    }
</script>

<style scoped>

</style>