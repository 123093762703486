<template>
  <div class="container scanner-container">
    <qrcode-stream
        ref="stream"
        v-if="!loading"
        class="qrcode-stream"
        :track="paintOutline"
        :formats="['micro_qr_code', 'qr_code', 'data_matrix', 'code_128']"
        @error="onError"
        @detect="onDetect"
        @init="onReady"
    >
      <div class="sideBar">
        <div class="sideBar-content">
          <button class=" barBtn" title="Refocus" @click="focus" v-if="focusSupport">
            <span>&#8857;</span>
          </button>
          <div class="btnGroup" v-if="zoomSupport">
            <button class=" barBtn" title="Zoom In" @click="zoomIn">+</button>
            <button class=" barBtn" title="Zoom Out" @click="zoomOut">-</button>
          </div>
          <button class=" barBtn" title="Reload scanner" @click="reload">
            <icon-reload class="icon-all-white icon-chunky " size="sm"></icon-reload>
          </button>
        </div>
      </div>
    </qrcode-stream>

    <loading-state
        v-if="loading"
        centered
        overlay
        title="Loading scanner"
    />
  </div>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader';
import {BarcodeScanned} from "@/models/barcodeScanned";

export default {
  components: {
    QrcodeStream,
  },
  data() {
    return {
      scanner: null,
      caps: null,
      zoomSupport: false,
      focusSupport: false,
      zoom: null,
      result: null,
      loading: false,
    };
  },
  methods: {
    async onReady(caps) {
      const promise = await caps;
      this.caps = promise.capabilities;
      this.scanner = this.$refs.stream.cameraInstance.stream.getVideoTracks()[0];
      this.zoom = this.scanner.getSettings().zoom || 1;
      this.checkZoomSupport();
      if (this.caps.focusMode) {
        this.focusSupport = this.caps.focusMode.includes('continuous') || this.caps.focusMode.includes('manual');
      }

    },

    async onDetect(detectedCodesPromise) {
      let detectedCodes = await detectedCodesPromise;
      detectedCodes = await detectedCodes.content;
      if (this.isJson(detectedCodes)) {
        detectedCodes = new BarcodeScanned(JSON.parse(detectedCodes));
      }

      this.result = detectedCodes;
      this.$emit("barcodeHasBeenScanned", detectedCodes);
    },

    focus() {
      if (this.focusSupport) {
        this.scanner.applyConstraints({
          advanced: [{focusMode: 'continuous'}]
        }).catch((error) => {
          console.error("Failed to refocus:", error);
        });
      } else {
        console.warn("Focus not supported on this device.");
      }
    },

    checkZoomSupport() {
      if (this.scanner && this.caps) {
        if (this.caps.zoom) {
          this.zoomSupport = true;
        }
      }
    },

    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = 'red'

        ctx.beginPath()
        ctx.moveTo(firstPoint.x, firstPoint.y)
        for (const {x, y} of otherPoints) {
          ctx.lineTo(x, y)
        }
        ctx.lineTo(firstPoint.x, firstPoint.y)
        ctx.closePath()
        ctx.stroke()
      }
    },


    isJson(string) {
      try {
        JSON.parse(string);
      } catch (e) {
        return false;
      }
      return true;
    },

    onError(error) {
      this.displayToast({text: error, type: 'danger'});
    },

    async reload() {
      this.loading = true
      await this.$nextTick()
      this.loading = false
    },

    zoomIn() {
      if (this.zoom < this.caps.zoom.max) {
        this.zoom += 0.5;
        this.applyZoom();
      }
    },
    zoomOut() {
      if (this.zoom > this.caps.zoom.min) {
        this.zoom -= 0.5;
        this.applyZoom();
      }
    },
    applyZoom() {
      if (this.zoomSupport) {
        this.scanner.applyConstraints({advanced: [{zoom: this.zoom}]});
      } else {
        console.warn("Zoom not supported on this device or applyConstraints not available.");
      }
    },
  },
};
</script>

<style scoped>


.scanner-container {
  position: relative;
  aspect-ratio: 3 / 1.3;
}

.qrcode-stream {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  border: 2px solid #4a4f68;
  overflow: hidden;
}

.sideBar {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sideBar-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.btnGroup {
  margin: 10px 0;

  .barBtn {
    margin: 5px 0;

  }
}


.barBtn {
  width: 35px;
  height: 35px;
  font-weight: bold;
  margin: 10px 0;
  background-color: rgba(37, 42, 65, 0.8);
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  padding: 0 !important;
  color: #FFFFFF !important;
}

.barBtn:hover {
  color: #3a415c;
  background-color: rgba(0, 0, 0, 0.7);
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .sideBar {
    width: 60px;
  }

  .scanner-container {
    aspect-ratio: 1;
  }
}


</style>
