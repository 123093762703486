import Vue from 'vue'
import VueRouter from 'vue-router'

const Auth = () => import('Views/auth/LoginIndex');

const Dashboard = () => import('Views/dashboard/DashboardIndex');

const DailyChecklist = () => import('Views/dailyChecklist/DailyChecklistIndex');

const BulkRepairImeiSearch = () => import('Views/bulkRepair/ImeiSearchIndex');
const BulkRepairNextSteps = () => import('Views/bulkRepair/AssetNextSteps');
const BulkRepairDevice = () => import('Views/bulkRepair/ConfirmDevice');
const BulkRepairPhotos = () => import('Views/bulkRepair/RepairImages');
const BulkRepairDiagnostic = () => import('Views/bulkRepair/BulkRepairIndex');
const BulkRepairPrintLabel = () => import('Views/bulkRepair/PrintDiagnosticBarcode');
const BulkRepairProblems = () => import('Views/bulkRepair/ConfirmAssetProblems');
const BulkRepairSymptomCart = () => import('Views/bulkRepair/SymptomCart');

const StoreRepairImeiSearch = () => import('Views/store/StoreAssetCollection');
const StoreZoneFinder = () => import('Views/store/StoreZoneFinder');

const InHouseRepairs = () => import('Views/inHouseRepairs/InHouseRepairsIndex');

const RelocateStock = () => import('Views/relocateStock/relocateStock');

const GoodsIn = () => import('Views/goodsIn/GoodsInIndex');

const OutboundShipments = () => import('Views/shipments/OutboundShipments');
const TechnicianInboundShipments = () => import('Views/shipments/technicians/InboundShipments');
const TechnicianOutboundShipments = () => import('Views/shipments/technicians/OutboundShipments');

const RepairKit = () => import('Views/repairKits/RepairKit');
const repairKitsDashboard = () => import('Views/repairKits/RepairKitsDashboard');
const RepairKitLabel = () => import('Views/repairKits/RepairKitLabel');
const RepairKitsPicking = () => import('Views/repairKits/PickingIndex');
const UploadPhotos = () => import('Views/uploadPhotos/UploadPhotos');
const JitRepairKitsPicking = () => import('Views/repairKits/JitPickingIndex');
const RepairKitsPacking = () => import('Views/repairKits/PackingIndex');
const RepairKitsReturns = () => import('Views/repairKits/ReturnsIndex');
const ReturnsRepairKit = () => import('Views/repairKits/ReturnsKit');

const JobList = () => import('Views/jobList/JobListIndex');

const Job = () => import('Views/job/JobIndex');

const Overtime = () => import('Views/overtime/OvertimeIndex');
const OvertimeRequest = () => import('Views/overtime/OvertimeRequest');

const Repair = () => import('Views/repairProcess/RepairProcessIndex');
const StartChecklist = () => import('Views/repairProcess/StartChecklist');
const ScanAsset = () => import('Views/repairProcess/ScanAsset');
const StartTestChecklist = () => import('Views/repairProcess/StartTestChecklist');
const WarrantyDetails = () => import('Views/repairProcess/WarrantyDetails');
const QualityControl = () => import('Views/repairProcess/QualityControl');
const RepairImages = () => import('Views/repairProcess/RepairImages');
const SymptomCodes = () => import('Views/repairProcess/SymptomCodes');
const IrisCodes = () => import('Views/repairProcess/IrisCodes');
const ChooseFault = () => import('Views/repairProcess/ChooseFault');
const DefectDescriptions = () => import('Views/repairProcess/DefectDescriptions');
const AddImei = () => import('Views/repairProcess/AddImei');
const AddSerialNumber = () => import('Views/repairProcess/AddSerialNumber');
const AddBoardSize = () => import('Views/repairProcess/AddBoardSize');
const ConfirmProblems = () => import('Views/repairProcess/ConfirmProblems');
const AddParts = () => import('Views/repairProcess/AddParts');
const PostRepairConfirmations = () => import('Views/repairProcess/PostRepairConfirmations.vue');
const TestChecklist = () => import('Views/repairProcess/TestChecklist');
const SamsungNotes = () => import('Views/repairProcess/SamsungNotes');
const RepairComplete = () => import('Views/repairProcess/RepairComplete');

const Device = () => import('Views/revisit/Device');
const Revisit = () => import('Views/revisit/RevisitIndex');
const DeviceIssues = () => import('Views/revisit/DeviceIssues');
const RepairParts = () => import('Views/revisit/RepairParts');
const AdditionalParts = () => import('Views/revisit/AdditionalParts');
const UpdateAddress = () => import('Views/revisit/UpdateAddress');
const RepairDate = () => import('Views/revisit/RepairDate');
const ConfirmRevisit = () => import('Views/revisit/ConfirmRevisit');

const Test = () => import('Views/test/TestPage');

Vue.use(VueRouter);

import store from '../vuex/store';
import User from '../services/user';
import SawPage from "Components/forms/SawPage";
import SawList from "Components/forms/SawList";
import SawView from "Components/forms/SawView";
let user = new User(store.getters.user);

const index = [
    /** Auth */
    {
        path: '/login',
        name: 'login',
        component: Auth,
        meta: {title: 'Login'},
        beforeEnter: (to, from, next) => {
            store.dispatch('clearUser');
            next();
        }
    },

    {
        path: '/logout',
        name: 'logout',
        redirect: 'login'
    },

    /** Store Repair IMEI Search */
    {
        path: '/store',
        redirect: {name: 'storeRepairImeiSearch'},
    },

    /** Bulk Repair IMEI Search */
    {
        path: '/store/imei-search',
        name: 'storeRepairImeiSearch',
        component: StoreRepairImeiSearch,
        props: true,
        meta: { title: 'Mark Assets as Collected' }
    },

    /** Bulk Repair IMEI Search */
    {
        path: '/bulk-repair',
        redirect: {name: 'bulkRepairImeiSearch'},
    },

    /** Bulk Repair IMEI Search */
    {
        path: '/bulk-repair/imei-search',
        name: 'bulkRepairImeiSearch',
        component: BulkRepairImeiSearch,
        props: true,
        meta: { title: 'IMEI Search' }
    },

    /** Bulk Repair Print Label */
    {
        path: '/bulk-repair/:assetId/print-label',
        name: 'bulkRepairPrintLabel',
        component: BulkRepairPrintLabel,
        props: true,
        meta: { title: 'Print Asset Label' }
    },

    /** Bulk Repair Diagnostic Process */
    {
        path: '/bulk-repair/:assetId/diagnostics',
        name: 'bulkRepairDiagnostics',
        component: BulkRepairDiagnostic,
        props: true,
        redirect: {name: 'bulkRepairDiagnostics.device'},
        children: [
            /** Confirm Device */
            {
                path: 'device',
                name: 'bulkRepairDiagnostics.device',
                component: BulkRepairDevice,
                props: true,
                meta: {
                    title: 'Confirm Device'
                }
            },
            /** Confirm Device */
            {
                path: 'photos',
                name: 'bulkRepairDiagnostics.photos',
                component: BulkRepairPhotos,
                props: true,
                meta: {
                    title: 'Repair Photos'
                }
            },
            /** Confirm Problems */
            {
                path: 'confirm-problems',
                name: 'bulkRepairDiagnostics.confirmProblems',
                component: BulkRepairProblems,
                props: true,
                meta: {
                    title: 'Confirm Problems'
                }
            },
            /** Symptom Cart */
            {
                path: 'symptom-cart',
                name: 'bulkRepairDiagnostics.symptomCart',
                component: BulkRepairSymptomCart,
                props: true,
                meta: {
                    title: 'Cart'
                }
            },
            /** Next Steps */
            {
                path: 'next-steps',
                name: 'bulkRepairDiagnostics.nextSteps',
                component: BulkRepairNextSteps,
                props: true,
                meta: {
                    title: 'Next Steps'
                }
            },
        ]
    },

    /** Daily Checklist */
    {
        path: '/daily-checklist',
        name: 'dailyChecklist',
        component: DailyChecklist,
        meta: {title: 'Daily Checklist'}
    },

    /** Dashboard */
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {title: 'Dashboard'}
    },

    /** In-House Repairs */
    {
        path: '/in-house-repair',
        name: 'inHouseRepairs',
        component: InHouseRepairs,
        meta: { title: 'In-House Repairs' }
    },

    /** Relocate Stock */
    {
        path: '/relocate-stock',
        name: 'relocateStock',
        component: RelocateStock,
        meta: { title: 'Relocate Stock' }
    },

    /** Goods In */
    {
        path: '/goods-in',
        name: 'goodsIn',
        component: GoodsIn,
        meta: { title: 'Goods In' }
    },

    /** Goods In */
    {
        path: '/zone-finder',
        name: 'zoneFinder',
        component: StoreZoneFinder,
        meta: { title: 'Zone Finder' }
    },

    /** Job List */
    {
        path: '/jobs',
        name: 'jobList',
        component: JobList,
        meta: { title: 'Today\'s Repair Jobs' }
    },

    /** Job */
    {
        path: '/jobs/:jobId',
        name: 'job',
        component: Job,
        props: true,
        meta: {
            title: 'Job'
        }
    },

    /** Outbound Shipments */
    {
        path: '/outbound-shipments',
        name: 'outboundShipments',
        component: OutboundShipments,
        meta: { title: 'Outbound Shipments' }
    },

    /** Overtime */
    {
        path: '/overtime',
        name: 'overtime',
        component: Overtime,
        props: true,
        meta: { title: 'Overtime' }
    },

    /** Overtime Request */
    {
        path: '/overtime/create',
        name: 'overtime.request',
        component: OvertimeRequest,
        meta: { title: 'Request Overtime' }
    },

    /** Repair Kit */
    {
        path: '/repair-kit-picking/repair-kit/:repairKitId',
        name: 'repairKitPicking.repairKit',
        component: RepairKit,
        props: true,
        meta: {
            title: 'Repair Kit'
        }
    },

    /** Repair Kits Dashboard */
    {
        path: '/repair-kit-dashboard',
        name: 'repairKitsDashboard',
        component: repairKitsDashboard,
        props: true,
        meta: { title: 'Repair Kit Dashboard' }
    },

    /** Repair Kits Label */
    {
        path: '/repair-kit-label/:repairKitId',
        name: 'repairKitPicking.repairKitLabel',
        component: RepairKitLabel,
        props: true,
        meta: { title: 'Repair Kit Label' }
    },

    /** Repair Kits Packing */
    {
        path: '/repair-kit-packing',
        name: 'repairKitPacking',
        component: RepairKitsPacking,
        props: true,
        meta: { title: 'Repair Kit Packing' }
    },

    /** Repair Kits Picking */
    {
        path: '/repair-kit-picking',
        name: 'repairKitPicking',
        component: RepairKitsPicking,
        props: true,
        meta: { title: 'Repair Kit Picking' },
    },

    {
        path: '/jit-repair-kit-picking',
        name: 'jitRepairKitPicking',
        component: JitRepairKitsPicking,
        props: true,
        meta: { title: 'JIT Repair Kit Picking'}
    },

    /** Repair Kits Returns */
    {
        path: '/repair-kit-returns',
        name: 'repairKitReturns',
        component: RepairKitsReturns,
        props: true,
        meta: { title: 'Repair Kit Returns' }
    },

    /** Repair Kits Returns */
    {
        path: '/repair-kit-returns/repair-kit',
        name: 'repairKitReturns.repairKit',
        component: ReturnsRepairKit,
        props: true,
        meta: { title: 'Repair Kit Return' }
    },

    /** MoH Upload Photos */
    {
        path: '/upload-photos/:imei?/:phototype?',
        name: 'uploadPhotos',
        component: UploadPhotos,
        props: true,
        meta: { title: 'Upload Photos' }
    },

    /** Repair Process */
    {
        path: '/jobs/:jobId/repair/:deviceId',
        name: 'repair',
        component: Repair,
        props: true,
        redirect: {name: 'repair.startChecklist'},
        children: [
            /** Add IMEI */
            {
                path: 'add-imei',
                name: 'repair.addImei',
                component: AddImei,
                props: true,
                meta: {
                    title: 'Add IMEI'
                }
            },
            /** Add Parts */
            {
                path: 'add-parts',
                name: 'repair.addParts',
                component: AddParts,
                props: true,
                meta: {
                    title: 'Add Parts'
                }
            },
            /** Add Serial Number */
            {
                path: 'add-serial-number',
                name: 'repair.addSerialNumber',
                component: AddSerialNumber,
                props: true,
                meta: {
                    title: 'Add Serial Number'
                }
            },
            /** Add Board Size */
            {
                path: 'add-board-size',
                name: 'repair.addBoardSize',
                component: AddBoardSize,
                props: true,
                meta: {
                    title: 'Add Board Size'
                }
            },
            /** After Pictures */
            {
                path: 'after-pictures',
                name: 'repair.afterPictures',
                component: RepairImages,
                props: true,
                meta: {
                    title: 'After Pictures'
                }
            },
            /** Before Pictures */
            {
                path: 'before-pictures',
                name: 'repair.beforePictures',
                component: RepairImages,
                props: true,
                meta: {
                    title: 'Before Pictures'
                }
            },
            /** Confirm Problems */
            {
                path: 'confirm-problems',
                name: 'repair.confirmProblems',
                component: ConfirmProblems,
                props: true,
                meta: {
                    title: 'Confirm Problems'
                }
            },
            /** Fault Descriptions */
            {
                path: 'defect-descriptions',
                name: 'repair.defectDescriptions',
                component: DefectDescriptions,
                props: true,
                meta: {
                    title: 'Fault Description'
                }
            },
            /** IRIS Codes */
            {
                path: 'choose-fault',
                name: 'repair.chooseFault',
                component: ChooseFault,
                props: true,
                meta: {
                    title: 'Choose Fault'
                }
            },
            /** IRIS Codes */
            {
                path: 'iris-codes',
                name: 'repair.irisCodes',
                component: IrisCodes,
                props: true,
                meta: {
                    title: 'IRIS Codes'
                }
            },
            /** Post Repair Confirmations */
            {
                path: 'post-repair-confirmations',
                name: 'repair.postRepairConfirmations',
                component: PostRepairConfirmations,
                props: true,
                meta: {
                    title: 'Post Repair Confirmations'
                }
            },
            /** Repair Complete */
            {
                path: 'repair-complete',
                name: 'repair.repairComplete',
                component: RepairComplete,
                props: true,
                meta: {
                    title: 'Repair Complete'
                }
            },
            /** Samsung Notes */
            {
                path: 'samsung-notes',
                name: 'repair.samsungNotes',
                component: SamsungNotes,
                props: true,
                meta: {
                    title: 'Submit Samsung Notes'
                }
            },
            /** Start Asset */
            {
                path: 'scan-asset',
                name: 'repair.scanAsset',
                component: ScanAsset,
                props: true,
                meta: {
                    title: 'Scan Asset'
                }
            },
            /** Start Checklist */
            {
                path: 'start-checklist',
                name: 'repair.startChecklist',
                component: StartChecklist,
                props: true,
                meta: {
                    title: 'Start Checklist'
                }
            },
            /** Start Test Checklist */
            {
                path: 'start-test-checklist',
                name: 'repair.startTestChecklist',
                component: StartTestChecklist,
                props: true,
                meta: {
                    title: 'Initial Tests'
                }
            },
            /** Symptom Codes */
            {
                path: 'symptom-codes',
                name: 'repair.symptomCodes',
                component: SymptomCodes,
                props: true,
                meta: {
                    title: 'Symptom Codes'
                }
            },
            /** Test Checklist */
            {
                path: 'test-checklist',
                name: 'repair.testChecklist',
                component: TestChecklist,
                props: true,
                meta: {
                    title: 'Test Checklist'
                }
            },
            /** Warranty Details */
            {
                path: 'warranty-details',
                name: 'repair.warrantyDetails',
                component: WarrantyDetails,
                props: true,
                meta: {
                    title: 'Warranty Details'
                }
            },

            /**
             * IQC check, Added for WEF-1114
             * @see https://we-fix.atlassian.net/browse/WEF-1114
             */
            {
                path: 'iqc',
                name: 'repair.iqc',
                component: QualityControl,
                props: true,
                meta: {
                    title: 'IQC'
                }
            },

            /**
             * OQC check, Added for WEF-1114
             * @see https://we-fix.atlassian.net/browse/WEF-1114
             */
            {
                path: 'oqc',
                name: 'repair.oqc',
                component: QualityControl,
                props: true,
                meta: {
                    title: 'OQC'
                }
            },

            /** Catch all 404 */
            {
                path: '*',
                redirect: {name: 'repair.startChecklist'}
            }
        ]
    },

    {
        path: '/jobs/:jobId/book-revisit',
        name: 'book-revisit',
        component: Revisit,
        props: true,
        redirect: {name: 'revisit.device-issues'},
        children: [
            /** Device */
            {
                path: 'device',
                name: 'revisit.device',
                component: Device,
                props: true,
                meta: {
                    title: 'Revisit Device'
                }
            },
            /** Device Issues */
            {
                path: 'device-issues',
                name: 'revisit.device-issues',
                component: DeviceIssues,
                props: true,
                meta: {
                    title: 'Revisit Device Issues'
                }
            },
            /** Repair Parts */
            {
                path: 'repair-parts',
                name: 'revisit.repair-parts',
                component: RepairParts,
                props: true,
                meta: {
                    title: 'Revisit Repair Parts'
                }
            },
            /** Additional Parts */
            {
                path: 'additional-parts',
                name: 'revisit.additional-parts',
                component: AdditionalParts,
                props: true,
                meta: {
                    title: 'Revisit Additional Parts'
                }
            },
            /** Update Address */
            {
                path: 'update-address',
                name: 'revisit.update-address',
                component: UpdateAddress,
                props: true,
                meta: {
                    title: 'Revisit Update Address'
                }
            },
            /** Repair Date */
            {
                path: 'repair-date',
                name: 'revisit.repair-date',
                component: RepairDate,
                props: true,
                meta: {
                    title: 'Revisit Repair Date'
                }
            },
            /** Confirm Revisit */
            {
                path: 'confirm-revisit',
                name: 'revisit.confirm-revisit',
                component: ConfirmRevisit,
                props: true,
                meta: {
                    title: 'Confirm Revisit'
                }
            },
            /** Catch all 404 */
            {
                path: '*',
                redirect: {name: 'job'}
            }
        ]
    },

    /** Technician Inbound Shipments */
    {
        path: '/technician-inbound-shipments',
        name: 'technicianInboundShipments',
        component: TechnicianInboundShipments,
        meta: { title: 'Inbound Shipments' }
    },

    /** Technician Outbound Shipments */
    {
        path: '/technician-outbound-shipments',
        name: 'technicianOutboundShipments',
        component: TechnicianOutboundShipments,
        meta: { title: 'Outbound Shipments' }
    },

    /** SAW Requests */
    {
        path: '/saw/list',
        name: 'sawList',
        component: SawList,
        meta: { title: 'List SAW Requests' }
    },
    {
        path: '/saw/view/:sawId',
        name: 'sawView',
        component: SawView,
        props: true,
        meta: { title: 'View SAW Request' }
    },
    {
        path: '/saw/request/:deviceId/job/:jobId',
        name: 'sawRequest',
        component: SawPage,
        props: true,
        meta: { title: 'Create SAW Request' }
    },

    /** Test */
    {
        path: '/test',
        component: Test,
    }

];


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: index
});

router.beforeEach((to, from, next) => {

    document.title = to.meta.title;

    window.scrollTo(0, 0); // TODO: for some reason this isn't working in the repair process

    to.matched.some(record => {
        if (record.name !== 'login' && !user.details.id) {
            store.dispatch('clearUser');
            next('/login');
        }
        else next();
    });

});

export default router
