<template>
    <div class="loading-state" :class="{ 'centered': centered, 'fullscreen': fullscreen, 'overlay': overlay, 'inline': inline,'sm': small }">

        <!-- Loading Icon -->
        <animated-loading :size="loadingIconSize"></animated-loading>

        <!-- Title -->
        <h2 v-if="title" v-html="title"></h2>

        <!-- Subtitle -->
        <h3 v-if="subtitle" v-html="subtitle"></h3>

    </div>
</template>

<script>
    export default {
        props: {
            centered: {
                type: Boolean,
                default: false
            },
            fullscreen: {
                type: Boolean,
                default: false
            },
            inline: {
                type: Boolean,
                default: false
            },
            loadingIconSize: {
                type: String,
                default: "3x"
            },
            overlay: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
            subtitle: {
                type: String,
                default: "Hang tight!"
            },
            title: {
                type: String,
                default: "Loading..."
            }
        }
    }
</script>