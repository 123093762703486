<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"><path d="M112 32v91a5 5 0 0 1-5 5H23a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5h60l29 30z" class="path" stroke="#0C2340"/><path class="path" stroke="#0C2340" d="M112 32H83V2"/><path d="M39 54h52a5 5 0 0 1 5 5v43a5 5 0 0 1-5 5H39a5 5 0 0 1-5-5V59a5 5 0 0 1 5-5zm26.5 16v35.5m-29-36h58m-58 19h58" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>