<template>
    <b-modal
        id="remove-parts-modal"
        hide-header
        no-footer
        no-close-on-backdrop
        no-close-on-esc>
        <empty-state
                statusIcon="warning"
                statusIconSize="4x"
                title="Please remove all parts from this device"
                subtitle="If this device has been found to be BER, you MUST return the device back to its original condition.">
        </empty-state>
        <template v-slot:modal-footer>
            <button v-if="isJobManager" type="button" class="btn btn-light" @click="$bvModal.hide('remove-parts-modal')">Close</button>
            <router-link :to="{ name: 'repair.addParts'}" class="btn btn-info">Remove parts</router-link>
        </template>
    </b-modal>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters(['isJobManager'])
        }
    }
</script>

<style scoped>

</style>