<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M120.233 38.357C110.047 18.467 89.37 4.848 65.512 4.813c-31.96 0-58.223 24.415-61.184 55.626m75.734-23.58l41.384 6.56L128 2" class="path" stroke="#0C2340"/><path d="M117.905 105.918c-10.186-19.89-30.862-33.509-54.72-33.544C31.223 72.374 4.96 96.789 2 128m75.735-23.58l41.383 6.56 6.554-41.419" class="highlight" stroke="#00BBDC" transform="rotate(180 63.836 98.78)"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>