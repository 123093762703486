<template>
    <div class="container pb-0">
        <div class="card card-body card-body-sm mb-0">
            <div class="media align-items-center">
                <div class="media-left">
                    <img src="https://likewizerepair-uk.s3.eu-west-2.amazonaws.com/makes/apple/phone/iphone-xs/space-grey.svg" class="img-xl">
                </div>
                <div class="media-body">
                    <h5 class="mb-0">iPhone XS Space Grey</h5>
                </div>
                <div class="media-right align-self-center">
                    <button class="btn btn-red btn-sm"  @click="$bvModal.show('job-status-modal')">Change status</button>
                    <button class="btn btn-info btn-sm"  @click="$bvModal.show('note-modal')">Add note</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            /** Show note modal */
            showNoteModal() {
                // $('#note-modal').modal('show');
            },

            /** Show job status modal */
            showJobStatusModal() {
                // $('#job-status-modal').modal('show');
            }
        }
    }
</script>

<style scoped>

</style>