<template>
    <div class="scan-page">

        <div class="container repair-container">
            <div class="card card-body">

                <!-- Initial state -->
                <template v-if="!validAssetBarcode">

                    <!-- Empty State -->
                    <empty-state
                            v-if="!invalidAssetBarcode"
                            icon="scanner"
                            inline
                            small
                            title="Scan the asset barcode to continue">
                    </empty-state>

                    <!-- Invalid barcode -->
                    <empty-state
                            v-if="invalidAssetBarcode"
                            statusIcon="error"
                            inline
                            small
                            title="This is not a valid asset barcode">
                    </empty-state>

                    <!-- Barcode input (admins only) -->
                    <div class="row" v-if="isJobManager">
                        <div class="col-12">
                            <hr />
                            <label>Or manually enter the barcode</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" v-model="manualAssetId">
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-success" @click="submitAssetBarcode()">Add</button>
                        </div>
                    </div>
                </template>

                <!-- Valid barcode -->
                <empty-state
                        v-if="validAssetBarcode"
                        statusIcon="success"
                        title="Valid asset barcode"
                        subtitle="You can proceed with the repair">
                </empty-state>

            </div>
        </div>

        <!-- Footer -->
        <fixed-footer
                :class="{'active': showFooter}"
                :nextLabel="this.nextRepairStep($route.name).title"
                :nextRoute="this.nextRepairStep($route.name).routeName">
        </fixed-footer>

    </div>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";

    export default {
        data() {
            return {
                assetBarcode: null,
                manualAssetId: null,
                invalidAssetBarcode: false,
                showFooter: false,
                validAssetBarcode: false
            }
        },

        computed: {
            ...mapGetters([
                'isJobManager',
                'isTechnician',
                'job',
                'nextRepairStep',
                'user'
            ]),

            technicianId() {
                if (this.user.technician && this.user.technician.id) {
                    return this.user.technician.id;
                }
                return null;
            }
        },

        methods: {
            ...mapActions(['checkAsset', 'displayToast']),

            /** Barcode scanned */
            barcodeScanned(barcode) {
                this.assetBarcode = barcode.data;

                if (this.assetBarcode.type === 'asset') {
                    this.submitAssetBarcode();
                }

                this.invalidAssetBarcode = true;
            },

            /** Submit the barcode */
            submitAssetBarcode() {
                if (this.manualAssetId || (this.assetBarcode && this.assetBarcode.type === 'asset')) {

                    let assetId = this.manualAssetId;
                    if (this.assetBarcode) {
                        assetId = this.assetBarcode.id;
                    }

                    let json = JSON.parse(assetId);

                    if (json.id) {
                        assetId = json.id;
                    }

                    this.checkAsset({
                        jobId: this.job.id,
                        assetId: assetId,
                        technicianId: this.technicianId
                    }).then(() => {
                        this.invalidAssetBarcode = false;
                        this.validAssetBarcode = true;
                        this.$emit("barcodeHasBeenScanned",this.showFooter = true);
                    }).catch(error => {
                        this.displayToast({text: error, type: 'error'});
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>