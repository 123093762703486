<template>
    <b-modal
            id="repeat-repair-modal"
            hide-footer
            hide-header-close
            no-close-on-backdrop
            no-close-on-esc
            title="Repeat Repair">

        <!-- Error -->
        <empty-state
                statusIcon="error"
                statusIconSize="4x"
                title="This device is a repeat repair"
                subtitle="We've already repaired this device in the last 90 days">
            <slot>
                <button class="btn btn-light" @click="closeModal()">Acknowledge</button>
            </slot>
        </empty-state>

    </b-modal>
</template>

<script>
    export default {
        methods: {
            closeModal() {
                this.$bvModal.hide('repeat-repair-modal');
            },

            displayModal() {
                this.$bvModal.show('repeat-repair-modal');
            }
        }
    }
</script>

<style scoped>

</style>