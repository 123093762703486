const state = {
    overtimeId: null,
    overtimes:[],
    allowedOvertimeDates: [],
    overtimeDateSelected: null
};

const getters = {
    overtimeId: state => state.overtimeId,
    overtimes: state => state.overtimes,
    overtimeDateSelected: state => state.overtimeDateSelected,
    allowedOvertimeDates: state => state.allowedOvertimeDates,
};

const actions = {
    /** Add overtime */
    addOvertime(__, {technicianId, date, dayStartTime, dayEndTime}) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/v4/technicians/${technicianId}/overtime`, {
                date: date,
                day_start_time: dayStartTime,
                day_end_time: dayEndTime,
            }).then(() => {
                    resolve();
            }).catch(errors => {
                reject(errors.response.data.messages);
            });
        });
    },

    /** Delete overtime */
    deleteOvertime(__, {technicianId, overtimeId}) {
        return new Promise((resolve, reject) => {
            this.$axios.delete(`/api/v4/technicians/${technicianId}/overtime/${overtimeId}`)
                .then(() => {
                    resolve();
                }).catch(errors => {
                    reject(errors.response.data.messages);
            });
        });
    },

    /** Load overtime */
    loadOvertime({commit}, technicianId) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/v4/technicians/${technicianId}/overtime?order_by=overtimes.date&order_by_direction=desc`)
                .then((response) => {
                    commit('LOAD_OVERTIME', response.data.data.data);
                    resolve();
                }).catch(errors => {
                reject(errors.response.data.messages);
            });
        });
    },

    /** Load overtime */
    loadAllowedOvertimeDates({commit}, technicianId) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/v4/technicians/${technicianId}/overtime/allowed-dates`)
                .then((response) => {
                    commit('LOAD_ALLOWED_OVERTIME_DATES', response.data);
                    resolve();
                }).catch(errors => {
                reject(errors.response.data.messages);
            });
        });
    },

    /** Set the overtime ID */
    setOvertimeId({commit}, overtimeId) {
        commit('SET_OVERTIME_ID', overtimeId)
    },

    setOvertimeDateSelected({commit}, overtimeDateSelected) {
        commit('SET_OVERTIME_DATE_SELECTED', overtimeDateSelected)
    },
};

const mutations = {
    LOAD_OVERTIME(state, overtimes) {
        state.overtimes = overtimes;
    },

    LOAD_ALLOWED_OVERTIME_DATES(state, dates) {
        state.allowedOvertimeDates = dates;
    },

    SET_OVERTIME_DATE_SELECTED(state, overtimeDateSelected) {
        state.overtimeDateSelected = overtimeDateSelected;
    },

    SET_OVERTIME_ID(state, overtimeId) {
        state.overtimeId = overtimeId;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}