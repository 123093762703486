import {db} from '@/firebase'
import {firestoreAction} from "vuexfire";

const state = {
    stockItem: null,
    stockItems:[]
};

const getters = {
    stockItem: state => state.stockItem
};

const actions = {
    /** Load stock item for scanned part */
    loadStockItem: firestoreAction(({bindFirestoreRef}, {stockItemId}) => {
        return new Promise((resolve, reject) => {
            return bindFirestoreRef(
                'stockItem',
                db.collection(`stock_items`)
                    .doc(stockItemId.toString())
                ).then(() => {
                    resolve();
                }).catch((error) => {
                    console.log(error)
                    reject();
                })
        });
    }),

    // stockItemFromId(stockItemId) {
    //     // return new Promise((resolve) => {
    //         return db.collection(`stock_items`).doc(stockItemId.toString());
    //     // });
    // }
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}