<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M126 64.5C126 30.534 98.466 3 64.5 3S3 30.534 3 64.5 30.534 126 64.5 126a61.238 61.238 0 0038.065-13.192" class="path" stroke="#0C2340" transform="rotate(-23 64.5 64.5)"/><path class="highlight" stroke="#00BBDC" transform="rotate(9 104.033 19.432)" d="M83.084 40.394l41.9.005-.006-41.934"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>