<template>
    <b-modal
            body-class="pt-0"
            id="so-warranty-error-modal"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            title="GSPN Errors">

        <!-- Header close button -->
        <template v-slot:modal-header-close>
            <span @click="closeModal">×</span>
        </template>

        <h3>There are {{ soWarrantyErrors.length }} errors on GSPN</h3>
        <p>
            Please refer to the errors below to get them fixed:
        </p>
        <ul class="fa-ul">
            <li v-for="error in soWarrantyErrors" :key="error.id">
                <span class="fa-li"><i class="fa fa-skull"></i></span>
                {{ error.error }}
            </li>
        </ul>

    </b-modal>
</template>

<script>
    export default {
        props:['soWarrantyErrors'],

        methods: {
            /** Close the modal */
            closeModal() {
                this.$bvModal.hide('so-warranty-error-modal');
            },
        }
    }
</script>

<style scoped>

</style>