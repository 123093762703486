<template>
    <b-modal
            id="change-address-modal"
            no-footer
            title="Change Address"
            no-stacking>

        <div class="form-group">
            <label>Customer phone number</label>
            <input type="text" class="form-control" inputmode="numeric" pattern="[0-9]*" v-model="phoneNumber" />
        </div>

        <div class="form-group">
            <label>Where would you like the repair carried out?</label>
            <input type="text" class="form-control" placeholder="POSTCODE" v-model="postCode" />
        </div>

        <button class="btn btn-success" @click="searchPostCode" :disabled="!postCode || searching">
            <template v-if="!searching">Search</template>
            <template v-if="searching"><animated-loading class="mr-2" size="xs"></animated-loading> Searching</template>
        </button>

        <empty-state
                v-if="postCodeError"
                class="pb-0 my-4"
                faIcon="map-marker-alt-slash"
                inline
                small
                title=""
                subtitle="No address found, please try a different postcode">
        </empty-state>


        <template v-if="addressResults.length !== 0">
            <div class="form-group mt-3">
                <label>Select the address</label>
                <select class="custom-select" v-model="address">
                    <option
                            v-for="address in addressResults"
                            :key="address.line_1"
                            :value="address">
                        {{ address.line_1 }}
                    </option>
                </select>
            </div>
        </template>

        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="$bvModal.hide('change-address-modal')">Cancel</button>
            <button type="button" class="btn btn-success" @click="save" :disabled="address === null || saving">
                <template v-if="!saving">Update address</template>
                <template v-if="saving"><animated-loading class="mr-2" size="xs"></animated-loading> Updating</template>
            </button>
        </template>
    </b-modal>
</template>

<script>
    import {mapActions} from "vuex"

    export default {
        name: "ChangeAddress",

        data() {
            return {
                address: null,
                addressResults: [],
                booking: null,
                phoneNumber: null,
                postCode: null,
                postCodeError: false,
                saving: false,
                searching: false
            }
        },

        mounted() {
            this.loadBooking();
        },

        methods: {
            ...mapActions([
                "displayToast"
            ]),

            loadBooking() {
                this.$http.get(`/api/v4/warranty/booking/${localStorage.revisitBookingId}`)
                    .then(res => {
                        this.address = res.data.data.address;
                        this.phoneNumber = res.data.data.order.phone_number;
                        this.booking = res.data.data;
                    })
            },

            searchPostCode() {
                this.postCodeError = false;
                this.searching = true;

                this.$http.get(`/api/v4/addresses/check/${this.postCode}`)
                    .then(res => {
                        this.addressResults = res.data.data;
                        this.searching = false;
                    })
                    .catch(() => {
                        this.postCodeError = true;
                        this.searching = false;
                    })
            },

            save() {
                this.saving = true;
                this.$http.put(`/api/v4/warranty/booking/${localStorage.revisitBookingId}/address`, {
                    billing_first_name: this.booking.billing_address.first_name,
                    billing_second_name: this.booking.billing_address.second_name,
                    email: this.booking.order.email,
                    first_name: this.booking.order.first_name,
                    last_name: this.booking.order.second_name,
                    is_billing: false,
                    is_self_serve: false,
                    line_1: this.address.line_1,
                    line_2: this.address.line_2,
                    postcode: this.address.postcode,
                    town: this.address.town,
                    phone_number: this.phoneNumber
                }).then(() => {
                    this.saving = false;
                    this.displayToast({text: 'Address updated', type: 'success'});
                    this.$bvModal.hide('change-address-modal');
                });
            }
        }
    }
</script>

<style scoped>

</style>