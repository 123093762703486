<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M16 105h-4c-5.523 0-10-4.477-10-10V46c0-5.523 4.477-10 10-10m15 0h65.332a10 10 0 017.666 3.578l2.763 3.298m10.172 12.14l12.732 15.197A10 10 0 01128 76.636V95c0 5.523-4.477 10-10 10h-4.538m-27.023 0H43.231M69 36v32h54" class="path" stroke="#0C2340"/><path d="M30 90c7.18 0 13 5.82 13 13s-5.82 13-13 13-13-5.82-13-13 5.82-13 13-13zm70 0c7.18 0 13 5.82 13 13s-5.82 13-13 13-13-5.82-13-13 5.82-13 13-13zm10.5-57l3.523 8.977L123 45.5l-8.977 3.523L110.5 58l-3.523-8.977L98 45.5l8.977-3.523L110.5 33zm-91-8l4.086 10.414L34 39.5l-10.414 4.086L19.5 54l-4.086-10.414L5 39.5l10.414-4.086L19.5 25zm62-11l3.523 8.977L94 26.5l-8.977 3.523L81.5 39l-3.523-8.977L69 26.5l8.977-3.523L81.5 14z" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>