<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><rect class="path" stroke="#0C2340" x="25" y="2" width="80" height="126" rx="10"/><path d="M36 17h58a3 3 0 013 3v96a3 3 0 01-3 3H36a3 3 0 01-3-3V20a3 3 0 013-3zm25-7.5h15m-22 0h1" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>