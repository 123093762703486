import {db} from '@/firebase'
import {firestoreAction} from "vuexfire";

const state = {
    deviceImages: [],
    deviceAfterImages: [],
    deviceBeforeImages: []
};

const getters = {
    deviceImages: state => state.deviceImages,
    deviceAfterImages: state => state.deviceAfterImages,
    deviceBeforeImages: state => state.deviceBeforeImages,
};

const actions = {
    /** Add a new device image to firebase storage */
    addDeviceImage: (__, {jobId, deviceId, type, filename}) => {
        return new Promise((resolve, reject) => {

            let imagesCollection = db.collection(`jobs/${jobId}/devices/${deviceId}/images/`);

            imagesCollection.add({
                type: type,
                filename: filename
            }).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    },

    loadDeviceImages: firestoreAction(({bindFirestoreRef}, {jobId, deviceId}) => {
        return new Promise((resolve, reject) => {
            jobId = jobId.toString();
            deviceId = deviceId.toString();

            bindFirestoreRef(
                'deviceImages',
                db.collection(`jobs/${jobId}/devices/${deviceId}/images`)
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}