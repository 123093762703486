<template>
    <div class="card card-body">
        <div class="row">
            <div class="col-12">
                <label>Search for a job</label>
            </div>
            <div class="col">
                <div class="form-group mb-0">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-search mb-0"></i></span>
                        </div>
                        <input type="text" inputmode="numeric" pattern="[0-9]*" class="form-control" placeholder="Enter job number" v-on:keyup.enter="goToJob()" v-model="jobId">
                        <span class="input-clear" v-if="jobId" @click="clearJobId()"><i class="fas fa-times-circle mb-0"></i></span>
                    </div>
                </div>
            </div>
            <div class="col-auto pl-0">
                <button class="btn btn-success btn-block d-md-inline-block mb-0" @click="goToJob()" :disabled="!jobId">
                    <span class="d-md-none"><i class="fa fa-arrow-right mr-0"></i></span>
                    <span class="d-none d-md-inline">Go to job</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';

    export default {
        data() {
            return {
                jobId: null
            }
        },

        methods: {
            ...mapActions([
               'displayToast'
            ]),

            /** Clear job search input */
            clearJobId() {
                this.jobId = null;
            },

            /** Job search */
            goToJob() {
                let jobId = parseInt(this.jobId.trim(),10);

                if (!Number.isInteger(jobId)) {
                    this.displayToast({text: 'Only an order ID can be entered', type: 'error'});
                    return;
                }

                this.$router.push({name: `job`, params: {jobId: jobId}});
            }
        }
    }
</script>

<style scoped>

</style>