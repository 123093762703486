<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round"><path d="M22.3 2h85.4a5 5 0 013.797 1.746l15.3 17.85A5 5 0 01128 24.85V118c0 5.523-4.477 10-10 10H12c-5.523 0-10-4.477-10-10V24.85a5 5 0 011.204-3.254l15.3-17.85A5 5 0 0122.3 2zM2.5 23.5h124" class="path" stroke="#0C2340" stroke-linejoin="round"/><path class="highlight" stroke="#00BBDC" d="M83 23.5v32l-18-11-18 11v-32zM47 22l7.188-20h21.624L83 22"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>