<template>
    <b-modal
            id="device-status-modal"
            no-footer
            title="Change device status">

        <!-- Change status -->
        <template v-if="!loadingRevisit">
            <empty-state
                    statusIcon="warning"
                    statusIconSize="4x"
                    title="Changing this device’s status may have an affect on your KPIs"
                    subtitle="Please ensure you select the correct status for this device and only update when applicable">
                <slot>
                    <label class="d-block text-left">Select Status</label>
                    <select class="custom-select mb-4" v-model="selectedStatus" @change="handleDeviceStatusSelectChange">
                        <option selected>Open this select menu</option>
                        <option
                            v-for="status in filteredDeviceStatuses"
                            :key="status.key"
                            :value="status.id">
                            {{ status.title }}
                        </option>
                    </select>

                    <div class="alert alert-warning alert-centered rounded-0 mb-4 text-sm" v-if="selectedStatusSendsReplacement">
                        <animated-warning></animated-warning> Selecting this status will result in Likewize sending a replacement device to the customer.<br/>Are you sure this is correct?
                    </div>

                    <label class="d-block text-left" v-if="subStatusIsRequired">Please specify</label>
                    <select v-if="subStatusIsRequired" class="custom-select mb-4" v-model="selectedSubStatus">
                        <option selected>Open this select menu</option>
                        <option
                            v-for="status in filteredDeviceSubStatuses"
                            :key="status.key"
                            :value="status.id">
                            {{ status.title }}
                        </option>
                    </select>

                    <label class="d-block text-left">Enter a brief description of work done <span class="float-right text-sm text-muted">{{ descriptionCharactersRemaining }}</span></label>
                    <textarea v-model="completeDescription" class="form-control" rows="3" maxlength="40" placeholder="Enter a description"></textarea>
                    <p class="text-left text-muted text-xs pl-1">Enter a minimum of 20 characters</p>

                    <p class="text-left text-muted text-sm mt-2" v-if="!isRefurbOrMailIn"><strong>Hint:</strong> If required, the option to book a revisit will appear once you've clicked the Update Status button</p>
                </slot>

                <!-- Revisit bits -->
                <template v-if="!isRefurbOrMailIn">
                    <template v-if="revisitRequired && isOnline">
                        <h2 class="mt-4">Revisit Required</h2>
                        <p>A revisit booking is required for this status change, either click below to book a revisit or change the status.</p>
                        <button type="button" class="btn btn-danger mb-0" @click="bookRevisitLoadJob()">Book Revisit</button>
                    </template>

                    <template v-if="revisitRequired && isOffline">
                        <h2 class="mt-4">Revisit Required</h2>
                        <p>A revisit is required for this order, however you are currently offline.</p>
                    </template>

<!--                    <template v-if="revisitRequired && isSelfServe">-->
<!--                        <h2 class="mt-4">Revisit Required</h2>-->
<!--                        <p>A revisit is required to complete this job, as the customer is not available click the button below to send them a self serve revisit booking form.</p>-->
<!--                        <button type="button" class="btn btn-danger mb-0" @click="bookRevisitSelfServe()">Book Revisit</button>-->
<!--                    </template>-->
                </template>
                <template v-if="isRefurbOrMailIn && revisitRequired">
                    <div class="alert alert-info mb-0 mt-4">
                        <animated-info></animated-info>
                        <div class="alert-content">Please return this device to the office.</div>
                    </div>
                </template>

            </empty-state>
        </template>

        <!-- Loading revisit -->
        <empty-state
                v-if="loadingRevisit"
                class="bg-transparent"
                statusIcon="loading"
                title="Loading revisit booking"
                subtitle="Please wait">
        </empty-state>

        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="$bvModal.hide('device-status-modal')">Cancel</button>
            <button type="button" class="btn btn-success" @click="updateDeviceStatus()" :disabled="loadingRevisit || shouldDisableUpdateStatusBtn">{{ buttonText }}</button>
        </template>

        <remove-parts-modal></remove-parts-modal>
    </b-modal>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    export default {
        props:['jobId', 'deviceId'],

        data() {
            return {
                isSelfServe: false,
                loadingRevisit: false,
                revisitRequired: false,
                selectedStatus: null,
                selectedSubStatus: null,
                completeDescription: ''
            }
        },

        mounted() {
            this.revisitRequired = false;
            this.loadDeviceStatuses();
        },

        computed: {
            ...mapGetters([
                "device",
                "deviceParts",
                "deviceStatus",
                "deviceStatuses",
                "selectedDeviceStatusSubStatuses",
                "job",
                "isRefurbOrMailIn",
                "isInHouseTechnician",
                "isSuperAdmin",
                "userService",
            ]),

            filteredDeviceStatuses() {
                return this.deviceStatuses.filter((el) => {
                    return (
                        !el.admin_only &&
                        el.title &&
                        (
                            el.title !== "Completed" &&
                            el.title !== "Repaired Pending QC" &&
                            el.title !== "Cancelled Repair"
                        )
                    )
                });
            },

            filteredDeviceSubStatuses() {
                return this.selectedDeviceStatusSubStatuses.filter((el) => {
                    return (
                        !el.admin_only ||
                        (
                            this.isSuperAdmin ||
                            this.isInHouseTechnician
                        )
                    )
                });
            },

            selectedStatusSendsReplacement() {
                if (
                    this.job.company_name !== 'Brightstar' &&
                    this.job.company_name !== 'Brightstar Bulk' &&
                    this.job.company_name !== 'Likewize' &&
                    this.job.company_name !== 'Likewize Bulk'
                ) {
                    return false;
                }

                for (let i = 0; i < this.filteredDeviceStatuses.length; i++) {
                    if (this.filteredDeviceStatuses[i].id === this.selectedStatus) {
                        return this.filteredDeviceStatuses[i].sends_replacement;
                    }
                }
                return false;
            },

            subStatusIsRequired() {
                return this.selectedDeviceStatusSubStatuses.length > 0;
            },

            subStatusHasBeenSelected() {
                return this.selectedSubStatus !== null && this.selectedSubStatus !== "Open this select menu";
            },

            shouldDisableUpdateStatusBtn() {
                if (!this.selectedStatus || !this.completeDescription || this.completeDescription === '' || this.completeDescription.length < 20) {
                    return true;
                }
                if(this.subStatusIsRequired) {
                    if(!this.subStatusHasBeenSelected) {
                        return true;
                    }
                }
                return false;
            },

            descriptionCharactersRemaining() {
                if (!this.completeDescription) return 40;
                let remaining = (40 - this.completeDescription.length);
                if (remaining < 0) {
                    return 0;
                }
                return remaining;
            },

            buttonText() {
                if (this.selectedStatus === "22") {
                    return "Update status and raise SAW";
                }
                return "Update status";
            }
        },

        watch: {
            // completeDescription(desc) {
            //     this.completeDescription = desc.replace("  ", " ");
            // }
        },

        methods: {
            ...mapActions([
                "loadDeviceStatuses",
                "loadDeviceSubStatuses",
                "loadDeviceStatus",
                "setDeviceComplete",
                "setDeviceNotComplete",
                "setDeviceStatus",
                "displayToast",
            ]),

            /** Revisit load job */
            bookRevisitLoadJob() {
                this.loadingRevisit = true;
                this.$http.get(`/api/v4/warranty/booking/build/${this.job.actual_order_id}`)
                .then(res => {
                    localStorage.revisitBookingId = res.data.data.booking_reference;
                    localStorage.revisitDeviceStatus = this.selectedStatus;
                    this.loadingRevisit = false;

                    if (res.data.data.order.devices.length !== 1) {
                        this.$router.push(`/jobs/${this.jobId}/book-revisit/device`);
                        return;
                    }

                    this.$router.push(`/jobs/${this.jobId}/book-revisit`)
                });
            },

            /** Revisit self serve */
            bookRevisitSelfServe() {
                this.loadingRevisit = true;
                this.$http.post(`/api/v4/orders/${this.job.order_id}/revisit-invitation`, {
                    "reason": "Sorry we missed you, click below to book a revisit."
                }).then(() => {
                    this.loadingRevisit = false;
                    this.$bvModal.hide('device-status-modal');
                    this.$router.push('job');
                });
            },

            /** Update the device status */
            updateDeviceStatus() {
				if (!this.selectedStatus) return;

                this.revisitRequired = false;

                let revisitRequired = false;
                this.isSelfServe = false;

                this.deviceStatuses.forEach(status => {
                    if (status.id === this.selectedStatus) {
                        revisitRequired = status.revisit_required;

                        if (this.selectedStatus == 3 || this.selectedStatus == 4) {
                            this.isSelfServe = true;
                        }
                    }
                });

                this.setDeviceStatus({
                    jobId:this.jobId,
                    deviceId: this.deviceId,
                    status: this.selectedStatus,
                    subStatusId: this.selectedSubStatus,
                    completeDescription: this.completeDescription
                }).then(() => {
                    // If status is Completed
                    if (this.selectedStatus === "1") {
                        this.setDeviceComplete({
                            jobId:this.jobId,
                            deviceId:this.deviceId
                        }).then(() => {
                            this.loadDeviceStatus({
                                statusId: this.device.status_id
                            });
                            this.$bvModal.hide('device-status-modal');
                        });
                        // If status is NOT Completed
                    } else if (this.selectedStatus !== "1") {
                        this.setDeviceNotComplete({
                            jobId:this.jobId,
                            deviceId:this.deviceId
                        }).then(() => {
                          // @TODO - Remove for general SAW Request release
                          // @TODO - Disabled at time of commit.
                          //   if (this.userService.details.id === 160 ||
                          //     this.userService.details.id === 201 ||
                          //     this.userService.details.id === 363 ||
                          //     this.userService.details.id === 855 ||
                          //     this.userService.details.id === 1125 ||
                          //     this.userService.details.id === 1276
                          //   ) {
                          //     if (this.selectedStatus === "22") {
                          //       // Go raise a SAW
                          //       return this.$router.push(`/saw/request/${this.deviceId}/job/${this.jobId}`);
                          //     }
                          //   }

                            this.loadDeviceStatus({
                                statusId: this.device.status_id
                            });

                            if (revisitRequired) {
                                this.revisitRequired = true;
                            } else {
                                this.$bvModal.hide('device-status-modal');
                            }
                        });
                    }
                });
            },

            handleDeviceStatusSelectChange() {
                if (this.selectedStatus === '9' && this.deviceParts.length > 0) {
                    this.$bvModal.show('remove-parts-modal');
                }

                // reset the selected sub status
                this.selectedSubStatus = null;

                this.loadDeviceSubStatuses({
                    statusId: this.selectedStatus
                });
            }
        }
    }
</script>

<style scoped>

</style>
