<template>
    <b-modal
            id="problem-symptom-modal"
            no-footer
            title="Pick Symptoms">

        <ul class="list-group selection-list mb-4">
            <li class="list-group-item" v-for="symptom in symptoms" :key="symptom.id">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input"
                           :id="symptom.id"
                           v-model="symptom.checked" @change="updateSymptom(symptom)">
                    <label class="custom-control-label mb-0" :for="symptom.id">{{symptom.title}}</label>
                </label>
            </li>
        </ul>

        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="$bvModal.hide('problem-symptom-modal')">Cancel</button>
            <button type="button" class="btn btn-success" @click="saveSymptoms" :disabled="!symptomHasBeenChosen">Add</button>
        </template>
    </b-modal>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "ProblemSymptoms",

        data() {
            return {
                symptoms: [],
                symptomHasBeenChosen: false
            }
        },

        mounted() {
            this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
                if (modalId === 'problem-symptom-modal') {
                    this.loadSymptoms();
                }
            })
        },

        methods: {
            ...mapActions([
                "displayToast"
            ]),

            loadSymptoms() {
                this.symptoms = [];

                const symptoms = JSON.parse(localStorage.symptoms);

                symptoms.forEach(symptom => {
                    symptom.checked = symptom.existing;

                    this.symptoms.push(symptom);

                    if (symptom.existing) {
                        this.symptomHasBeenChosen = true;
                    }
                });
            },

            updateSymptom(symptom) {
                if (symptom.checked) {
                    this.$http.post(`/api/v4/warranty/booking/${localStorage.revisitBookingId}/symptom/${symptom.id}`);

                    this.symptomHasBeenChosen = true;
                    return;
                }

                this.symptomHasBeenChosen = false;
                this.$http.delete(`/api/v4/warranty/booking/${localStorage.revisitBookingId}/symptom/${symptom.id}`);
            },

            saveSymptoms() {
                if (this.symptomHasBeenChosen) {
                    localStorage.removeItem('symptoms');
                    this.displayToast({text: 'Symptom added', type: 'success'});
                    this.$bvModal.hide('problem-symptom-modal');
                    return;
                }
            }
        }
    }
</script>

<style scoped>

</style>