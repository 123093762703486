<template>
    <div>
        <!-- Navbar -->
        <b-navbar toggleable="true" sticky v-if="!isLogin">
            <div class="container py-0">

                <div class="nav-controls">
                    <!-- Back buttons -->
                    <div v-if="isDashboard || menuOpen" class="back-button"></div> <!-- Empty div for dashboard -->
                    <template v-if="!menuOpen">
                        <router-link v-if="backToDashboard" :to="{ name: 'dashboard'}" class="back-button"><i class="fal fa-arrow-left"></i></router-link> <!-- Any page other than the dashboard, job or repair kit pages OR the job page for super admins -->
                        <router-link v-if="isBulkRepair" :to="{name: 'bulkRepairImeiSearch'}" class="back-button"><i class="fal fa-arrow-left"></i></router-link> <!-- Bulk Repair page -->
                        <router-link v-if="isOvertime" :to="{name: 'overtime'}" class="back-button"><i class="fal fa-arrow-left"></i></router-link> <!-- Job detail page -->
                        <router-link v-if="isJob && isTechnician" :to="{name: 'jobList'}" class="back-button"><i class="fal fa-arrow-left"></i></router-link> <!-- Job detail page -->
                        <router-link v-if="isRepairKit " :to="{name: 'repairKitPicking'}" class="back-button"><i class="fal fa-arrow-left"></i></router-link> <!-- Repair kit page -->
                        <router-link v-if="isRepairProcess && !isRepairProcessDisplayAlert" :to="{ name: 'job', props:{jobId: expectedJobId}}" class="back-button"><i class="fal fa-arrow-left"></i> <span class="text-sm ml-2">Job</span></router-link> <!-- Job progress pages -->
                        <span v-if="isRepairProcessDisplayAlert" class="back-button cursor-pointer" @click="openUnsavedChangesModal('job')"><i class="fal fa-arrow-left"></i> <span class="text-sm ml-2">Job</span></span> <!-- Job progress pages - Display alert on leave -->
                        <span v-if="isRevisit" class="back-button cursor-pointer" @click="openUnsavedChangesModal('job')"><i class="fal fa-arrow-left"></i></span> <!-- Revisit - Display alert on leave -->
                    </template>

                    <!-- Title -->
                    <b-nav-text class="title">{{ pageTitle }}</b-nav-text>

                    <div class="nav-right">
                        <!-- Signal status -->
                        <icon-signal-none class="signal-icon" v-if="isOffline && !menuOpen" size="md"></icon-signal-none>

                        <!-- Navbar toggle -->
                        <span class="navbar-toggler" @click="toggleMenu()" :class="{'active': menuOpen}">
                            <span class="top"></span>
                            <span class="middle"></span>
                            <span class="bottom"></span>
                        </span>
                    </div>
                </div>

            </div>
        </b-navbar>

        <!-- Unsaved changes modal -->
        <unsaved-changes-modal :backTo="backTo" :jobId="expectedJobId"></unsaved-changes-modal>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        data() {
            return {
                backTo: null
            }
        },

        computed: {
            ...mapGetters([
                'isJobManager',
                'isTechnician',
                'menuOpen'
            ]),

            backToDashboard() {
                return (
                    !this.isBulkRepair &&
                    !this.isDashboard &&
                    !this.isJob &&
                    !this.isOvertime &&
                    !this.isRepairKit &&
                    !this.isRepairProcess &&
                    !this.isRepairProcessDisplayAlert &&
                    !this.isRevisit
                ) || (
                    this.isJobManager &&
                    this.isJob
                );
            },

            expectedJobId() {
                if (!this.jobId) {
                    return null;
                }
                return this.jobId;
            },

            isBulkRepair() {
                if (!this.$route.matched[0]) {
                    return false;
                }
                return this.$route.matched[0].name === `bulkRepairPrintLabel` || this.$route.matched[0].path === `/bulk-repair/:assetId/diagnostics`;
            },

            isDashboard() {
                if (!this.$route.matched[0]) {
                    return false;
                }
                return this.$route.matched[0].name === `dashboard`;
            },

            isJob() {
                if (!this.$route.matched[0]) {
                    return false;
                }
                return this.$route.matched[0].name === `job`;
            },

            isLogin() {
                if (!this.$route.matched[0]) {
                    return false;
                }
                return this.$route.matched[0].name === `login`;
            },

            isOvertime() {
                if (!this.$route.matched[0]) {
                    return false;
                }
                return this.$route.matched[0].name === `overtime.request`;
            },

            isRepairKit() {
                if (!this.$route.matched[0]) {
                    return false;
                }
                return this.$route.matched[0].name === `repairKitPicking.repairKit` || this.$route.matched[0].name === `repairKitPicking.repairKitLabel`;
            },

            isRepairProcess() {
                if (!this.$route.matched[0]) {
                    return false;
                }
                return this.$route.matched[0].path === `/jobs/:jobId/repair/:deviceId` && (this.$route.matched[1].path !== `/jobs/:jobId/repair/:deviceId/iris-codes` || this.$route.matched[1].path !== `/jobs/:jobId/repair/:deviceId/symptom-codes`);
            },

            isRepairProcessDisplayAlert() {
                if (!this.$route.matched[1]) {
                    return false;
                }
                return this.$route.matched[1].path === `/jobs/:jobId/repair/:deviceId/iris-codes` || this.$route.matched[1].path === `/jobs/:jobId/repair/:deviceId/symptom-codes`;
            },

            isRevisit() {
                if (!this.$route.matched[0]) {
                    return false;
                }
                return this.$route.matched[0].path === `/jobs/:jobId/book-revisit`;
            },

            pageTitle: function() {
                for (let i in this.$route.params) {
                    if (this.$route.params.hasOwnProperty(i) && i.includes('Id')) {
                        let title = `${this.$route.meta.title} ${this.$route.params[i]}`;
                        document.title = title;
                        return title;
                    }
                }

                return this.$route.meta.title;
            },
        },

        mounted() {
            this.checkPage();
        },

        methods: {
            ...mapActions([
                'toggleMenu',
            ]),

            checkPage() {

            },

            /** Open unsaved changes modal */
            openUnsavedChangesModal(backTo) {
                this.backTo = backTo;
                this.$bvModal.show('unsaved-changes-modal');
            },
        }
    }
</script>