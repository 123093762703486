<template>
    <b-modal
        id="job-complete-modal"
        no-footer
        title="Mark job complete">
        <empty-state
                statusIcon="warning"
                statusIconSize="4x"
                :title="`Are you sure you want to mark job ${jobId} as complete?`"
                subtitle="Please ensure you only mark as complete when applicable as this may have an affect on your KPIs">
        </empty-state>
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="$bvModal.hide('job-complete-modal')">Cancel</button>
            <button type="button" class="btn btn-success" @click="completeJob">Mark as complete</button>
        </template>
    </b-modal>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        props: ['jobId'],

        methods: {
            ...mapActions([
                "setJobComplete",
            ]),

            completeJob() {
                this.setJobComplete({
                    jobId: this.jobId
                }).then(() => {
                    this.$bvModal.hide('job-complete-modal');
                });
            }
        }
    }
</script>

<style scoped>

</style>