<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke-linejoin="round" stroke-width="4"><path d="M29.778 39.016v-15.08A7.937 7.937 0 0 1 37.714 16h28.572a7.937 7.937 0 0 1 7.936 7.937v15.079" class="highlight" stroke="#00BBDC"/><path d="M74 100.92H5.968A3.968 3.968 0 0 1 2 96.953v-44.38c0-1.027.399-2.015 1.112-2.755l7.718-8a3.968 3.968 0 0 1 2.856-1.214h76.628c1.077 0 2.108.438 2.856 1.213l7.718 8.001A3.968 3.968 0 0 1 102 52.572V59M3.19 73.54h70.62" class="path" stroke="#0C2340" stroke-linecap="round"/><path class="highlight" stroke="#00BBDC" stroke-linecap="round" d="M30.175 66.397v15.079M73.825 66.397v8.079M98 74v15h12"/><circle class="highlight" stroke="#00BBDC" stroke-linecap="round" cx="99.5" cy="87.5" r="28.5"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>