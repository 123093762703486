<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke-linejoin="round" stroke-width="4"><path d="M37 39V20c0-5.523 4.477-10 10-10h36c5.523 0 10 4.477 10 10v19" class="highlight" stroke="#00BBDC"/><path d="M16.724 41h96.552a5 5 0 0 1 3.599 1.529l9.724 10.08A5 5 0 0 1 128 56.082V112a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V56.081a5 5 0 0 1 1.401-3.471l9.724-10.081A5 5 0 0 1 16.724 41zM3.5 82.5h123" class="path" stroke="#0C2340" stroke-linecap="round"/><path class="highlight" stroke="#00BBDC" stroke-linecap="round" d="M37.5 73.5v19M92.5 73.5v19"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>