<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M17 12h96c5.523 0 10 4.477 10 10v96c0 5.523-4.477 10-10 10H17c-5.523 0-10-4.477-10-10V22c0-5.523 4.477-10 10-10zM9.636 41h111.782" class="path" stroke="#0C2340"/><path d="M47 86l10 10 26-26M93 2.5v18m-56-18v18" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>