import {firestoreAction} from "vuexfire";
import {db} from '@/firebase'

const state = {
    consignment: null,
    consignmentDetails: [],
    consignmentIdBeingReturned: null,

    addedToReturnConsignment: 'added-to-return-shipment'
};

const getters = {
    consignment: state => state.consignment,
    consignmentDetails: state => state.consignmentDetails,
    consignmentIdBeingReturned: state => state.consignmentIdBeingReturned,

    addedToReturnConsignment:  state => state.assets.filter(asset => asset.state_constant === state.addedToReturnConsignment),
};

const actions = {
    /** Clear the consignment shipment */
    clearReturnConsignmentScanned({commit}) {
        commit('CLEAR_RETURN_CONSIGNMENT_SCANNED');
    },

    /** Load a consignment */
    loadReturnConsignment: firestoreAction(({bindFirestoreRef}, {consignment}) => {
        return new Promise((resolve, reject) => {
            let consignmentReference = db.collection(`consignments`).doc(consignment.toString());

            consignmentReference.get()
                .then((snapshot) => {

                    if (!snapshot.exists) {
                        reject("Shipment not found");
                    }

                    bindFirestoreRef(
                        'consignment',
                        consignmentReference
                    ).then(() => {
                        resolve();
                    }).catch(() => {
                        reject();
                    })
                });
        });
    }),

    /** Load outbound consignment details */
    loadReturnConsignmentDetails: firestoreAction(({bindFirestoreRef}, {barcodeScanned, consignment}) => {
        return new Promise((resolve, reject) => {
            if (barcodeScanned.type !== 'consignment') {
                return resolve();
            }

            bindFirestoreRef(
                'consignmentDetails',
                db.collection(`consignments/${consignment}/consignment_details`)
                    ).then(() => {
                        resolve();
                    }).catch(() => {
                        reject();
                    })
        });
    }),

    /** Change repair kit status shipped back  */
    addDetailToConsignment(__, {detail_type, detail_id, consignment, reference}) {
        return new Promise((resolve, reject) => {
            detail_id = detail_id.toString();

            db.collection(`consignments/${consignment}/consignment_details`)
                .add({
                    id: detail_id,
                    consignment_id: consignment,
                    detail_id: detail_id,
                    detail_type: detail_type,
                    reference: reference,
                })
                .then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    },

    /** Clear the consignment shipment */
    setReturnConsignmentScanned({commit}, consignmentId) {
        commit('SET_RETURN_CONSIGNMENT_SCANNED', consignmentId);
    },
};

const mutations = {
    CLEAR_RETURN_CONSIGNMENT_SCANNED(state) {
        state.consignmentIdBeingReturned = null;
    },

    SET_RETURN_CONSIGNMENT_SCANNED(state, consignmentId) {
        state.consignmentIdBeingReturned = consignmentId;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}