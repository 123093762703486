<template>
    <b-modal
            id="cant-add-serial-modal"
            no-footer
            title="Can't add the serial number">

        <!-- Header close button -->
        <template v-slot:modal-header-close>
            <span @click="closeModal">×</span>
        </template>

        <empty-state
                statusIcon="warning"
                statusIconSize="4x"
                title="A serial number image is required by Samsung to close down this service order"
                subtitle="Completing a job without one could prevent the service order from being completed with Samsung">
        </empty-state>
        <div class="form-group">
            <label>Please add a note</label>
            <textarea class="form-control" rows="6" placeholder="Required" v-model="newNoteText"></textarea>
        </div>

        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="closeModal">Cancel</button>
            <button type="button" class="btn btn-danger" @click="confirmCantAdd()" :disabled="!newNoteText">Confirm</button>
        </template>
    </b-modal>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        props:['jobId', 'deviceId'],

        data() {
            return {
                newNoteText: ""
            }
        },

        computed: {
            ...mapGetters([
                "user"
            ])
        },

        methods: {
            ...mapActions([
                "addDeviceNote",
                "setNoSerialSupplied"
            ]),

            /** Close the modal */
            closeModal() {
                this.$bvModal.hide('cant-add-serial-modal');
                this.$emit('close');
            },

            /** Confirm can't add serial */
            confirmCantAdd() {
                this.setNoSerialSupplied({
                    jobId: this.jobId,
                    deviceId: this.deviceId
                }).then(() => {
                    this.addDeviceNote({
                        jobId: this.jobId,
                        deviceId: this.deviceId,
                        newNoteText: this.newNoteText,
                        user: this.user
                    }).then(() => {
                        this.newNoteText = "";
                        this.closeModal();
                    });
                });

            }
        }
    }
</script>

<style scoped>

</style>