<template>
    <!-- Non Faulty Part Modal -->
    <b-modal
            body-class="pt-0"
            id="part-not-faulty-modal"
            no-close-on-backdrop
            no-close-on-esc
            no-footer
            title="Non-faulty part">

        <!-- Header close button -->
        <template v-slot:modal-header-close>
            <span @click="closeModal">×</span>
        </template>

        <!-- Barcode picker -->
        <div class="mb-3">
            <scanner
                isModal
                @barcodeHasBeenScanned="barcodeScanned($event)"
            ></scanner>

        </div>

        <!-- Empty state -->
        <empty-state
                v-if="!partScanned"
                icon="scanner"
                title="Scan non-faulty parts">
        </empty-state>

        <!-- Scanned Part -->
        <template v-if="partScanned">
            <label>Non-faulty part</label>
            <ul class="list-group">
                <li class="list-group-item">
                    {{ partScanned.stock_item_title }}
                </li>
            </ul>
        </template>


        <!-- Footer -->
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="closeModal">Cancel</button>
            <button type="button" class="btn btn-success" :disabled="!partScanned" @click="partScannedNotFaulty()">Mark part as not faulty</button>
        </template>
    </b-modal>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        props:['jobId', 'deviceId'],

        data() {
            return {
                partScanned: null
            }
        },

        computed: {
            ...mapGetters([
                "appRegion",
                "deviceParts",
                "devicePartsFaulty",
            ]),
        },

        mounted() {
            this.clearPartScanned();
        },

        methods: {
            ...mapActions([
                "displayToast"
            ]),

            /** On barcode scanned */
            barcodeScanned(barcode) {
                this.partScanned = null;

                this.deviceParts.forEach(device => {
                    if (barcode.id.toString() === device.id) {
                        return this.displayToast({text: 'This part has not been marked as faulty', type: 'danger'});
                    }
                });

                this.devicePartsFaulty.forEach(device => {
                    if (barcode.id.toString() === device.id) {
                        return this.partScanned = device;
                    }
                    return;
                })
            },

            /** Clear the scanned part */
            clearPartScanned() {
                this.partScanned = null;
            },

            /** Close the modal */
            closeModal() {
                this.clearPartScanned();
                this.$bvModal.hide('part-not-faulty-modal');
                this.$emit('close');
            },

            /** Mark the part as faulty */
            partScannedNotFaulty() {
                this.$emit('partMarkedNotFaulty', {
                    partScanned: this.partScanned
                });
                this.closeModal();
            },
        }
    }
</script>

<style scoped>

</style>