<template>
    <b-modal
            id="no-parts-required-modal"
            no-footer
            title="No parts required">

        <!-- Header close button -->
        <template v-slot:modal-header-close>
            <span @click="closeModal">×</span>
        </template>

        <empty-state
                statusIcon="warning"
                statusIconSize="4x"
                title="Are you sure no parts are required?"
                subtitle="Please ensure you only confirm no parts are required when applicable, as this may have an affect on your KPIs">
        </empty-state>

        <div class="form-group">
            <label>Reason for no parts used</label>
            <select v-model="noPartsUsedReason" class="custom-select">
                <option :value="null">Select reason</option>
                <option value="No fault found">No fault found</option>
                <option value="Customer education">Customer education</option>
                <option value="Blacklisted">Blacklisted</option>
                <option value="Unauthorised repair">Unauthorised repair</option>
                <option value="Water damaged">Water damaged</option>
                <option value="Impact damaged">Impact damaged</option>
            </select>
        </div>

        <div class="form-group">
            <label>Add additional information</label>
            <textarea class="form-control" rows="6" placeholder="Enter additional information" v-model="newNoteText"></textarea>
        </div>

        <template v-slot:modal-footer>
            <button type="button" class="btn btn-light" @click="closeModal">Cancel</button>
            <button type="button" class="btn btn-danger" @click="confirmNoParts()" :disabled="!noPartsUsedReason">No parts required</button>
        </template>
    </b-modal>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        props:['jobId', 'deviceId'],

        data() {
            return {
                noPartsUsedReason: null,
                newNoteText: ""
            }
        },

        computed: {
            ...mapGetters([
                "nextRepairStep",
                "user"
            ])
        },

        methods: {
            ...mapActions([
                "addDeviceNote",
                "setNoPartsRequired"
            ]),

            /** Close the modal */
            closeModal() {
                this.$bvModal.hide('no-parts-required-modal');
                this.$emit('close');
            },

            /** Confirm no parts required */
            confirmNoParts() {
                this.setNoPartsRequired({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                    noPartsUsedReason: this.noPartsUsedReason
                }).then(() => {
                    if (this.newNoteText && this.newNoteText !== '') {
                        this.addDeviceNote({
                          jobId: this.jobId,
                          deviceId: this.deviceId,
                          newNoteText: this.newNoteText,
                          user: this.user
                        }).then(() => {
                          this.newNoteText = "";
                          this.$bvModal.hide('no-parts-required-modal');
                          this.$router.push({ name: this.nextRepairStep(this.$route.name).routeName});
                        });
                    } else {
                      this.newNoteText = "";
                      this.$bvModal.hide('no-parts-required-modal');
                      this.$router.push({ name: this.nextRepairStep(this.$route.name).routeName});
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>