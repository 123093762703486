<template>
    <div>

        <loading-state
            fullscreen
            v-if="loading">
        </loading-state>

        <div v-if="!loading" class="container">

            <h2>Raise a SAW Request</h2>
            <div v-if="gspnIsActive">
                <p class="text-muted">Use this form to raise a SAW request. Failing to do so may negatively impact your KPIs.</p>

                <div id="selectType" class="card card-body pt-3 pb-4">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Select SAW Type</label>
                                <select class="form-control" v-model="selectedSaw" @change="setFormSchema(selectedSaw)">
                                    <option :value="null" selected>Select a SAW type</option>
                                    <option
                                        v-for="saw in sawList"
                                        :key="saw.id"
                                        :value="saw">
                                        {{ saw.name }}
                                    </option>
                                    <option value="other" selected>Other</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="selectedSaw && selectedSaw !== 'other'">
                        <div class="col-md-12">
                            <label>SAW Description</label>
                            <p>
                                {{ selectedSaw.description }}
                            </p>
                        </div>
                    </div>
                </div>

                <empty-state
                    v-if="selectedSaw === 'other'"
                    icon="tools"
                    inline
                    title="Manually raise SAW"
                    subtitle="You'll need to manually raise this SAW through GSPN">
                </empty-state>

                <!-- Job Details -->
                <div id="showForm" class="card card-body" v-if="selectedSaw && selectedSaw !== 'other'">
                    <div class="row">
                        <div class="col-md-12">
                            <saw-form
                                ref="sawFormElement"
                                :formSchema="selectedSaw.schema"
                                :formId="selectedSaw.id"
                                :deviceId="deviceId"
                                @success="handleSuccess($event)"
                            ></saw-form>
                          <button class="btn btn-warning" @click="cancelForm">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <empty-state
                v-if="!gspnIsActive"
                icon="tools"
                title="Manually raise SAW"
                subtitle="You'll need to manually raise this SAW through GSPN">
            </empty-state>
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
    data: () => ({
        loading: true,
        sawList: [],
        selectedSaw: null,
        buttons: [],
        deviceId: null,
    }),

    created() {
        this.deviceId = this.$route.params.deviceId;
        this.loadSawList(this.deviceId).then(response => {
            response.data.forEach(saw => {
                if (saw.active === 1) {
                    this.sawList.push(saw);
                }
            })
            this.loading = false;
        })
    },

    computed: {
        ...mapGetters(['user']),

        gspnIsActive() {
            return this.user.company.gspn_active;
        }
    },

    methods: {
        ...mapActions([
            "loadSawList",
        ]),

        setFormSchema(selectedSaw)
        {
            this.$refs.sawFormElement.updateForm(selectedSaw.schema, selectedSaw.id)
        },

        handleSuccess()
        {
            this.$router.push(`/jobs/${this.$route.params.jobId}`);
        },

        cancelForm()
        {
            this.$router.push(`/jobs/${this.$route.params.jobId}`);
        }
    }
}
</script>
