<template>
    <laraform-override v-if="renderForm" ref="laraFormComponent" @response="checkResponse($event)" :form="form"></laraform-override>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
    // Imported globally, as that's the only way Laraform will work :(
    // .eslint-disable-next-line
    // mixins: [Laraform],

    props: {
        formSchema: {
            required: true,
        },
        formId: {
            required: true,
        },
        deviceId: {
            required: true,
        }
    },
    data: () => ({
        renderForm: true,
        form: {
            key: '',
            data: [],
            schema: {},
            theme: '',
            columns: {
                element: 12,
                label: 12,
                field: 12,
            },
            class: null,
            classes: null,
            labels: false,
            layout: 'vertical',
            formErrors: true,
            buttons: [{
                label: 'Submit',
                class: 'btn-success',
                disabled() {
                    return this.form$.invalid
                }
            }],
            with: [],
            messages: [],
            locale: 'en_GB',
            endpoint: '',
            method: 'POST',
            validateOn: 'submit|change',
        }
    }),

    computed: {
        ...mapGetters([
            'user',
            'weFixUri',
        ]),
    },
    created() {
        this.form.key = this.user.api.key;
        this.updateForm(this.formSchema, this.formId);
    },
    methods: {
        ...mapActions([
          'displayToast',
        ]),

        updateForm(schema, formId) {
            schema['deviceId'] = {
                type: 'hidden',
                default: this.deviceId,
            };
            this.form.schema = schema;
            this.form.endpoint = this.weFixUri + `/api/v4/saw/${formId}/save`;
            this.forceUpdateOfForm();
        },

        forceUpdateOfForm() {
            this.renderForm = false;
            this.$nextTick(()=> {
                this.renderForm = true;
            });
        },

        checkResponse(event) {
            let type = 'success';
            if (event.status === 'fail') {
                type = 'error';
            }

            if (type === 'success') {
              this.$emit('success', event);
            } else {
              this.displayToast({text: event.messages[0], type: 'error'});
            }
        },
    }
}
</script>
