<template>
    <footer class="fixed-footer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="card-grid card-grid-scroll mb-0 pt-3 grid-auto-columns-sm">
                        <div class="card-link-container">
                            <router-link :to="{ name: 'job'}" class="card card-body card-link vertical bg-gradient-info text-nowrap py-2">
                                Back to job
                            </router-link>
                        </div>
                        <div class="card-link-container" v-if="!isJobManager">
                            <router-link :to="{ name: 'jobList'}" class="card card-body card-link vertical bg-gradient-info text-nowrap py-2">
                                Back to job List
                            </router-link>
                        </div>
                        <div class="card-link-container">
                            <router-link :to="'#'" class="card card-body card-link vertical bg-gradient-info text-nowrap py-2">
                                Send report
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        computed:{
            ...mapGetters([
                'isJobManager'
            ]),
        },
    }
</script>

<style scoped>

</style>