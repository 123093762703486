import User from "@/services/user";

const state = {
    user: {},
    userService: null
};

const getters = {
    user: state => state.user,
    userService: state => new User(state.user),

    isStockRoomUser: state => ['stockUser', 'stockManager'].includes(state.user.role.tag),
    isInHouseTechnician: state => state.user.technician && state.user.technician.in_house,
    isAreaManager: state => state.user.role.tag === 'areaManager',
    isSuperAdmin: state => state.user.role.tag === 'superAdmin',
    isTechnician: state => state.user.role.tag === 'technician',
    isJobManager(state) {
        return (
            state.user.role.tag === 'areaManager' ||
            state.user.role.tag === 'superAdmin'
        );
    },
    isDoppelganger(state) {
        if (state.user.role.tag !== 'technician') {
            return false;
        }

        if (!state.user.technician || !state.user.technician.technician) {
            return false;
        }

        return (state.user.name !== state.user.technician.technician);
    }
};

const actions = {
    fetchUser({commit}, {email, password}) {
        return new Promise((resolve, reject) => {
            this.$axios.post(
                '/api/login',
                {
                    'email' : email,
                    'password' : password
                })
                .then(response => {

                    let user = response.data.data;

                    if (user.company.name !== 'Reviveaphone') {
                        reject('You are not authorised to use this app.');
                    }

                    commit('SET_USER', user);
                    resolve();
                })
                .catch(errors => {
                    console.log(errors);
                    reject(errors.response.data.messages[0]);
                });
        });
    },

    refreshUser({commit}) {
        return new Promise((resolve, reject) => {
            this.$axios.get('/api/login/user').then(response => {
                let user = response.data.data;
                if (user.company.name !== 'Reviveaphone') {
                    reject('You are not authorised to use this app.');
                }

                commit('SET_USER', user);
                resolve();
            })
            .catch(errors => {
                reject(errors.response.data.messages[0]);
            });
        });
    },

    clearUser({commit}) {
        return new Promise((resolve) => {
            commit('CLEAR_USER');
            resolve();
        })
    }
};

const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },

    CLEAR_USER(state) {
        state.user = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}