import {firestoreAction} from "vuexfire";
import {db} from '@/firebase'

const state = {
    irisOneCodes: null,
    irisTwoCodes: null,
    irisThreeCodes: null,
    irisFourCodes: null,
    irisCodeOne:null,
    irisCodeTwo:null,
    irisCodeThree:null,
    irisCodeFour:null,
    currentIrisCode:"one"
};

const getters = {
    irisOneCodes: state => state.irisOneCodes,
    irisTwoCodes: state => state.irisTwoCodes,
    irisThreeCodes: state => state.irisThreeCodes,
    irisFourCodes: state => state.irisFourCodes,
    irisCodeOne: state => state.irisCodeOne,
    irisCodeTwo: state => state.irisCodeTwo,
    irisCodeThree: state => state.irisCodeThree,
    irisCodeFour: state => state.irisCodeFour,
    currentIrisCode: state => state.currentIrisCode
};

const actions = {
    /** Load the current iris codes for the device */
    loadIrisCodesForDevice: ({dispatch}, {jobId, deviceId}) => {
        db.collection(`jobs/${jobId}/devices/${deviceId}/iris_codes/`)
            .limit(1)
            .get()
            .then(querySnapshot => {
                const queryDocumentSnapshot = querySnapshot.docs[0];

                if (!queryDocumentSnapshot) {
                    return;
                }

                let data = queryDocumentSnapshot.data();

                dispatch("setIrisCodeOne", {
                    description:data.group_description_1,
                    code:data.group_code_1,
                });

                dispatch("setIrisCodeTwo", {
                    description:data.group_description_2,
                    code:data.group_code_2,
                });

                dispatch("setIrisCodeThree", {
                    description:data.group_description_3,
                    code:data.group_code_3,
                });

                dispatch("setIrisCodeFour", {
                    description:data.group_description_4,
                    code:data.group_code_4,
                });
            });
    },

    loadIrisCodes({dispatch}) {
        dispatch('loadIrisOneCodes');
        dispatch('loadIrisTwoCodes');
        dispatch('loadIrisThreeCodes');
        dispatch('loadIrisFourCodes');
    },

    /** Load all iris code ones from firestore */
    loadIrisOneCodes: firestoreAction(({bindFirestoreRef}) => {
        return new Promise((resolve, reject) => {
            bindFirestoreRef(
                'irisOneCodes',
                db.collection(`/iris_codes/1/codes`)
                    .orderBy('description')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load all iris code two from firestore */
    loadIrisTwoCodes: firestoreAction(({bindFirestoreRef}) => {
        return new Promise((resolve, reject) => {
            bindFirestoreRef(
                'irisTwoCodes',
                db.collection(`/iris_codes/2/codes`)
                    .orderBy('description')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load all iris code three from firestore */
    loadIrisThreeCodes: firestoreAction(({bindFirestoreRef}) => {
        return new Promise((resolve, reject) => {
            bindFirestoreRef(
                'irisThreeCodes',
                db.collection(`/iris_codes/3/codes`)
                    .orderBy('description')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Load all iris code four from firestore */
    loadIrisFourCodes: firestoreAction(({bindFirestoreRef}) => {
        return new Promise((resolve, reject) => {
            bindFirestoreRef(
                'irisFourCodes',
                db.collection(`/iris_codes/4/codes`)
                    .orderBy('description')
            ).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        });
    }),

    /** Set code one */
    setIrisCodeOne({commit}, code) {
        commit("SET_CURRENT_IRIS_CODE", "two");
        commit("SET_IRIS_CODE_ONE", code);
    },

    /** Set code two */
    setIrisCodeTwo({commit}, code) {
        commit("SET_CURRENT_IRIS_CODE", "three");
        commit("SET_IRIS_CODE_TWO", code);
    },

    /** Set code three */
    setIrisCodeThree({commit}, code) {
        commit("SET_CURRENT_IRIS_CODE", "four");
        commit("SET_IRIS_CODE_THREE", code);
    },

    /** Set code three */
    setIrisCodeFour({commit}, code) {
        commit("SET_CURRENT_IRIS_CODE", "complete");
        commit("SET_IRIS_CODE_FOUR", code);
    },

    /** Clear code one, two and three */
    editIrisCodeOne({commit}) {
        commit("SET_IRIS_CODE_ONE", null);
        commit("SET_IRIS_CODE_TWO", null);
        commit("SET_IRIS_CODE_THREE", null);
        commit("SET_IRIS_CODE_FOUR", null);
        commit("SET_CURRENT_IRIS_CODE", "one");
    },

    /** Clear code two and three */
    editIrisCodeTwo({commit}) {
        commit("SET_IRIS_CODE_TWO", null);
        commit("SET_IRIS_CODE_THREE", null);
        commit("SET_IRIS_CODE_FOUR", null);
        commit("SET_CURRENT_IRIS_CODE", "two");
    },

    /** Clear code three */
    editIrisCodeThree({commit}) {
        commit("SET_IRIS_CODE_THREE", null);
        commit("SET_IRIS_CODE_FOUR", null);
        commit("SET_CURRENT_IRIS_CODE", "three");
    },

    /** Clear code four */
    editIrisCodeFour({commit}) {
        commit("SET_IRIS_CODE_FOUR", null);
        commit("SET_CURRENT_IRIS_CODE", "four");
    },

    /** Confirm the selected iris codes */
    confirmIrisCodes: ({state}, {jobId, deviceId}) => {
        return new Promise((resolve, reject) => {
            db.collection(`jobs/${jobId}/devices/${deviceId}/iris_codes/`).doc(deviceId)
                .set({
                    group_code_1: state.irisCodeOne.code,
                    group_description_1: state.irisCodeOne.description,
                    group_code_2: state.irisCodeTwo.code,
                    group_description_2: state.irisCodeTwo.description,
                    group_code_3: state.irisCodeThree.code,
                    group_description_3: state.irisCodeThree.description,
                    group_code_4: state.irisCodeFour.code,
                    group_description_4: state.irisCodeFour.description,
                }).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
        });
    }
};

const mutations = {
    SET_IRIS_CODE_ONE(state, code) {
        state.irisCodeOne = code;
    },
    SET_IRIS_CODE_TWO(state, code) {
        state.irisCodeTwo = code;
    },
    SET_IRIS_CODE_THREE(state, code) {
        state.irisCodeThree = code;
    },
    SET_IRIS_CODE_FOUR(state, code) {
        state.irisCodeFour = code;
    },
    SET_CURRENT_IRIS_CODE(state, codes) {
        state.currentIrisCode = codes;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}