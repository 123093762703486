<template>
    <b-modal
            id="adding-imei-modal"
            hide-footer
            hide-header-close
            no-close-on-backdrop
            no-close-on-esc
            title="Adding IMEI">

        <!-- Adding -->
        <empty-state
                v-if="adding"
                statusIcon="loading"
                statusIconSize="4x"
                title="Adding IMEI...">
        </empty-state>

        <!-- Success -->
        <empty-state
                v-if="success"
                statusIcon="success"
                statusIconSize="4x"
                title="Part added successfully">
        </empty-state>

        <!-- Error -->
        <empty-state
                v-if="!adding && error"
                statusIcon="error"
                statusIconSize="4x"
                title="There was an issue adding this IMEI"
                :subtitle="error">
            <slot>
                <button class="btn btn-light" @click="closeModal()">Close</button>
            </slot>
        </empty-state>

    </b-modal>
</template>

<script>
    export default {
        data() {
            return {
                adding: true,
                code: null,
                error: null,
                success: false
            }
        },

        methods: {
            closeModal() {
                this.$bvModal.hide('adding-imei-modal');
                this.adding = true;
                this.code = null;
                this.error = null;
            },

            displayError(error) {
                this.adding = false;
                this.code = error.code;
                this.error = error.messages[0];

                this.$bvModal.show('adding-imei-modal');
            }
        }
    }
</script>

<style scoped>

</style>