<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><rect class="path" stroke="#0C2340" x="25" y="2" width="80" height="126" rx="10"/><path d="M41.667 45l14.666 14.667m0-14.667L41.667 59.667m32-14.667l14.666 14.667m0-14.667L73.667 59.667M41 82.333h48m-4 0v10.5a7.5 7.5 0 01-15 0v-10.5h0m7.5 1.167v6" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>