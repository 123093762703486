<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"><path d="M17 12h96c5.523 0 10 4.477 10 10v96c0 5.523-4.477 10-10 10H17c-5.523 0-10-4.477-10-10V22c0-5.523 4.477-10 10-10zM9.636 41h111.782" class="path" stroke="#0C2340"/><path d="M40.656 79.4c2.114 0 3.844-1.721 3.844-3.825v-12.75c0-2.104-1.73-3.825-3.844-3.825H27.844C25.73 59 24 60.721 24 62.825v12.75c0 2.104 1.73 3.825 3.844 3.825h12.812zm30.75 0c2.114 0 3.844-1.721 3.844-3.825v-12.75c0-2.104-1.73-3.825-3.844-3.825H58.594c-2.114 0-3.844 1.721-3.844 3.825v12.75c0 2.104 1.73 3.825 3.844 3.825h12.812zm30.75 0c2.114 0 3.844-1.721 3.844-3.825v-12.75c0-2.104-1.73-3.825-3.844-3.825H89.344c-2.114 0-3.844 1.721-3.844 3.825v12.75c0 2.104 1.73 3.825 3.844 3.825h12.812zM71.406 110c2.114 0 3.844-1.721 3.844-3.825v-12.75c0-2.104-1.73-3.825-3.844-3.825H58.594c-2.114 0-3.844 1.721-3.844 3.825v12.75c0 2.104 1.73 3.825 3.844 3.825h12.812zm-30.75 0c2.114 0 3.844-1.721 3.844-3.825v-12.75c0-2.104-1.73-3.825-3.844-3.825H27.844C25.73 89.6 24 91.321 24 93.425v12.75c0 2.104 1.73 3.825 3.844 3.825h12.812zm61.5 0c2.114 0 3.844-1.721 3.844-3.825v-12.75c0-2.104-1.73-3.825-3.844-3.825H89.344c-2.114 0-3.844 1.721-3.844 3.825v12.75c0 2.104 1.73 3.825 3.844 3.825h12.812zM93 2.5v18m-56-18v18" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>