<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M8 40l27 10-3 69-30-9 6-70zm114 0L95 50l3 69 30-9-6-70zM35 50l9.782-2.282M65 79v30l-33 10m33-10l33 10-3-69-9.33-2.177" class="path" stroke="#0C2340"/><path d="M65 79c16.667-22.795 25-38.795 25-48C90 17.193 78.807 6 65 6S40 17.193 40 31c0 9.205 8.333 25.205 25 48zm0-36c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>