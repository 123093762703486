<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"><path d="M112 32v91a5 5 0 0 1-5 5H23a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5h60l29 30z" class="path" stroke="#0C2340"/><path class="path" stroke="#0C2340" d="M112 32H83V2"/><path d="M39 58h52a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5H39a5 5 0 0 1-5-5V63a5 5 0 0 1 5-5zm-2.5-36.5h19m-19 16h19m21 71h19" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>